import React, { useEffect, useState, useRef } from "react";
import Table from "react-bootstrap/Table";
import axios from "../../api/axios";

import "../UserMaster/UserMaster.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import { Navigate, useNavigate } from "react-router-dom";
import { DownloadTableExcel } from "react-export-table-to-excel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const UserList = () => {

  const UserListURL = "/GetUserListFromCompanyId";
  const navigate = useNavigate();
  const [Users, SetUser] = useState([]);
  const tableRef = useRef(null);

  useEffect(() => {
    getUsers();
  }, []);
  const setData = (data) => {
    //let { id} = data;
    // localStorage.setItem('EditUserId', id);
    alert(data.userName);
    navigate("/UserMaster", data.userName);
  };
  const onDelete = (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!" + id.userName,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, InActive it!",
        cancelButtonText: "No, Cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          swalWithBootstrapButtons.fire(
            "Deleted!",
            "User Inactivated.",
            "error"
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "Your imaginary file is safe :)",
            "success"
          );
        }
      });

    axios
      .delete(`https://60fbca4591156a0017b4c8a7.mockapi.io/fakeData/${id}`)
      .then(() => {
        //     getData();
      });
  };

  const getUsers = async () => {
    try {
      const response = await axios.get(
        UserListURL + "?CompanyId=" + localStorage.getItem("companyId")
      );
      // console.warn(response.data)
      // console.log( response);
      // console.log( response.json);
      // console.log(response.status);
      // console.log(response.statusText);
      // console.log(response.headers);
      // console.log(response.config);
      //const data = await response.json;
      SetUser(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="layoutContainer">
      <div className="row g-3">
                <div className="col-lg-10" style={{ textAlign: "Left" }}>
                  {" "}
                  <h4>
                    <b>User List</b>
                  </h4>
                </div>
                <div className="col-lg-2">
                  {/* <Link to="/UserMaster" style={{}} className="btn btn-success">
                    Add New (+)
                  </Link> */}
                  &nbsp;{" "}
                  <DownloadTableExcel
                    filename="users table"
                    sheet="users"
                    currentTableRef={tableRef.current}
                  >
                    <button
                      style={{ color: "#fff" }}
                      className="btn btn-success"
                    >
                      <i className="fa fa-file-excel-o"></i>
                    </button>
                  </DownloadTableExcel>
                </div>
              </div>
              <div style={{ paddingTop: "20px" }}>
                <Table ref={tableRef} table table-striped bordered table-sm>
                  <thead>
                    <tr>
                      <th style={{ width: "05%" }}>#</th>
                      {/* <th>User Id</th> */}
                      <th>User Name</th>
                      <th>Company Name</th>
                      <th>Status</th>

                      <th style={{ width: "15%" }}> Action</th>
                    </tr>
                  </thead>

                  {Users.length > 0 && (
                    <tbody>
                      {Users.map((user, index) => (
                        <tr>
                          <td>{index + 1} </td>
                          {/* <td>{user.userId}</td> */}
                          <td>{user.userName}</td>
                          <td>{user.companyName}</td>
                          <td>{user.status}</td>
                          <td>
                            <button
                              style={{
                                width: "30%",
                                height: "30px",
                                color: "green",
                              }}
                              className="btn"
                              onClick={() => setData(user)}
                            >
                              <i className="fa fa-pencil-square-o"></i>
                            </button>
                            &nbsp;
                            <button
                              style={{
                                width: "30%",
                                height: "30px",
                                color: "red",
                              }}
                              className="btn"
                              onClick={() => onDelete(user)}
                            >
                              <i className="fa fa-close"></i>
                            </button>
                            {/* <Link style={{width:'30%', height:'30px'}}  to='/Userupdate'>
            <Button  style={{width:'30%', height:'30px' }}   onClick={() => setData(user)} >Edit</Button>
            
            </Link>
           <Button  style={{width:'60%', height:'30px'}} variant="danger"  >In Active</Button> */}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </Table>
              </div>
            </div>
    </>
  );
};
export default UserList;
