import React from "react";
import moment from "moment";

import { useRef, useState, useEffect, useContext } from "react";
import axios from "../../api/axios";
import dayjs from "dayjs";
import { DatePicker } from "antd";
import customParseFormat from "dayjs/plugin/customParseFormat";

// import "react-datepicker/dist/react-datepicker.css";
import "../../css/calanderStyle.css";


export const DashBoard = () => {

  const [collapsed, setCollapsed] = useState(false);
  const LocalCompanyId = localStorage.getItem("CompanyId");

  const [CompanyId, setCompanyId] = useState(LocalCompanyId);
  const [UnitId, setUnitId] = useState(0);
  const [TotalEmployee, setTotalEmployee] = useState(0);
  const [TotalPresent, setTotalPresent] = useState(0);
  const [TotalAbsent, setTotalAbsent] = useState(0);

  const [TotalMissedPunch, setTotalMissedPunch] = useState(0);
  const [TotalVisit, setTotalVisit] = useState(0);
  const [DataDate, setDataDate] = useState(new Date());
  const [UnitItems, setUnitItems] = React.useState([]);
  const userRef = useRef();
  const UnitListURL = "/GetUnitListFromCompanyId";
  const TotalEmployeeURL = "/GetTotalEmployeeeFromCompanyIdAndUnitIdAndDate";
  const TotalPresentURL = "/GetTotalPresentFromCompanyIdAndUnitIdAndDate";
  const TotalMissedPunchURL =
    "/GetTotalMissedPunchFromCompanyIdAndUnitIdAndDate";
    const TotalVisitPunchURL ="/GetTotalVisitcountFromCompanyIdAndDate";

  const UnitChange = async (e) => {
    setUnitId(e.target.value);
  };

  const CalanderStyle: React.CSSProperties = {
    flex: "fit-content 1",
  };


  const contentStyle: React.CSSProperties = {
    float: "left",
    height: "90 %",
    // textAlign: "center",
    // minHeight: 120,
    // lineHeight: "120px",
    // color: "#fff",
    // backgroundColor: "#108ee9",
  };

  const ViewData = async () => {
    console.log('here::', moment(DataDate).format("YYYY-MM-DD"));
    const payload = {
      CompanyId: localStorage.getItem("companyId"),
      UnitId: localStorage.getItem("unitId"),
      DateFilter: moment(DataDate).format("YYYY-MM-DD")
    }
    const responseTotalEmployee = await axios.get(TotalEmployeeURL, { params: payload });
    console.log(responseTotalEmployee.data);
    setTotalEmployee(Number(responseTotalEmployee.data));

    const responseTotalPresent = await axios.get(TotalPresentURL, { params: payload });
    console.log(responseTotalPresent.data);
    setTotalPresent(Number(responseTotalPresent.data));
    setTotalAbsent(
      Number(responseTotalEmployee.data) - Number(responseTotalPresent.data)
    );

    const responseMissedPunch = await axios.get(TotalMissedPunchURL, { params: payload });
    setTotalMissedPunch(Number(responseMissedPunch.data));

    const responseVisit = await axios.get(
      TotalVisitPunchURL +
        "?CompanyId=" +
        localStorage.getItem("companyId") +
        "&DateFilter=" +
        moment(DataDate).format("YYYY-MM-DD")
    );
    console.log(responseVisit.data);
    setTotalVisit(Number(responseVisit.data));
  };

  function onChange(date, dateString) {
    if (date === null) {
      setDataDate(new Date());
    } else {
      setDataDate(new Date(dateString));
    }
  }


  useEffect(() => {
    setUnitId(localStorage.getItem("unitId"));
    const doFetchUnit = async () => {
      const responseunit = await axios.get(
        UnitListURL + "?CompanyId=" + localStorage.getItem("companyId")
      );
      setUnitItems(
        responseunit.data.map(({ unitId, unitName }) => ({
          label: unitName,
          value: unitId,
        }))
      );
      ViewData();
    };
    doFetchUnit();
  }, []);

  return (
    <>
      <div style={{ display: "block" }}>
        <div className="layoutContainer">

          <div className="row g-3">
            <div className="col-12 col-sm-5 col-lg-3">
              <select onChange={UnitChange} className="form-select">
                {UnitItems.map((item) => (
                  <option key={item.value} value={item.value} selected={item.value == UnitId}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-12 col-sm-5 col-lg-3">
              <DatePicker
                className="form-control"
                onChange={onChange}
                defaultValue={dayjs(DataDate)}
              />

            </div>
            <div className="col-12 col-sm-2 col-lg-1">
              <button onClick={ViewData} className="btn btn-success w-100">
                View
              </button>
            </div>
          </div>

          <div className="row mt-2 g-4">
            <div className="col-12 col-sm-6 col-md-3">
              <div className="BlueBox">
                <div className="mt-3 text-xl font-medium text-white">
                  {" "}
                  <a>{TotalEmployee}</a>
                </div>
                <div className="mt-3 text-xl font-medium text-white">
                  Total Employee
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-3">
              <div className="OrangeBox">
                <div className="mt-3 text-xl font-medium text-white">
                  <a>{TotalPresent}</a>
                </div>
                <div className="mt-3 text-xl font-medium text-white">
                  Total Present
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-3">
              <div className="SkyBlueBox">
                <div className="mt-3 text-xl font-medium text-white">
                  <a>{TotalAbsent}</a>
                </div>
                <div className="mt-3 text-xl font-medium text-white">
                  Total Absent
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-3">
              <div className="RedBox">
                <div className="mt-3 text-xl font-medium text-white">
                  <a>{TotalMissedPunch}</a>
                </div>
                <div className="mt-3 text-xl font-medium text-white">
                  Total Missed Punch
                </div>
              </div>
            </div>

            <div className="col-sm-3">
                    <div className="PurpleBox">
                      <div className="mt-3 text-xl font-medium text-white">
                        <a>{TotalVisit}</a>
                      </div>
                      <div className="mt-3 text-xl font-medium text-white">
                        Total Visit
                      </div>
                    </div>
                  </div>
          </div>

          <div className="row"></div>
        </div>
      </div>
    </>
  );
};
