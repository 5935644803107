import { text } from "@fortawesome/fontawesome-svg-core";
import { textAlign } from "@mui/system";
import React from "react";

const FooterBottom = () => {
  var d = new Date();
  return (
    <div className="footer">
      <div
        className="copyright"
      >
        <p>
          <b>
            Designed &amp; Developed by{" "}
            <a href="https://scriptindia.in" target="_blank" rel="noreferrer">
              SCRIPT INDIA
            </a>{" "}
            &nbsp;&#169;
            {d.getFullYear()}
          </b>
        </p>
      </div>
    </div>
  );
};

export default FooterBottom;
