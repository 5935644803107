import './App.css';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from './Component/Login';
import { DashBoard } from './Component/HR/DashBoard';
import { UserMaster } from './Component/UserMaster/UserMaster';
import UserList from './Component/UserMaster/UserList';
import Users from './Component/UserMaster/Users';
import Unit from './Component/Unit/Unit';
import { UnitMaster } from './Component/Unit/UnitMaster';
import { Company } from './Component/Company/Company';
import Department from './Component/Department/Department';
import { DepartmentMaster } from './Component/Department/DepartmentMaster';
import Client from './Component/ClientVisit/Client';
import { ClientVisitMaster } from './Component/ClientVisit/ClientVisitMaster';
import Designation from './Component/Designation/Designation';
import { DesignationMaster } from './Component/Designation/DesignationMaster';
import Shift from './Component/ShiftMaster/Shift';
import { ShiftMaster } from './Component/ShiftMaster/ShiftMaster';
import ShiftPlaning from './Component/ShiftMaster/ShiftPlaning';
import Device from './Component/Device/Device';
import { DeviceMaster } from './Component/Device/DeviceMaster';
import PaidHoliday from './Component/PaidHoliday/PaidHoliday';
import { PaidHolidayMaster } from './Component/PaidHoliday/PaidHolidayMaster';
import ShiftGroup from './Component/ShiftGroup/ShiftGroup';
import { ShiftGroupMaster } from './Component/ShiftGroup/ShiftGroupMaster';
import Employee from './Component/Employee/Employee';
import ImportEmployee from './Component/Employee/ImportEmployee';
import InOutReport from './Component/Reports/Attendance/InOutReport';
import InOutReportC from './Component/Reports/Attendance/InOutReportC';
import AbsentPresentReport from './Component/Reports/Attendance/AbsentPresentReport';
import WorkHourReport from './Component/Reports/Attendance/WorkHourReport';
import AttendanceLog from './Component/Reports/Attendance/AttendanceLog';
import SalaryReport from './Component/Reports/Salary/SalaryReport';
import SalaryReportC from './Component/Reports/Salary/SalaryReportC';
import SalarySlip from './Component/Reports/Salary/SalarySlip';
import LateComing from './Component/Reports/Attendance/LateComing';
import EarlyGoing from './Component/Reports/Attendance/EarlyGoing';
import DateWiseAttendance from './Component/Reports/Attendance/DateWiseAttendance';
import DateRangeWiseAttendance from './Component/Reports/Attendance/DateRangeWiseAttendance';
import MissedPunch from './Component/Reports/Attendance/MissedPunch';
import { EmployeeMaster } from './Component/Employee/EmployeeMaster';
import { SalaryMaster } from './Component/Salary/SalaryMaster';
import EmployeeSalary from './Component/Salary/EmployeeSalary';
import PFReport from './Component/Reports/Salary/PFReport';
import BonusReport from './Component/Reports/Salary/BonusReport';
import ESICReport from './Component/Reports/Salary/ESICReport';
import Leave from './Component/Leave/Leave';
import { LeaveMaster } from './Component/Leave/LeaveMaster';
import ChangePassword from './Component/ChangePassword/ChangePassword';
import InOutLogReport from './Component/Reports/Attendance/InOutLogReport';
import GeofenceReport from './Component/Reports/Attendance/GeofenceReport';
import ClientVisit from './Component/ClientVisit/ClientVisit';
import DateWiseClientVisit from './Component/ClientVisit/DateWiseClientVisit';
import { ViewLocation } from './Component/Reports/Attendance/ViewLocation';
import MainLayout from './Layout/MainLayout';

function App() {
  return (
    <>
      <title>SCRIPT HR</title>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/Login" element={<Login />} />
        <Route element={<MainLayout />}>
          <Route path="/DashBoard" element={<DashBoard />} />
          <Route path="/Company" element={<Company />} />
          <Route path="/UserMaster" element={<UserMaster />} />
          <Route path="/UserList" element={<UserList />} />
          <Route path="/Users" element={<Users />} />
          <Route path="/UnitMaster" element={<UnitMaster />} />
          <Route path="/Unit" element={<Unit />} />
          <Route path="/DepartmentMaster" element={<DepartmentMaster />} />
          <Route path="/Department" element={<Department />} />
          <Route path="/ClientVisitMaster" element={<ClientVisitMaster />} />
          <Route path="/Client" element={<Client />} />
          <Route path="/DesignationMaster" element={<DesignationMaster />} />
          <Route path="/Designation" element={<Designation />} />
          <Route path="/Shift" element={<Shift />} />
          <Route path="/ShiftMaster" element={<ShiftMaster />} />
          <Route path="/Device" element={<Device />} />
          <Route path="/DeviceMaster" element={<DeviceMaster />} />
          <Route path="/PaidHoliday" element={<PaidHoliday />} />
          <Route path="/PaidHolidayMaster" element={<PaidHolidayMaster />} />
          <Route path="/ShiftGroup" element={<ShiftGroup />} />
          <Route path="/ShiftGroupMaster" element={<ShiftGroupMaster />} />
          <Route path="/Employee" element={<Employee />} />
          <Route path="/EmployeeMaster" element={<EmployeeMaster />} />
          <Route path="/ImportEmployee" element={<ImportEmployee />} />
          <Route path="/InOutReport" element={<InOutReport />} />
          <Route path="/InOutReportC" element={<InOutReportC />} />
          <Route path="/AbsentPresentReport" element={<AbsentPresentReport />} />
          <Route path="/WorkHourReport" element={<WorkHourReport />} />
          <Route path="/Leave" element={<Leave />} />
          <Route path="/LeaveMaster" element={<LeaveMaster />} />
          <Route path="/LateComing" element={<LateComing />} />
          <Route path="/EarlyGoing" element={<EarlyGoing />} />
          <Route path="/AttendanceLog" element={<AttendanceLog />} />
          <Route path="/SalaryReport" element={<SalaryReport />} />
          <Route path="/PFReport" element={<PFReport />} />
          <Route path="/BonusReport" element={<BonusReport />} />
          <Route path="/ESICReport" element={<ESICReport />} />
          <Route path="/SalaryReportC" element={<SalaryReportC />} />
          <Route path="/SalarySlip" element={<SalarySlip />} />
          <Route path="/ChangePassword" element={<ChangePassword />} />
          <Route path="/MissedPunch" element={<MissedPunch />} />
          <Route path="/SalaryMaster" element={<SalaryMaster />} />
          <Route path="/EmployeeSalary" element={<EmployeeSalary />} />
          <Route path="/ShiftPlaning" element={<ShiftPlaning />} />
          <Route path="/DateWiseAttendance" element={<DateWiseAttendance />} />
          <Route path="/DateRangeWiseAttendance" element={<DateRangeWiseAttendance />} />
          <Route path="/InOutLogReport" element={<InOutLogReport />} />
          <Route path="/GeofenceReport" element={<GeofenceReport />} />
          <Route path="/ClientVisit" element={<ClientVisit />} />
          <Route path="/DateWiseClientVisit" element={<DateWiseClientVisit />} />
          <Route path="/ViewLocation" element={<ViewLocation />} />
          <Route path="*" element={<>Not Found</>} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
