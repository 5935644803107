import React from 'react'
import { ChangeEvent, useState } from 'react';
import { useForm } from "react-hook-form";

const ImportEmployee = () => {
  const { register, handleSubmit } = useForm();

  const onSubmit = async (data) => {
      const formData = new FormData();
      formData.append("files", data.file[0]);

      const res = await fetch("https://localhost:7205/ImportEmployeeList", {
          method: "POST",
          body: formData,
      }).then((res) => res.json());
      alert(JSON.stringify(`${res.message}, status: ${res.status}`));
  };
   

  return (
    <div className="App">
    <form onSubmit={handleSubmit(onSubmit)}>
        <input type="file" {...register("file")} />

        <input type="submit" />
    </form>
</div>


  )
}

export default ImportEmployee