import React from 'react'
import { useRef, useState, useEffect, useContext } from 'react'
import AuthContext from '../context/AuthProvider';
import { Navigate, useNavigate } from "react-router-dom";
import axios from '../api/axios'
import './../css/login.css'
import ScriptIndiaBlueLogo from './../images/logo-black Blue.png'
//import { useHistory } from "react-router-dom";

const LOGIN_URL = '/UserAuthentication';
const Login = () => {
  const { setAuth } = useContext(AuthContext);
  const userRef = useRef();
  const errRef = useRef();
  const navigate = useNavigate();
  //  const history = useHistory()
  const [userName, setuserName] = useState('');
  const [Password, setPassword] = useState('');
  const [errMSG, setErrMsg] = useState('');
  const [sucecess, setSucecess] = useState(false);
  useEffect(() => {
    userRef.current.focus();
  }, [])

  useEffect(() => {

    setErrMsg('');

  }, [userName, Password])

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // const response = await axios.post(LOGIN_URL, JSON.stringify({ userName, Password }), {
      //   headers: { 'Context-Type': 'application/json', withCredentials: true }

      // });
      const response = await axios.post(LOGIN_URL,
        JSON.stringify({ UserName: userName, Password: Password }),
        {
          headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PATCH, PUT' },
          withCredentials: true
        }
      );
      //console.log(JSON.stringify(response?.data));
      //const UserId = response?.data?.UserId;
      // console.log(JSON.stringify({ UserName: userName, Password: Password }));
      console.log(response.data);
      console.log(response.data.userId);
      if (parseInt(response.data.userId) > 0) {
        console.log('Valid');
        console.log(response.data.userId);
        console.log(response.data.userName);
        localStorage.setItem("userId", response.data.userId);
        localStorage.setItem("companyId", response.data.companyId);
        localStorage.setItem("unitId", response.data.unitId);
        navigate("/DashBoard");
        //history.push("/DashBoard");
        //setAuth({ UserName: userName, Password: Password, Userid: 0, CompanyId: 0, IsActive: "" });
      } else {
        console.log('In Valid');
        alert("Invalid Id Password");
      }

      setuserName('');
      setPassword('');
      setSucecess(true);
    } catch (error) {

      console.log('error', error);
      // if (!error?.response) {
      //   setErrMsg('No Server Response');
      // }
      // else if (error.response?.status == 400) {
      //   setErrMsg('Missin Username or password');


      // } else if (error.response?.status == 401) {
      //   setErrMsg('Un Autharised');


      // }
      // else {
      //   setErrMsg('Login Fail');
      // }
      // errRef.current.focus();
    }
    e.preventDefault();


  }

  return (
    <>
      <div className="container">
        <div className='CenterAlign'>
        <div className="LogoContainer">
          <img src={ScriptIndiaBlueLogo} alt="SCRIPT INDIA" className="avatar" />
        </div>

        <div className="loginContainer">

          <form onSubmit={handleSubmit}>
            <h2>LOGIN</h2>
            <input type="Text" className="form-controll" id="username" onChange={(e) => setuserName(e.target.value)}
              ref={userRef} autoComplete="off" value={userName} placeholder="User Name" required></input>

            <input type="Password" className="form-controll" id="password" onChange={(e) => setPassword(e.target.value)}
              autoComplete="off" value={Password} placeholder="Password" required></input>

            <button className="btn btn-primary mt-4"> Login</button>


          </form>
        </div>
        <div>
          Design & Developed By  <a href='https://scriptindia.in'> SCRIPT INDIA</a>

        </div>
        </div>
      </div >


    </>

  )
}

export default Login