import React, { useEffect, useState, useMemo, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import HeaderTop from "../../../Layout/HeaderTop";
import SideBar from "../../../Layout/Sidebar/SideBar";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { Navigate, useNavigate } from "react-router-dom";
import "./att.css";
import axios from "../../../api/axios";
import { DownloadTableExcel } from "react-export-table-to-excel";
import SideMenu from "../../../Layout/SideMenu";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Layout, Space } from "antd";
import FooterBottom from "../../../Layout/FooterBottom";

const DateWiseAttendance = () => {
  const { Header, Footer, Sider, Content } = Layout;
  const headerStyle: React.CSSProperties = {
    textAlign: "center",
    height: 64,
    paddingInline: 50,
    lineHeight: "64px",
    backgroundColor: "#ffff",
  };

  const contentStyle: React.CSSProperties = {
    float: "left",
  };

  const siderStyle: React.CSSProperties = {
    backgroundColor: "#ffff",
  };

  const footerStyle: React.CSSProperties = {
    backgroundColor: "#fff",
  };

  const [data, setData] = useState([]);
  const [Headerdata, setHeaderdata] = useState([]);

  const AbsentPresentReportListURL = "/GetAttendanceFromCompanyIdAndUnitId";
  const GetAttendanceDateWiseURL = "/GetAttendanceDateRangeWiseEmployeeWise";
  const tableRef = useRef(null);
  const navigate = useNavigate();
  const [UnitItems, setUnitItems] = React.useState([]);
  const [UnitId, setUnitId] = useState(0);
  const [FromMonth, setFromMonth] = useState(0);
  const [FromYear, setFromYear] = useState(0);
  const [FromDay, setFromDay] = useState(0);
  const [ToMonth, setToMonth] = useState(0);
  const [ToYear, setToYear] = useState(0);
  const [ToDay, setToDay] = useState(0);
  const [LateByMinutes, setLateByMinutes] = useState(20);

  const [currentYear] = useState(() => new Date().getFullYear());
  const [EmployeeCardNo, setEmployeeCardNo] = useState("");
  const UnitListURL = "/GetUnitListFromCompanyId";
  const options = [];
  obj = {};
  var obj = {};

  obj["value"] = 0;
  obj["label"] = "Select Year";
  options.push(obj);
  for (var i = -2; i < 1; i++) {
    var obj = {};

    obj["value"] = currentYear + i;
    obj["label"] = currentYear + i;
    options.push(obj);
  }
  useEffect(() => {
    setUnitId(localStorage.getItem("unitId"));
    const doFetch = async () => {
      // const response = await fetch('https://randomuser.me/api/?results=100');
      const responseunit = await axios.get(
        UnitListURL + "?CompanyId=" + localStorage.getItem("companyId")
      );
      setUnitItems(
        responseunit.data.map(({ unitId, unitName }) => ({
          label: unitName,
          value: unitId,
        }))
      );
    };
    doFetch();
  }, []);

  const UnitChange = async (e) => {
    setUnitId(e.target.value);
    // const responsedepartment = await axios.get(DepartmentListURL+"?CompanyId="+localStorage.getItem('companyId')+"&UnitId="+e.target.value );
    // setDepartmentItems(responsedepartment.data.map(({ departmentId, departmentName }) => ({ label: departmentName , value: departmentId })));
    // const responsedesignation = await axios.get(DesignationListURL+"?CompanyId="+localStorage.getItem('companyId')+"&UnitId="+e.target.value );
    // setDesignationItems(responsedesignation.data.map(({ designationId, designationName }) => ({ label: designationName , value: designationId })));
    // const responseshiftgroup = await axios.get(ShiftGroupListURL+"?CompanyId="+localStorage.getItem('companyId')+"&UnitId="+e.target.value );
    // setShiftGroupItems(responseshiftgroup.data.map(({ shiftGroupId, shiftGroupName }) => ({ label: shiftGroupName , value: shiftGroupId })));
  };

  const ViewData = async () => {
    const response = await axios.get(
      GetAttendanceDateWiseURL +
        "?UnitId=" +
        localStorage.getItem("unitId") +
        "&CompanyId=" +
        localStorage.getItem("companyId") +
        "&FromMonth=" +
        FromMonth +
        "&FromYear=" +
        FromYear +
        "&FromDay=" +
        FromDay +
        "&ToMonth=" +
        ToMonth +
        "&ToYear=" +
        ToYear +
        "&ToDay=" +
        ToDay +
        "&EmployeeCardNo=" +
        EmployeeCardNo
    );
    console.log(response.data);

    setData(response.data);

    // const response = await axios.get(
    //   AbsentPresentReportListURL +
    //     "?UnitId=" +
    //     localStorage.getItem("unitId") +
    //     "&CompanyId=" +
    //     localStorage.getItem("companyId") +
    //     "&Month=" +
    //     Month +
    //     "&Year=" +
    //     Year
    // );
    // console.log(response.data);
    // setData(response.data);
    //setHeaderdata(response.data.slice(0, 1));
  };

  return (
    <>
      <Layout>
        <Header style={headerStyle}>
          <HeaderTop></HeaderTop>
        </Header>
        <Layout hasSider>
          <Sider style={siderStyle}>
            <SideMenu></SideMenu>
          </Sider>
          <Content style={contentStyle}>
            <div className="col-lg-12">
              <div className="row" style={{ padding: "20px 50px" }}>
                <div className="col-lg-10" style={{ textAlign: "Left" }}>
                  <h4>
                    <b>Date Range Wise Attendance Report</b>
                  </h4>
                </div>

                <div className="col-lg-2">
                  {/* <label className="form-controll" >Unit</label> &nbsp; */}
                  <select
                    onChange={UnitChange}
                    className="dropdown-toggle ant-select"
                  >
                    {UnitItems.map((item) => (
                      <option
                        key={item.value}
                        value={item.value}
                        selected={item.value == UnitId}
                      >
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-lg-1">From Date</div>
                <div className="col-lg-2">
                  {/* <label className="form-controll" >Month</label> &nbsp; */}
                  <select
                    value={FromDay}
                    onChange={(e) => setFromDay(e.target.value)}
                    className="ant-select"
                  >
                    <option value="0">Select Day</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                    <option value="16">16</option>
                    <option value="17">17</option>
                    <option value="18">18</option>
                    <option value="19">19</option>
                    <option value="20">20</option>
                    <option value="21">21</option>
                    <option value="22">22</option>
                    <option value="23">23</option>
                    <option value="24">24</option>
                    <option value="25">25</option>
                    <option value="26">26</option>
                    <option value="27">27</option>
                    <option value="28">28</option>
                    <option value="29">29</option>
                    <option value="30">30</option>
                    <option value="31">31</option>
                  </select>
                </div>
                <div className="col-lg-2">
                  {/* <label className="form-controll" >Month</label> &nbsp; */}
                  <select
                    value={FromMonth}
                    onChange={(e) => setFromMonth(e.target.value)}
                    className="ant-select"
                  >
                    <option value="0">Select Month</option>
                    <option value="1">January</option>
                    <option value="2">February</option>
                    <option value="3">March</option>
                    <option value="4">April</option>
                    <option value="5">May</option>
                    <option value="6">June</option>
                    <option value="7">July</option>
                    <option value="8">August</option>
                    <option value="9">September</option>
                    <option value="10">October</option>
                    <option value="11">November </option>
                    <option value="12">December</option>
                  </select>
                </div>
                <div className="col-lg-2">
                  {/* <label className="form-controll" >Year</label> &nbsp; */}
                  <select
                    className="dropdown-toggle ant-select"
                    value={FromYear}
                    onChange={(e) => setFromYear(e.target.value)}
                  >
                    {options.map(({ value, label }, index) => (
                      <option value={value}>{label}</option>
                    ))}
                  </select>
                </div>
                <div className="col-lg-5"></div>
                <div className="col-lg-1">To Date</div>
                <div className="col-lg-2">
                  {/* <label className="form-controll" >Month</label> &nbsp; */}
                  <select
                    value={ToDay}
                    onChange={(e) => setToDay(e.target.value)}
                    className="ant-select"
                  >
                    <option value="0">Select Day</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                    <option value="16">16</option>
                    <option value="17">17</option>
                    <option value="18">18</option>
                    <option value="19">19</option>
                    <option value="20">20</option>
                    <option value="21">21</option>
                    <option value="22">22</option>
                    <option value="23">23</option>
                    <option value="24">24</option>
                    <option value="25">25</option>
                    <option value="26">26</option>
                    <option value="27">27</option>
                    <option value="28">28</option>
                    <option value="29">29</option>
                    <option value="30">30</option>
                    <option value="31">31</option>
                  </select>
                </div>
                <div className="col-lg-2">
                  {/* <label className="form-controll" >Month</label> &nbsp; */}
                  <select
                    value={ToMonth}
                    onChange={(e) => setToMonth(e.target.value)}
                    className="ant-select"
                  >
                    <option value="0">Select Month</option>
                    <option value="1">January</option>
                    <option value="2">February</option>
                    <option value="3">March</option>
                    <option value="4">April</option>
                    <option value="5">May</option>
                    <option value="6">June</option>
                    <option value="7">July</option>
                    <option value="8">August</option>
                    <option value="9">September</option>
                    <option value="10">October</option>
                    <option value="11">November </option>
                    <option value="12">December</option>
                  </select>
                </div>
                <div className="col-lg-2">
                  {/* <label className="form-controll" >Year</label> &nbsp; */}
                  <select
                    className="dropdown-toggle ant-select"
                    value={ToYear}
                    onChange={(e) => setToYear(e.target.value)}
                  >
                    {options.map(({ value, label }, index) => (
                      <option value={value}>{label}</option>
                    ))}
                  </select>
                </div>
                <div className="col-lg-5"></div>
                <div className="col-lg-4">
                  <input
                    type="Text"
                    className="form-controll"
                    id="EmployeeCardNo"
                    onChange={(e) => setEmployeeCardNo(e.target.value)}
                    autoComplete="off"
                    value={EmployeeCardNo}
                    placeholder="Employee CardNo"
                  ></input>
                </div>
                <div style={{ display: "none" }} className="col-lg-4">
                  <input
                    type="Text"
                    className="form-controll"
                    id="LateByMinutes"
                    onChange={(e) => setLateByMinutes(e.target.value)}
                    autoComplete="off"
                    value={LateByMinutes}
                    placeholder="20"
                  ></input>
                </div>
                {/* <div className="col-lg-6"></div> */}
                <div className="col-lg-2" style={{ margin: "15px 0px" }}>
                  <button onClick={ViewData} className="btn btn-primary">
                    View
                  </button>
                  &nbsp;
                  {/* <DownloadTableExcel
                    filename="AbsentPresent Report"
                    sheet="AbsentPresent"
                    currentTableRef={tableRef.current}
                  >
                    <button
                      style={{ width: "50%", height: "50px", color: "green" }}
                      className="btn"
                    >
                      <i className="fa fa-file-excel-o"></i>
                    </button>
                  </DownloadTableExcel> */}
                </div>
                <div className="col-lg-2" style={{ margin: "15px 0px" }}>
                  <DownloadTableExcel
                    filename="Date Wise Attendance"
                    sheet="Date Wise Attendance"
                    currentTableRef={tableRef.current}
                  >
                    <button className="btn btn-success">
                      Export to Excell
                    </button>
                  </DownloadTableExcel>
                </div>
              </div>
              <div className="scollDiv">
                <table
                  ref={tableRef}
                  className="attendTable"
                  style={{ width: "100%" }}
                >
                  <thead></thead>
                  <tbody>
                    <tr>
                      <td>Employee CardNo</td>
                      <td>Employee Name</td>
                      <td>Date</td>
                      <td>ShiftName</td>
                      <td>Shift InTime</td>
                      <td>Shift OutTime</td>
                      <td>InTime </td>
                      <td>OutTime</td>
                      <td>Status</td>
                    </tr>

                    {data.map((att, index) => (
                      <>
                        <tr data-index={index}>
                          <td>{att.employeeCardNo}</td>
                          <td>{att.employeeName}</td>
                          <td>{att.attendanceDate}</td>
                          <td>{att.shiftName}</td>
                          <td>{att.shiftInTime}</td>
                          <td>{att.shiftOutTime}</td>
                          <td>{att.inTime} </td>
                          <td>{att.outTime} </td>
                          <td>{att.status} </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </Content>
        </Layout>
        <Footer style={footerStyle}>
          <FooterBottom />
        </Footer>
      </Layout>
    </>
  );
};

export default DateWiseAttendance;
