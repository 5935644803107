import React, { useEffect, useState, useMemo, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import HeaderTop from "../../../Layout/HeaderTop";
import SideBar from "../../../Layout/Sidebar/SideBar";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { Navigate, useNavigate,useLocation } from "react-router-dom";
import "./att.css";
import axios from "../../../api/axios";
import { DownloadTableExcel } from "react-export-table-to-excel";
import SideMenu from "../../../Layout/SideMenu";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Layout, Space } from "antd";
import FooterBottom from "../../../Layout/FooterBottom";
// import { MapContainer } from 'react-leaflet/MapContainer'
// import { TileLayer } from 'react-leaflet/TileLayer'
// import { useMap } from 'react-leaflet/hooks'
// import { MapContainer, Marker, Popup, TileLayer,useMap } from 'react-leaflet'


export const ViewLocation = (props) => {
  const { Header, Footer, Sider, Content } = Layout;
  const headerStyle: React.CSSProperties = {
    textAlign: "center",
    height: 64,
    paddingInline: 50,
    lineHeight: "64px",
    backgroundColor: "#ffff",
  };

  const contentStyle: React.CSSProperties = {
    float: "left",
    height:"300",
  };

  const siderStyle: React.CSSProperties = {
    backgroundColor: "#ffff",
  };

  const footerStyle: React.CSSProperties = {
    backgroundColor: "#fff",
  };

  const GetDeviceLogCompanyIdAndUnitIdAndEmployeeCodeLocationDateWiseLogIdURL =
    "/GetDeviceLogCompanyIdAndUnitIdAndEmployeeCodeLocationDateWiseLogId";
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [DeviceLogId, setDeviceLogId] = useState(0);
  const [EmployeeCardNo, setEmployeeCardNo] = useState("");
  const [Latitude, setLatitude] = useState("");
  const [Longitude, setLongitude] = useState("");
  const { state } = useLocation(0);
  const mapRef = useRef(null)
  const [mapReady, setMapReady] = useState(false)

  const position = [20.3928, 72.9060]
  const googleUrl = "https://maps.google.com/maps";

  const [iframSrc, setIframeUrl] = useState();


  const userRef = useRef();
  useEffect(() => { 
     alert(state.id);
     setLatitude(state.ltd);
      setLongitude(state.lnd);
    setDeviceLogId(state.id);

    setIframeUrl(
        // `${googleUrl}?q==${state.ltd},${state.lnd}&hl=es;z=14&amp;output=embed`
        // `https://maps.google.com/maps?q=10.305385,77.923029&hl=es;z=14&amp;output=embed`
         `https://maps.google.com/maps?q=${state.ltd},${state.lnd}&hl=es;z=14&output=embed`
          );
    // if (state.id == 0) {
    //     //alert('New');
    //     setEmployeeCardNo("");
    //     setLatitude("");
    //     setLongitude("");

    //   } else {
        // const FetchLocation = async () => {
        //     const response = await axios.get(
        //       GetDeviceLogCompanyIdAndUnitIdAndEmployeeCodeLocationDateWiseLogIdURL + "?DeviceLogId=" + state.id
        //     );
        // alert(state.id);
        // setEmployeeCardNo(response.data.employeeCardNo);
        // setLatitude(response.data.latitude);
        // setLongitude(response.data.longitude);
     // };
      //FetchLocation();
      //}
   // userRef.current.focus();
  }, []);

  return (
    <>
      <Layout>
        <Header style={headerStyle}>
          <HeaderTop></HeaderTop>
        </Header>
        <Layout hasSider>
          <Sider style={siderStyle}>
            <SideMenu></SideMenu>
          </Sider>
          <Content style={{float:"left"}}>
            <div className="col-lg-11" style={{ padding: "20px 20px",height:"80%" }}>   
                      
            <iframe style={{width:"100%", height:"100%"}} src = {iframSrc}></iframe>
            {/* <iframe src="https://maps.google.com/maps?q=10.305385,77.923029&hl=es;z=14&output=embed" ></iframe> */}
            </div>
          </Content>
        </Layout>
        <Footer style={footerStyle}>
          <FooterBottom />
        </Footer>
      </Layout>
    </>
  );
};

//export default ViewLocation;



