import React from "react";
import { useRef, useState, useEffect, useContext } from "react";
//import AuthContext from '../context/AuthProvider';
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import axios from "../../api/axios";
import Swal from "sweetalert2";
//import  UserList  from './UserList';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const Company = (props) => {
  const UpdateCompanyDetails_URL = "/UpdateCompanyDetails";
  const CompanyDeails_URL = "/GetCompanyDetailsFromCompanyId";
  const userRef = useRef();
  const errRef = useRef();
  const navigate = useNavigate();

  const [CompanyName, setCompanyName] = useState("");
  const [Address, setAddress] = useState("");
  const [Email, setEmail] = useState("");
  const [City, setCity] = useState("");
  const [State, setState] = useState("");
  const [PAN, setPAN] = useState("");
  const [GSTNo, setGSTNo] = useState("");
  const [ContactNo, setContactNo] = useState("");
  const [Latitude, setLatitude] = useState("");
  const [Longitude, setLongitude] = useState("");
  const [GPSRadioValue, setGPSRadioValue] = useState("GPSDetect");

  const googleUrl = "https://maps.google.com/maps";

  const [iframSrc, setIframeUrl] = useState();



  const [IsActive, setIsActive] = useState(false);
  const [CompanyId, setCompanyId] = useState("");
  const [MSG, setErrMsg] = useState("");
  const [sucecess, setSucecess] = useState(false);

  const { state } = useLocation(0);

  useEffect(() => {
    // alert(localStorage.getItem('companyId'));
    const FetchCompanyDetails = async () => {
      const response = await axios.get(
        CompanyDeails_URL + "?CompanyId=" + localStorage.getItem("companyId")
      );
      console.log(response.data);
      setCompanyName(response.data.companyName);
      setAddress(response.data.address);
      setEmail(response.data.email);
      setCity(response.data.city);
      setState(response.data.state);
      setContactNo(response.data.contactNo);
      setPAN(response.data.pan);
      setGSTNo(response.data.gstNo);
      setLatitude(response.data.latitude);
      setLongitude(response.data.longitude);
    };
    FetchCompanyDetails();
    userRef.current.focus();

  }, []);

  //   const getcurrentcoords = async () => {
  //   navigator.geolocation.getCurrentPosition((position)=>{
  //     setLatitude(position.coords.latitude)
  //     setLongitude(position.coords.longitude)
  //   })
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      //alert(userId);

      // console.log(userId);

      const response = await axios.post(
        UpdateCompanyDetails_URL,
        JSON.stringify({
          CompanyName: CompanyName,
          Address: Address,
          Email: Email,
          City: City,
          PAN: PAN,
          GSTNo: GSTNo,
          State: State,
          ContactNo: ContactNo,
          CompanyId: localStorage.getItem("companyId"),
          Latitude: Latitude,
          Longitude: Longitude,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          withCredentials: true,
        }
      );
      console.log(response.data);
      setErrMsg(response.data);
      //setuserName('');
      //setPassword('');
      //setIsActive(false);
      Swal.fire({
        position: "top-center",
        icon: "success",
        title: response.data,
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      console.log("error", error);
    }
    e.preventDefault();
  };


  return (
    <>
      <div className="layoutContainer">
        <div className="row g-3">
          <div className="col-lg-10" style={{ textAlign: "Left" }}>
            {" "}
            <h4>
              <b>Company Master</b>
            </h4>
          </div>
        </div>
        <div style={{ paddingTop: "20px" }}>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-lg-4">
                <input
                  type="Text"
                  className="form-controll"
                  id="CompanyName"
                  onChange={(e) => setCompanyName(e.target.value)}
                  ref={userRef}
                  autoComplete="off"
                  value={CompanyName}
                  placeholder="User Name"
                  required
                ></input>
              </div>
              <div className="col-lg-4">
                <textarea
                  className="form-controll ant-textarea"
                  id="Address"
                  onChange={(e) => setAddress(e.target.value)}
                  autoComplete="off"
                  value={Address}
                  placeholder="Address"
                  required
                ></textarea>
              </div>
              <div className="col-lg-4">
                <input
                  type="email"
                  className="form-controll"
                  id="Email"
                  onChange={(e) => setEmail(e.target.value)}
                  autoComplete="off"
                  value={Email}
                  placeholder="Email"
                  required
                ></input>
              </div>
              <div className="col-lg-4">
                <input
                  type="Text"
                  className="form-controll"
                  id="City"
                  onChange={(e) => setCity(e.target.value)}
                  autoComplete="off"
                  value={City}
                  placeholder="City"
                  required
                ></input>
              </div>
              <div className="col-lg-4">
                <input
                  type="Text"
                  className="form-controll"
                  id="State"
                  onChange={(e) => setState(e.target.value)}
                  autoComplete="off"
                  value={State}
                  placeholder="State"
                  required
                ></input>
              </div>
              <div className="col-lg-4">
                <input
                  type="Text"
                  className="form-controll"
                  id="ContactNo"
                  onChange={(e) => setContactNo(e.target.value)}
                  autoComplete="off"
                  value={ContactNo}
                  placeholder="ContactNo"
                  required
                ></input>
              </div>
              <div className="col-lg-4">
                <input
                  type="Text"
                  className="form-controll"
                  id="PAN"
                  onChange={(e) => setPAN(e.target.value)}
                  autoComplete="off"
                  value={PAN}
                  placeholder="PAN Number"
                  required
                ></input>
              </div>
              <div className="col-lg-4">
                <input
                  type="Text"
                  className="form-controll"
                  id="GSTNo"
                  onChange={(e) => setGSTNo(e.target.value)}
                  autoComplete="off"
                  value={GSTNo}
                  placeholder="GST Number"
                  required
                ></input>
              </div>

              {/* 
<input type="RetypePassword" className="form-controll" id="RetypePassword" onChange={(e) => setPassword(e.target.value)}
              autoComplete="off" value={Password} placeholder="Retype Password" required></input> */}
              <div className="col-lg-2" style={{ padding: "10px" }}>
                <button className="btn btn-primary"> Update</button>
              </div>

              <div className="col-lg-4">
                <input
                  type="Text"
                  className="form-controll"
                  id="Latitude"
                  onChange={(e) => setLatitude(e.target.value)}
                  autoComplete="off"
                  value={Latitude}
                  placeholder="Latitude"
                //required
                ></input>
              </div>
              <div className="col-lg-4">
                <input
                  type="Text"
                  className="form-controll"
                  id="Longitude"
                  onChange={(e) => setLongitude(e.target.value)}
                  autoComplete="off"
                  value={Longitude}
                  placeholder="Longitude"
                //required
                ></input>
              </div>






              {/* <div
                  onChange={(event) =>
                    setGPSRadioValue(event.target.value)
                  }
                  className="col-lg-12"
                > */}
              {/* <p>Please select Filter:</p> {" "}  */}
              {/* <input
                    selected={false}
                    type="radio"
                    id="GPSId"
                    name="GPSRadio"
                    value="GPSValue"
                    defaultChecked={GPSRadioValue === "GPSDetect"}
                  />
                    <label for="GPSDetect">GPS Detect</label> {" "}
                </div> */}

              <div>
                <label>{MSG}</label>
              </div>
            </div>
          </form>

          {/* <div className="col-lg-2" style={{ margin: "15px 0px" }}>
                  <button onClick={getcurrentcoords} className="btn btn-primary">
                    {" "}
                    View GPS
                  </button>
                </div> */}
        </div>
      </div>
    </>
  );
};
