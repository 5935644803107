import React from "react";

import { useRef, useState, useEffect, useContext } from "react";
//import AuthContext from '../context/AuthProvider';
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import axios from "../../api/axios";
import Swal from "sweetalert2";
import UnitMasterDDL from "../DropdownList/UnitMasterDDL";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Password } from "@mui/icons-material";

const ChangePassword = () => {

  const ChangePassword_URL = "/UserChangePassword";

  const [OldPassword, setOldPassword] = useState('');
  const [NewPassword, setNewPassword] = useState('');
  const [ConfirmNewPassword, setConfirmNewPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (NewPassword !== ConfirmNewPassword) {
      alert("New Passwords Don't Match");
      return;
    }

    try {
      //alert('Change');
      const response = await axios.post(
        ChangePassword_URL,
        JSON.stringify({
          OldPassword: OldPassword,
          NewPassword: NewPassword,
          ConfirmNewPassword: ConfirmNewPassword,
          CompanyId: localStorage.getItem("companyId"),
          UserId: localStorage.getItem("userId"),
        }),
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          withCredentials: true,
        }
      );
      console.log(response.data);
      setErrorMessage(response.data);
      console.log('Password Changed Successfully');
      setOldPassword("");
      setNewPassword("");
      setConfirmNewPassword("");

      Swal.fire({
        position: "top-center",
        icon: "success",
        title: response.data,
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      console.log("error", error);
    }
    e.preventDefault();
  };

  return (
    <>
      <div className="layoutContainer screenCenter">
      <div className="loginContainer">
          {/* <form onSubmit={handleSubmit}> */}
          <div>
            <h2>Change Password</h2>
            <form onSubmit={handleSubmit}>
              <div>
                <label>Old Password:</label>
                <input
                  type="Password"
                  name="OldPassword"
                  value={OldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                />
              </div>
              <div>
                <label>New Password:</label>
                <input
                  type="Password"
                  name="NewPassword"
                  value={NewPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </div>
              <div>
                <label>Confirm New Password:</label>
                <input
                  type="Password"
                  name="ConfirmNewPassword"
                  value={ConfirmNewPassword}
                  onChange={(e) => setConfirmNewPassword(e.target.value)}
                />
              </div>
              <button className="btn btn-primary">Change Password</button>
            </form>
          </div>
          {/* </form> */}
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
