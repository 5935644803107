import React, { useEffect, useState, useMemo, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import { DownloadTableExcel } from "react-export-table-to-excel";
import Swal from "sweetalert2";
import { Navigate, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  Container,
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
} from "reactstrap";
import TableContainer from "../Util/TableContainer";
//import 'bootstrap/dist/css/bootstrap.min.css';
import { SelectColumnFilter } from "../Util/filters";
import axios from "../../api/axios";
import { colors } from "@mui/material";

const Client = () => {
  const [data, setData] = useState([]);
  const ClientListURL = "/GetAllVisitorDetailsFromCompanyId";
  const tableRefDownload = useRef(null);
  const navigate = useNavigate();
  const tableRef = useRef(null);
  const [RowNum,setRowNum] =useState([]); 

  useEffect(() => {
    const doFetch = async () => {
      // const response = await fetch('https://randomuser.me/api/?results=100');
      const response = await axios.get(
        ClientListURL + "?CompanyId=" + localStorage.getItem("companyId")
      );
      console.log(response.data);
      response.data.shift();
      setData(response.data);
      setRowNum(response.data.rowNum);
    };
    doFetch();
  }, []);

  const EditData = (id) => {
    //let { id} = data;
    // localStorage.setItem('EditUserId', id);
    //alert(id);
    navigate("/ClientVisitMaster", { state: { id } });
  };



  return (
    <>
      <div className="layoutContainer">
        <div className="row g-3">
          <div className="col-lg-8" style={{ textAlign: "Left" }}>
            {" "}
            <h4>
              <b>Client Visit List</b>
            </h4>
          </div>

          {/* <div className="col-lg-2">
            <button
              onClick={(e) => {
                EditData(0);
              }}
              className="btn btn-primary"
            >
              Add New (+)
            </button>
          </div> */}

          <div className="col-lg-2">
            <DownloadTableExcel
              filename="Client Visit table"
              sheet="Client"
              currentTableRef={tableRef.current}
            >
              <button className="btn btn-success">
                Export to Excell
              </button>
            </DownloadTableExcel>
          </div>
        </div>

        <div className="scollDiv mt-4">
          <table
            ref={tableRef}
            className="attendTable text-center"
            style={{ width: "100%" }}
          >
            <thead></thead>
            <tbody>
              <tr>
                <td>Sr No.</td>
                <td>Visitor Card No</td>
                <td>Visitor Name</td>
                <td>Company Name</td>
                <td>Company Address</td>
                <td>Contact Person Name</td>
                <td>Contact Person No</td>
                <td>EmailId</td>
                <td>Latitude</td>
                <td>Longitude</td>
                <td>Visit Date</td>
                <td>Status</td>
                <td>Remarks</td>
                <td>Action</td>
              </tr>

              {data.map((att, index) => (
                <>
                  <tr data-index={index}>
                    <td>{att.rowNum}</td>
                    <td>{att.visitorCardNo}</td>
                    <td>{att.visitorName}</td>
                    <td>{att.companyName}</td>
                    <td>{att.companyAddress}</td>
                    <td>{att.contactPersonName}</td>
                    <td>{att.contactPersonNo}</td>
                    <td>{att.emailId}</td>
                    <td>{att.latitude}</td>
                    <td>{att.longitude}</td>
                    <td>{att.entryDate}</td>
                    <td>{att.status}</td>
                    <td>{att.remarks} </td>
                    <td>{<button
                      style={{ width: "40%", height: "40px", color: "green" }}
                      onClick={(e) =>
                        EditData(att.clientVisitId)
                      }
                      className="btn"
                    >
                      <i className="fa fa-pencil-square-o"></i>
                    </button>}
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Client;
