import React from "react";

import SideBar from "../../Layout/Sidebar/SideBar";
import HeaderTop from "../../Layout/HeaderTop";
import { useRef, useState, useEffect, useContext } from "react";
//import AuthContext from '../context/AuthProvider';
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import axios from "../../api/axios";
import Swal from "sweetalert2";
import UnitMasterDDL from "../DropdownList/UnitMasterDDL";
import { width } from "@mui/system";
import { blue } from "@mui/material/colors";
import { DatePicker } from "antd";
import "react-datepicker/dist/react-datepicker.css";
import SideMenu from "../../Layout/SideMenu";
import { Layout, Space } from "antd";
import FooterBottom from "../../Layout/FooterBottom";
import moment from "moment";
import dayjs from "dayjs";

export const ClientVisitMaster = (props) => {
  const { Header, Footer, Sider, Content } = Layout;
  const headerStyle: React.CSSProperties = {
    textAlign: "center",
    height: 64,
    paddingInline: 50,
    lineHeight: "64px",
    backgroundColor: "#ffff",
  };

  const contentStyle: React.CSSProperties = {
    float: "left",
    // textAlign: "center",
    // minHeight: 120,
    // lineHeight: "120px",
    // color: "#fff",
    // backgroundColor: "#108ee9",
  };

  const siderStyle: React.CSSProperties = {
    backgroundColor: "#ffff",
  };

  const footerStyle: React.CSSProperties = {
    backgroundColor: "#eee",
  };

  const INSERTVisitor_URL = "/InsertVisitorDetails";
  const UPDATEVisitor_URL = "/UpdateVisitorDetails";
  const VisitorDetail_URL = "/GetAllVisitorDetailsFromCompanyIdAndClientVisitId";

  const userRef = useRef();
  const errRef = useRef();
  const navigate = useNavigate();

  const [ClientVisitId, setClientVisitId] = useState(0);
  const [VisitorCardNo, setVisitorCardNo] = useState("");
  const [VisitorName, setVisitorName] = useState("");
  const [CompanyName, setCompanyName] = useState("");
  const [CompanyAddress, setCompanyAddress] = useState("");

  const [ContactPersonName, setContactPersonName] = useState("");
  const [ContactPersonNo, setContactPersonNo] = useState("");
  const [EmailId, setEmailId] = useState("");
  const [Latitude, setLatitude] = useState("");
  const [Longitude, setLongitude] = useState("");
  const [Status, setStatus] = useState("");
  const [CompanyId, setCompanyId] = useState("");
  const [UnitId, setUnitId] = useState("");
  const [Remarks, setRemarks] = useState("");

  const [MSG, setErrMsg] = useState("");
  const [sucecess, setSucecess] = useState(false);
  const [btnLabel, setbtnLabel] = useState(false);

  const { state } = useLocation(0);

  useEffect(() => {
    setClientVisitId(state.id);
    if (state.id == 0) {
      //alert('New');
      setVisitorName("");
      setbtnLabel("Save");
    } else {
       //alert(state.id);
      const FetchVisitorDetails = async () => {
        const response = await axios.get(
            VisitorDetail_URL + "?ClientVisitId=" + state.id
        );
        setClientVisitId(response.data.clientVisitId);
        setVisitorCardNo(response.data.visitorCardNo);
        setVisitorName(response.data.visitorName); 
        setCompanyName(response.data.companyName);
        setCompanyAddress(response.data.companyAddress);
        setContactPersonName(response.data.contactPersonName);
        setContactPersonNo(response.data.contactPersonNo);
        setEmailId(response.data.emailId);
        setLatitude(response.data.latitude);
        setLongitude(response.data.longitude);
        setStatus(response.data.status);
        setCompanyId(response.data.companyId);
        setUnitId(response.data.unitId);
        setRemarks(response.data.remarks);
      };
      FetchVisitorDetails();
      setbtnLabel("Update");
    }

    userRef.current.focus();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      //          alert(userId);

      //    console.log(userId);
      if (ClientVisitId == 0) {
        const response = await axios.post(
            INSERTVisitor_URL,
          JSON.stringify({
            VisitorCardNo: VisitorCardNo,
            VisitorName: VisitorName,
            CompanyName: CompanyName,
            CompanyAddress: CompanyAddress,
            ContactPersonName: ContactPersonName,
            ContactPersonNo: ContactPersonNo,
            EmailId: EmailId,
            Latitude: Latitude,
            Longitude: Longitude,
            Status: Status,
            Remarks: Remarks,
            UnitId: localStorage.getItem("unitId"),
            CompanyId: localStorage.getItem("companyId")
          }),
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            withCredentials: true,
          }
        );
        console.log(response.data);
        setErrMsg(response.data);
        setVisitorName("");

        Swal.fire({
          position: "top-center",
          icon: "success",
          title: response.data,
          showConfirmButton: false,
          timer: 1500,
        });

        // alert('Saved successfully.')
        navigate("/Client");
      } else {
        const response = await axios.post(
            UPDATEVisitor_URL,
          JSON.stringify({
            ClientVisitId: ClientVisitId,
            VisitorCardNo: VisitorCardNo,
            VisitorName: VisitorName,
            CompanyName: CompanyName,
            CompanyAddress: CompanyAddress,
            ContactPersonName: ContactPersonName,
            ContactPersonNo: ContactPersonNo,
            EmailId: EmailId,
            Latitude: Latitude,
            Longitude: Longitude,
            Status: Status,
            Remarks: Remarks,
            UnitId: localStorage.getItem("unitId"),
            CompanyId: localStorage.getItem("companyId")
          }),
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            withCredentials: true,
          }
        );
        console.log(response.data);
        setErrMsg(response.data);
        setVisitorName("");
        setClientVisitId(0);
        Swal.fire({
          position: "top-center",
          icon: "success",
          title: response.data,
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/Client");
      }
    } catch (error) {
      console.log("error", error);
    }
    e.preventDefault();
  };

  return (
    <>
      <div className="layoutContainer screenCenter">
        <div className="row">
          <form onSubmit={handleSubmit} style={{ position: "relative" }}>
            <div
              className="col-lg-12"
              style={{
                textAlign: "Left",
              }}
            >
               {" "} 
              <h4 style={{ paddingBottom: "20px" }}>
                <b>Client Visit Master</b>
              </h4>
            </div>
            <div
              className="row EmployeeContainer"
              style={{ border: "1px solid #ccc", padding: "20px" }}
            >

              {/* <div className="col-sm-3">
                <label className="form-controll" htmlFor="VisitorCardNo">
                  Visitor Card No
                </label>{" "}
                {/* &nbsp; */}
                {/* <input
                  type="Text"
                  className="form-controll"
                  id="VisitorCardNo"
                  onChange={(e) => setVisitorCardNo(e.target.value)}
                  ref={userRef}
                  autoComplete="off"
                  value={VisitorCardNo}
                  placeholder="Visitor Card No"
                  required
                ></input>
              </div> */} 
              <div className="col-sm-3">
                <label
                  className="form-controll"
                  htmlFor="VisitorName"
                >
                  Visitor Name
                </label>{" "}
                {/* &nbsp; */}
                <input
                  type="Text"
                  className="form-controll"
                  id="VisitorName"
                  onChange={(e) => setVisitorName(e.target.value)}
                  ref={userRef}
                  autoComplete="off"
                  value={VisitorName}
                  placeholder="Visitor Name"
                ></input>
              </div>

              <div className="col-sm-3">
                <label className="form-controll" htmlFor="CompanyName">
                Company Name
                </label>{" "}
                {/* &nbsp; */}
                <input
                  type="Text"
                  className="form-controll"
                  id="Company Name"
                  onChange={(e) => setCompanyName(e.target.value)}
                  ref={userRef}
                  autoComplete="off"
                  value={CompanyName}
                  placeholder="Company Name"
                  required
                ></input>
              </div>

              <div className="col-sm-3">
                <label className="form-controll" htmlFor="CompanyAddress">
                Company Address
                </label>{" "}
                {/* &nbsp; */}
                <input
                  type="Text"
                  className="form-controll"
                  id="CompanyAddress"
                  onChange={(e) => setCompanyAddress(e.target.value)}
                  ref={userRef}
                  autoComplete="off"
                  value={CompanyAddress}
                  placeholder="Company Address"
                // required
                ></input>
              </div>
              
              <div className="col-sm-3">
                <label className="form-controll" htmlFor="ContactPersonName">
                Contact Person Name
                </label>{" "}
                <input
                  type="Text"
                  className="form-controll"
                  id="ContactPersonName"
                  onChange={(e) => setContactPersonName(e.target.value)}
                  ref={userRef}
                  autoComplete="off"
                  value={ContactPersonName}
                  placeholder="Contact Person Name"
                // required
                ></input>
              </div>
              
              <div className="col-sm-3">
                <label className="form-controll" htmlFor="ContactPersonNo">
                Contact Person No
                </label>{" "}
                <input
                  type="Text"
                  className="form-controll"
                  id="ContactPersonNo"
                  onChange={(e) => setContactPersonNo(e.target.value)}
                  ref={userRef}
                  autoComplete="off"
                  value={ContactPersonNo}
                  placeholder="Contact Person No"
                // required
                ></input>
              </div>

              <div className="col-sm-3">
                <label className="form-controll" htmlFor="EmailId">
                Email Id
                </label>{" "}
                <input
                  type="Text"
                  className="form-controll"
                  id="EmailId"
                  onChange={(e) => setEmailId(e.target.value)}
                  ref={userRef}
                  autoComplete="off"
                  value={EmailId}
                  placeholder="EmailId"
                // required
                ></input>
              </div>

              {/* <div className="col-sm-3">
                <label className="form-controll" htmlFor="Latitude">
                Latitude
                </label>{" "}
                {/* &nbsp; */}
                {/* <input
                  type="Text"
                  className="form-controll"
                  id="Latitude"
                  onChange={(e) => setLatitude(e.target.value)}
                  ref={userRef}
                  autoComplete="off"
                  value={Latitude}
                  placeholder="Latitude"
                  required
                ></input>
              </div> */} 

              {/* <div class="col-sm-3">
                <label className="form-controll" htmlFor="Longitude">Longitude</label>{" "}
                {/* &nbsp; */}
                {/* <input
                  type="Text"
                  className="form-controll"
                  id="Longitude"
                  onChange={(e) => setLongitude(e.target.value)}
                  ref={userRef}
                  autoComplete="off"
                  value={Longitude}
                  placeholder="Longitude"
                //  required
                ></input>
              </div> */}
             
              {/* <div class="col-sm-3">
                <label className="form-controll" htmlFor="Status">Status</label>{" "}
                <input
                  type="Text"
                  className="form-controll"
                  id="Status"
                  onChange={(e) => setStatus(e.target.value)}
                  ref={userRef}
                  autoComplete="off"
                  value={Status}
                  placeholder="Status"
                //required
                ></input>
              </div> */}

              {/* <div class="col-sm-3">
                <label className="form-controll" htmlFor="Status">Remarks</label>{" "}
                <input
                  type="Text"
                  className="form-controll"
                  id="Remarks"
                  onChange={(e) => setRemarks(e.target.value)}
                  ref={userRef}
                  autoComplete="off"
                  value={Remarks}
                  placeholder="Remarks"
                //required
                ></input>
              </div> */}

              <div className="col-sm-3">
                <button
                  style={{ width: "100px" }}
                  className="btn btn-primary"
                >
                  {" "}
                  {btnLabel}
                </button>
                &nbsp;
                <button
                  style={{ width: "100px" }}
                  className="btn btn-danger"
                >
                  {" "}
                  Clear
                </button>
              </div>
              <div>
                <label>{MSG}</label>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
