import React, { useEffect, useState, useMemo, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import HeaderTop from "../../../Layout/HeaderTop";
import SideBar from "../../../Layout/Sidebar/SideBar";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { Navigate, useNavigate } from "react-router-dom";
import "./att.css";
import axios from "../../../api/axios";
import { DownloadTableExcel } from "react-export-table-to-excel";
import SideMenu from "../../../Layout/SideMenu";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Layout, Space } from "antd";
import FooterBottom from "../../../Layout/FooterBottom";
import moment from 'moment';

const GeofenceReport = () => {
  const { Header, Footer, Sider, Content } = Layout;
  const headerStyle: React.CSSProperties = {
    textAlign: "center",
    height: 64,
    paddingInline: 50,
    lineHeight: "64px",
    backgroundColor: "#ffff",
  };

  const contentStyle: React.CSSProperties = {
    float: "left",
  };

  const siderStyle: React.CSSProperties = {
    backgroundColor: "#ffff",
  };

  const footerStyle: React.CSSProperties = {
    backgroundColor: "#fff",
  };

  const [data, setData] = useState([]);
  const [Headerdata, setHeaderdata] = useState([]);

  const ViewGeofenceReportDateWiseAllEmployeeURL = "/ViewGeofenceReportDateWiseAllEmployee";
  const ViewGeofenceReportDateWiseEmployeeWiseURL = "/ViewGeofenceReportDateWiseEmployeeWise";
  const tableRef = useRef(null);
  const navigate = useNavigate();
  const [UnitItems, setUnitItems] = React.useState([]);
  const [UnitId, setUnitId] = useState(0);
  const [Month, setMonth] = useState(0);
  const [Year, setYear] = useState(0);
  const [Day, setDay] = useState(0);
  const [currentYear] = useState(() => new Date().getFullYear());
  const [currentDay] = useState(() => new Date().getDay());
  const [currentMonth] = useState(() => new Date().getMonth());
  const [SearchText, setSearchText] = useState("");
  const [EmployeeCardNo, setEmployeeCardNo] = useState("");
  const UnitListURL = "/GetUnitListFromCompanyId";
  const options = [];
  obj = {};
  var obj = {};

  obj["value"] = 0;
  obj["label"] = "Select Year";
  options.push(obj);
  for (var i = -2; i < 1; i++) {
    var obj = {};

    obj["value"] = currentYear + i;
    obj["label"] = currentYear + i;
    options.push(obj);
  }


  useEffect(() => {
    setUnitId(localStorage.getItem("unitId"));
    const doFetch = async () => {
      // const response = await fetch('https://randomuser.me/api/?results=100');
      const responseunit = await axios.get(
        UnitListURL + "?CompanyId=" + localStorage.getItem("companyId")
      );
      setUnitItems(
        responseunit.data.map(({ unitId, unitName }) => ({
          label: unitName,
          value: unitId,
        }))
      );
      //alert(new Date().getDay());
      setMonth(new Date().getMonth() + 1);
      setYear(currentYear);
      setDay(new Date().getDate());
    };
    doFetch();
  }, []);

  const UnitChange = async (e) => {
    setUnitId(e.target.value);

  };

  const [iframSrc, setIframeUrl] = useState();
  const ViewLocationDetails = (id, ltd, lnd) => {
    // navigate("/ViewLocation")//, { state: { id } });

    setIframeUrl(
      // `${googleUrl}?q==${state.ltd},${state.lnd}&hl=es;z=14&amp;output=embed`
      // `https://maps.google.com/maps?q=10.305385,77.923029&hl=es;z=14&amp;output=embed`
      `https://maps.google.com/maps?q=${ltd},${lnd}&hl=es;z=14&output=embed`
    );
    //alert(id);
    // navigate("/ViewLocation", { state: { id ,ltd,lnd} });
  };


  const ViewData = async () => {
    //alert('ok');
    if (SearchText == "") {
      const response = await axios.get(
        ViewGeofenceReportDateWiseAllEmployeeURL +
        "?UnitId=" +
        localStorage.getItem("unitId") +
        "&CompanyId=" +
        localStorage.getItem("companyId") +
        "&Month=" +
        Month +
        "&Year=" +
        Year +
        "&Day=" +
        Day
      );
      console.log(response.data);
      setData(response.data);
      //setHeaderdata(response.data.slice(0, 1));
    }
    else {
      //alert('ok1');
      const response = await axios.get(
        ViewGeofenceReportDateWiseEmployeeWiseURL +
        "?UnitId=" +
        localStorage.getItem("unitId") +
        "&CompanyId=" +
        localStorage.getItem("companyId") +
        "&EmployeeCardNo=" +
        SearchText +
        "&Month=" +
        Month +
        "&Year=" +
        Year +
        "&Day=" +
        Day
      );
      console.log(response.data);
      setData(response.data);
      //setHeaderdata(response.data.slice(0, 1));
    }
  };

  return (
    <>
      <div className="layoutContainer">
        <div className="row">
          <div className="col-lg-10" style={{ textAlign: "Left" }}>
            <h4>
              <b>Geofence Report</b>
            </h4>
          </div>

          <div className="col-lg-4">
            {/* <label className="form-controll" >Unit</label> &nbsp; */}
            <select
              onChange={UnitChange}
              className="dropdown-toggle form-controll ant-select"
            >
              {UnitItems.map((item) => (
                <option
                  key={item.value}
                  value={item.value}
                  selected={item.value == UnitId}
                >
                  {item.label}
                </option>
              ))}
            </select>
          </div>

          <div className="col-lg-4">
            {/* <label className="form-controll" >Month</label> &nbsp; */}
            <select
              value={Month}
              onChange={(e) => setMonth(e.target.value)}
              className="form-controll ant-select"
            >
              <option value="0">Select Month</option>
              <option value="1">January</option>
              <option value="2">February</option>
              <option value="3">March</option>
              <option value="4">April</option>
              <option value="5">May</option>
              <option value="6">June</option>
              <option value="7">July</option>
              <option value="8">August</option>
              <option value="9">September</option>
              <option value="10">October</option>
              <option value="11">November </option>
              <option value="12">December</option>
            </select>
          </div>
          <div className="col-lg-4">
            {/* <label className="form-controll" >Year</label> &nbsp; */}
            <select
              className="dropdown-toggle form-controll ant-select"
              value={Year}
              onChange={(e) => setYear(e.target.value)}
              style={{ margin: "8px 0;" }}
            >
              {options.map(({ value, label }, index) => (
                <option value={value}>{label}</option>
              ))}
            </select>
          </div>
          <div className="col-lg-2">
            {/* <label className="form-controll" >Month</label> &nbsp; */}
            <select
              value={Day}
              onChange={(e) => setDay(e.target.value)}
              className="ant-select"
            >
              <option value="0">Select Day</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
              <option value="13">13</option>
              <option value="14">14</option>
              <option value="15">15</option>
              <option value="16">16</option>
              <option value="17">17</option>
              <option value="18">18</option>
              <option value="19">19</option>
              <option value="20">20</option>
              <option value="21">21</option>
              <option value="22">22</option>
              <option value="23">23</option>
              <option value="24">24</option>
              <option value="25">25</option>
              <option value="26">26</option>
              <option value="27">27</option>
              <option value="28">28</option>
              <option value="29">29</option>
              <option value="30">30</option>
              <option value="31">31</option>
            </select>
          </div>

          <div className="col-lg-4">
            <input
              type="Text"
              className="form-controll"
              id="EmployeeCardNo"
              onChange={(e) => setSearchText(e.target.value)}
              autoComplete="off"
              value={SearchText}
              placeholder="EmployeeCardNo"
              required
            ></input>
          </div>
          <div className="col-lg-2" style={{ margin: "15px 0px" }}>
            <button onClick={ViewData} className="btn btn-primary">
              {" "}
              View
            </button>
          </div>
          <div className="col-lg-2" style={{ margin: "15px 0px" }}>
            <DownloadTableExcel
              filename="InOutLogReport"
              sheet="InOutLogReport"
              currentTableRef={tableRef.current}
            >
              <button className="btn btn-success">
                Export To Excell
              </button>
            </DownloadTableExcel>
          </div>
        </div>
        <div className="scollDiv mt-4">
          {/* <table >
                        <thead>
                            <tr>
                                {heading.map((head, headID) =>
                                    <th key={headID} >{head}</th>)}
                            </tr>
                        </thead>
                        <tbody>
                            {body.map((rowContent, rowID) =>
                                <tdrowContent={rowContent} key={rowID} />)}
                        </tbody>
                    </table>
                    
                    */}
          <table
            ref={tableRef}
            className="attendTable text-center"
            style={{ width: "100%" }}
          >
            <thead></thead>
            <tbody>
              <tr>
                <td>Employee Card No</td>
                <td>Employee Name</td>
                <td>Log Date</td>
                <td>InOut</td>
                <td>Latitude</td>
                <td>Longitude</td>
                <td></td>
              </tr>

              {data.map((att, index) => (
                <>
                  <tr data-index={index}>
                    <td>{att.employeeCardNo}</td>
                    <td>{att.employeeName}</td>
                    <td>{att.logDate}</td>
                    <td>{att.inOut}</td>
                    <td>{att.latitude}</td>
                    <td>{att.longitude}</td>

                    <td>
                      {/* {" "} */}
                      {<button
                        className="btn btn-outline-primary btn-sm"
                        onClick={(e) =>
                          ViewLocationDetails(att.deviceLogId, att.latitude, att.longitude) //att.employeeCardNo
                        }
                      >
                        View Location
                      </button>}

                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>

        </div>
        <iframe style={{ width: "100%", height: "50%" }} src={iframSrc}></iframe>
      </div>
    </>
  );
};
export default GeofenceReport;
