import React, { useEffect, useState, useMemo, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import HeaderTop from "../../../Layout/HeaderTop";
import SideBar from "../../../Layout/Sidebar/SideBar";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { Navigate, useNavigate } from "react-router-dom";
import "../Attendance/att.css";
import axios from "../../../api/axios";
import { DownloadTableExcel } from "react-export-table-to-excel";
import SideMenu from "../../../Layout/SideMenu";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Layout, Space } from "antd";
import FooterBottom from "../../../Layout/FooterBottom";
import moment from 'moment';

const PFReport = () => {
  // const { Header, Footer, Sider, Content } = Layout;
  // const headerStyle: React.CSSProperties = {
  //   textAlign: "center",
  //   height: 64,
  //   paddingInline: 50,
  //   lineHeight: "64px",
  //   backgroundColor: "#ffff",
  // };

  // const contentStyle: React.CSSProperties = {
  //   float: "left",
  // };

  // const siderStyle: React.CSSProperties = {
  //   backgroundColor: "#ffff",
  // };

  // const footerStyle: React.CSSProperties = {
  //   backgroundColor: "#fff",
  // };

  const [data, setData] = useState([]);
  const [Headerdata, setHeaderdata] = useState([]);

  const GetSalaryCalculationFromCompanyIdAndUnitIdURL =
    "/GetSalaryCalculationFromCompanyIdAndUnitId";
  const InOutReportListURLEmployeeCardNo =
    "/GetAttendanceFromCompanyIdAndUnitIdAndEmployeeCardNo";
  const tableRef = useRef(null);
  const navigate = useNavigate();
  const [UnitItems, setUnitItems] = React.useState([]);
  const [UnitId, setUnitId] = useState(0);
  const [Month, setMonth] = useState(0);
  const [Year, setYear] = useState(0);
  const [currentYear] = useState(() => new Date().getFullYear());
  const [SearchText, setSearchText] = useState("");
  const UnitListURL = "/GetUnitListFromCompanyId";
  const options = [];
  obj = {};
  var obj = {};

  obj["value"] = 0;
  obj["label"] = "Select Year";
  options.push(obj);
  for (var i = -2; i < 1; i++) {
    var obj = {};

    obj["value"] = currentYear + i;
    obj["label"] = currentYear + i;
    options.push(obj);
  }
  useEffect(() => {
    setUnitId(localStorage.getItem("unitId"));
    const doFetch = async () => {
      // const response = await fetch('https://randomuser.me/api/?results=100');
      const responseunit = await axios.get(
        UnitListURL + "?CompanyId=" + localStorage.getItem("companyId")
      );
      setUnitItems(
        responseunit.data.map(({ unitId, unitName }) => ({
          label: unitName,
          value: unitId,
        }))
      );
    };
    doFetch();
  }, []);

  const UnitChange = async (e) => {
    setUnitId(e.target.value);
    // const responsedepartment = await axios.get(DepartmentListURL+"?CompanyId="+localStorage.getItem('companyId')+"&UnitId="+e.target.value );
    // setDepartmentItems(responsedepartment.data.map(({ departmentId, departmentName }) => ({ label: departmentName , value: departmentId })));
    // const responsedesignation = await axios.get(DesignationListURL+"?CompanyId="+localStorage.getItem('companyId')+"&UnitId="+e.target.value );
    // setDesignationItems(responsedesignation.data.map(({ designationId, designationName }) => ({ label: designationName , value: designationId })));
    // const responseshiftgroup = await axios.get(ShiftGroupListURL+"?CompanyId="+localStorage.getItem('companyId')+"&UnitId="+e.target.value );
    // setShiftGroupItems(responseshiftgroup.data.map(({ shiftGroupId, shiftGroupName }) => ({ label: shiftGroupName , value: shiftGroupId })));
  };

  const ViewData = async () => {
    if (SearchText == "") {
      const response = await axios.get(
        GetSalaryCalculationFromCompanyIdAndUnitIdURL +
          "?UnitId=" +
          localStorage.getItem("unitId") +
          "&CompanyId=" +
          localStorage.getItem("companyId") +
          "&Month=" +
          Month +
          "&Year=" +
          Year
      );
      console.log(response.data);
      // console.log(response.data[0].FullMonthInOut);

      setData(response.data);
      setHeaderdata(response.data[0].FullMonthInOut);
    } else {
      const response = await axios.get(
        InOutReportListURLEmployeeCardNo +
          "?UnitId=" +
          localStorage.getItem("unitId") +
          "&CompanyId=" +
          localStorage.getItem("companyId") +
          "&Month=" +
          Month +
          "&Year=" +
          Year +
          "&EmployeeCardNo=" +
          SearchText
      );
      console.log(response.data);
      //console.log(response[0].data);
      setData(response.data);
      setHeaderdata(response.data[0].FullMonthInOut);
    }

    // const response = await axios.get(
    //   AbsentPresentReportListURL +
    //     "?UnitId=" +
    //     localStorage.getItem("unitId") +
    //     "&CompanyId=" +
    //     localStorage.getItem("companyId") +
    //     "&Month=" +
    //     Month +
    //     "&Year=" +
    //     Year
    // );
    // console.log(response.data);
    // setData(response.data);
    //setHeaderdata(response.data.slice(0, 1));
  };

  return (
    <>
            <div className="layoutContainer">
              <div className="row">
                <div className="col-lg-10" style={{ textAlign: "Left" }}>
                  <h4>
                    <b>PF Report</b>
                  </h4>
                </div>

                <div className="col-lg-4">
                  {/* <label className="form-controll" >Unit</label> &nbsp; */}
                  <select
                    onChange={UnitChange}
                    className="dropdown-toggle ant-select"
                  >
                    {UnitItems.map((item) => (
                      <option
                        key={item.value}
                        value={item.value}
                        selected={item.value == UnitId}
                      >
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-lg-4">
                  {/* <label className="form-controll" >Month</label> &nbsp; */}
                  <select
                    value={Month}
                    onChange={(e) => setMonth(e.target.value)}
                    className="ant-select"
                  >
                    <option value="0">Select Month</option>
                    <option value="1">January</option>
                    <option value="2">February</option>
                    <option value="3">March</option>
                    <option value="4">April</option>
                    <option value="5">May</option>
                    <option value="6">June</option>
                    <option value="7">July</option>
                    <option value="8">August</option>
                    <option value="9">September</option>
                    <option value="10">October</option>
                    <option value="11">November </option>
                    <option value="12">December</option>
                  </select>
                </div>
                <div className="col-lg-4">
                  {/* <label className="form-controll" >Year</label> &nbsp; */}
                  <select
                    className="dropdown-toggle ant-select"
                    value={Year}
                    onChange={(e) => setYear(e.target.value)}
                  >
                    {options.map(({ value, label }, index) => (
                      <option value={value}>{label}</option>
                    ))}
                  </select>
                </div>
                <div className="col-lg-4">
                  <input
                    type="Text"
                    className="form-controll"
                    id="EmployeeCardNo"
                    onChange={(e) => setSearchText(e.target.value)}
                    autoComplete="off"
                    value={SearchText}
                    placeholder="Employee CardNo"
                  ></input>
                </div>
                <div className="col-lg-2" style={{ margin: "15px 0px" }}>
                  <button onClick={ViewData} className="btn btn-primary">
                    View
                  </button>
                  &nbsp;
                  {/* <DownloadTableExcel
                    filename="AbsentPresent Report"
                    sheet="AbsentPresent"
                    currentTableRef={tableRef.current}
                  >
                    <button
                      style={{ width: "50%", height: "50px", color: "green" }}
                      className="btn"
                    >
                      <i className="fa fa-file-excel-o"></i>
                    </button>
                  </DownloadTableExcel> */}
                </div>
                <div className="col-lg-2" style={{ margin: "15px 0px" }}>
                  <DownloadTableExcel
                    filename="PF Report"
                    sheet="PF Report"
                    currentTableRef={tableRef.current}
                  >
                    <button className="btn btn-success">
                      Export to Excell
                    </button>
                  </DownloadTableExcel>
                </div>
              </div>
              <div className="scollDiv">
                {/* <table >
                        <thead>
                            <tr>
                                {heading.map((head, headID) =>
                                    <th key={headID} >{head}</th>)}
                            </tr>
                        </thead>
                        <tbody>
                            {body.map((rowContent, rowID) =>
                                <tdrowContent={rowContent} key={rowID} />)}
                        </tbody>
                    </table>
                    
                    */}
                <table
                  ref={tableRef}
                  className="attendTable"
                  style={{ width: "100%" }}
                >
                  <thead></thead>
                  <tbody>
                    {/* {data.map((att, index) => ( */}
                    <tr style={{ background: "#000" }}>
                      <td>EMPLOYEE CODE</td>
                      <td>NAME</td>
                      <td>Employee Contribution </td> 
                      <td>Company Contribution</td>                    
                    </tr>
                    {/* ))} */}

                    {data.map((att, index) => (
                      <>
                        <tr data-index={index}>
                          <td>{att.EmployeeCardNo}</td>
                          <td>{att.EmployeeName}</td>     
                          <td>{att.PFEarn}</td>
                          <td>{att.PFEarn}</td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
    </>
  );
};

export default PFReport;
