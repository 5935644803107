import { NavLink } from "react-router-dom";
import {
  FaAddressBook,
  FaAddressCard,
  FaAdversal,
  FaBars,
  FaBuilding,
  FaCampground,
  FaHome,
  FaLocationArrow,
  FaLock,
  FaMoneyBill,
  FaUser,
} from "react-icons/fa";
import { MdMessage } from "react-icons/md";
import { BiAnalyse, BiBuildingHouse, BiSearch } from "react-icons/bi";
import { BiCog } from "react-icons/bi";
import { AiFillHeart, AiTwotoneFileExclamation } from "react-icons/ai";
import { BsCartCheck } from "react-icons/bs";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import SCRIPTINDIABLUELOGO from "../../images/logo-black Blue.png";
import SidebarMenu from "./SidebarMenu";
import React from "react";
import "../../css/sidebar.css";
const routes = [
  {
    path: "/Dashboard",
    name: "Dashboard",
    icon: <FaHome />,
  },
  {
    path: "",
    name: "Masters",
    icon: <AiTwotoneFileExclamation />,
    subRoutes: [
      {
        path: "/Company",
        name: "Company",
        icon: <FaBuilding />,
      },
      {
        path: "/Users",
        name: "UserMaster ",
        icon: <FaUser />,
      },
      {
        path: "/Unit",
        name: "Unit ",
        icon: <FaLocationArrow />,
      },
      {
        path: "/Department",
        name: "Department ",
        icon: <FaCampground />,
      },
      {
        path: "/Designation",
        name: "Designation ",
        icon: <FaAdversal />,
      },
      {
        path: "/Shift",
        name: "Shift",
        icon: <FaLock />,
      },
      {
        path: "/ShiftPlaning",
        name: "ShiftPlaning",
        icon: <FaLock />,
      },
      {
        path: "/ShiftGroup",
        name: "ShiftGroup",
        icon: <FaAddressCard />,
      },
      {
        path: "/Device",
        name: "Device",
        icon: <FaAddressCard />,
      },
      {
        path: "/PaidHoliday",
        name: "Paid Holiday",
        icon: <FaAddressCard />,
      },
    ],
  },
  {
    path: "/Employee",
    name: "Employee",
    icon: <FaAddressBook />,
  },
  {
    path: "/Attendance",
    name: "Attendance",
    icon: <MdMessage />,
  },
  {
    path: "/DeviceLog",
    name: "DeviceLog",
    icon: <BiAnalyse />,
  },

  {
    path: "/Leave",
    name: "Leave",
    icon: <BsCartCheck />,
  },

  {
    path: "",
    name: "Reports",
    icon: <BiCog />,
    exact: true,
    subRoutes: [
      {
        path: "/InOutReport",
        name: "InOutReport",
        icon: <FaUser />,
      },

      {
        path: "/AbsentPresentReport",
        name: "AbsentPresentReport",
        icon: <FaMoneyBill />,
      },
      {
        path: "/AttendanceLog",
        name: "AttendanceLog",
        icon: <FaLock />,
      },
      {
        path: "/MissedPunch",
        name: "MissedPunch",
        icon: <FaLock />,
      },
    ],
  },
  {
    path: "/Support",
    name: "Support",
    icon: <MdMessage />,
  },
];

const SideBar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);
  const inputAnimation = {
    hidden: {
      width: 0,
      padding: 0,
      transition: {
        duration: 0.2,
      },
    },
    show: {
      width: "140px",
      padding: "5px 15px",
      transition: {
        duration: 0.2,
      },
    },
  };

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <div style={{}}>
      <motion.div
        animate={{
          width: isOpen ? "230px" : "45px",

          transition: {
            duration: 0.5,
            type: "spring",
            damping: 10,
          },
        }}
        className={`sidebar `}
      >
        <div className="top_section">
          <AnimatePresence>
            <a href="#">
              <img src={SCRIPTINDIABLUELOGO} height="50" alt="SCRIPT INDIA" />
            </a>
            {isOpen && (
              <motion.h1
                variants={showAnimation}
                initial="hidden"
                animate="show"
                exit="hidden"
                className="logo"
              ></motion.h1>
            )}
          </AnimatePresence>

          <div className="bars">
            <FaBars onClick={toggle} />
          </div>
        </div>
        <div className="search">
            <div className="search_icon">
              <BiSearch />
            </div>
            <AnimatePresence>
              {isOpen && (
                <motion.input
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  variants={inputAnimation}
                  type="text"
                  placeholder="Search"
                />
              )}
            </AnimatePresence>
          </div>
        <section className="routes">
          {routes.map((route, index) => {
            if (route.subRoutes) {
              return (
                <SidebarMenu
                  setIsOpen={setIsOpen}
                  route={route}
                  showAnimation={showAnimation}
                  isOpen={isOpen}
                />
              );
            }

            return (
              <NavLink
                to={route.path}
                key={index}
                className="link"
                activeClassName="active"
              >
                <div className="icon">{route.icon}</div>
                <AnimatePresence>
                  {isOpen && (
                    <motion.div
                      variants={showAnimation}
                      initial="hidden"
                      animate="show"
                      exit="hidden"
                      className="link_text"
                    >
                      {route.name}
                    </motion.div>
                  )}
                </AnimatePresence>
              </NavLink>
            );
          })}
        </section>
      </motion.div>

      {/* <main>{children}</main> */}
    </div>
  );
};

export default SideBar;
