import React from "react";

import SideBar from "../../Layout/Sidebar/SideBar";
import HeaderTop from "../../Layout/HeaderTop";
import { useRef, useState, useEffect, useContext } from "react";
//import AuthContext from '../context/AuthProvider';
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import axios from "../../api/axios";
import Swal from "sweetalert2";
import UnitMasterDDL from "../DropdownList/UnitMasterDDL";
import SideMenu from "../../Layout/SideMenu";
import { DatePicker } from "antd";
import "react-datepicker/dist/react-datepicker.css";
import { Layout, Space } from "antd";
import FooterBottom from "../../Layout/FooterBottom";

export const SalaryMaster = (props) => {
  const { Header, Footer, Sider, Content } = Layout;
  const headerStyle: React.CSSProperties = {
    textAlign: "center",
    height: 64,
    paddingInline: 50,
    lineHeight: "64px",
    backgroundColor: "#ffff",
  };

  const contentStyle: React.CSSProperties = {
    float: "left",
    // textAlign: "center",
    // minHeight: 120,
    // lineHeight: "120px",
    // color: "#fff",
    // backgroundColor: "#108ee9",
  };

  const siderStyle: React.CSSProperties = {
    backgroundColor: "#ffff",
  };

  const footerStyle: React.CSSProperties = {
    backgroundColor: "#ffff",
  };

  const InsertSalaryMasterDetailsURL = "/InsertSalaryMasterDetails";
  const UpdateSalaryMasterDetailsURL = "/UpdateSalaryMasterDetails";
  const GetSalaryMasterFromSalaryIdURL = "/GetSalaryMasterFromSalaryId";
  const userRef = useRef();
  const errRef = useRef();
  const navigate = useNavigate();
  const UnitMasterDDLRef = useRef();

  const [SalaryId, setSalaryId] = useState(0);
  const [UnitId, setUnitId] = useState(0);

  const [EmployeeCardNo, setEmployeeCardNo] = useState("");
  const [Basic, setBasic] = useState("0");
  const [BasicType, setBasicType] = useState("");
  const [Rate, setRate] = useState("0");
  const [RateType, setRateType] = useState("Daily");
  const [SPLAllownce, setSPLAllownce] = useState("0");
  const [PFRate, setPFRate] = useState("0");
  const [PFIsPercentage, setPFIsPercentage] = useState(true);

  const [EffectiveMonth, setEffectiveMonth] = useState(new Date());
  const [IsActive, setIsActive] = useState(false);
  const [CompanyId, setCompanyId] = useState("");
  const [MSG, setErrMsg] = useState("");
  const [sucecess, setSucecess] = useState(false);
  const [btnLabel, setbtnLabel] = useState(false);
  const { state } = useLocation(0);
  const [Month, setMonth] = useState(0);
  const [Year, setYear] = useState(0);
  const [currentYear] = useState(() => new Date().getFullYear());

  const handleChange = (event) => {
    if (event.target.checked) {
      console.log("✅ Checkbox is checked");
      setIsActive(true);
    } else {
      console.log("⛔️ Checkbox is NOT checked");
      setIsActive(false);
    }

    console.log(IsActive);
  };

  const setIsActiveValue = (value) => {
    setIsActive((pre) => (pre = value));
  };
  const options = [];
  obj = {};
  var obj = {};

  obj["value"] = 0;
  obj["label"] = "Select Year";
  options.push(obj);
  for (var i = -2; i < 1; i++) {
    var obj = {};

    obj["value"] = currentYear + i;
    obj["label"] = currentYear + i;
    options.push(obj);
  }
  useEffect(() => {
    setSalaryId(state.id);
    if (state.id == 0) {
      //alert('New');
      setEmployeeCardNo("");
      setIsActive(false);
      setbtnLabel("Save");
    } else {
      //alert(state.id);
      const FetchSalaryMasterDetails = async () => {
        const response = await axios.get(
          GetSalaryMasterFromSalaryIdURL + "?SalaryId=" + state.id
        );
        console.log(response.data);

        setEmployeeCardNo(response.data.employeeCardNo);
        setBasic(response.data.basic);
        setBasicType(response.data.basicType);
        setRate(response.data.rate);
        setRateType(response.data.rateType);
        setSPLAllownce(response.data.splAllownce);
        setPFRate(response.data.pfRate);
        setMonth(response.data.effectiveMonth);
        setYear(response.data.effectiveYear);
        setSalaryId(response.data.salaryId);
        // setUnitName(response.data.unitName);
        // setUnitId(response.data.unitId);
        // console.log(UnitId);
        // console.log(response.data.status);
        if (response.data.status == "true") {
          console.log("set True");
          setIsActiveValue(true);
          console.log(IsActive);
        } else {
          console.log("set False");

          // setIsActive(false);
          setIsActiveValue(false);
          console.log(IsActive);
        }
      };
      FetchSalaryMasterDetails();
      setbtnLabel("Update");
    }

    userRef.current.focus();
  }, []);

  // useEffect(() => {

  //   setErrMsg('');
  //   //UserList.apply();

  // }, [ , userName, Password ])

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      //          alert(userId);

      //    console.log(userId);
      if (SalaryId == 0) {
        console.warn(localStorage.getItem("companyId"));
        //  setUnitId(UnitMasterDDLRef.current.getUnitId());
        //console.log(UnitMasterDDLRef.current.getUnitId());
        // console.log(UnitId);
        const response = await axios.post(
          InsertSalaryMasterDetailsURL,
          JSON.stringify({
            EmployeeCardNo: EmployeeCardNo,
            Basic: Basic,
            BasicType: BasicType,
            Rate: Rate,
            RateType: RateType,
            SPLAllownce: SPLAllownce,
            PFRate: PFRate,
            PFIsPercentage: PFIsPercentage,
            EffectiveMonth: Month,
            EffectiveYear: Year,
            EntryBy: localStorage.getItem("userId"),
            UpdateBy: localStorage.getItem("userId"),
            UnitId: localStorage.getItem("unitId"),
            IsActive: IsActive,
            CompanyId: localStorage.getItem("companyId"),
          }),
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            withCredentials: true,
          }
        );
        console.log(response.data);
        setErrMsg(response.data);
        setUnitId(0);
        setIsActive(false);

        //setCompanyId('');
        // UserList
        Swal.fire({
          position: "top-center",
          icon: "success",
          title: response.data,
          showConfirmButton: false,
          timer: 1500,
        });

        // alert('Saved successfully.')
        navigate("/EmployeeSalary");
      } else {
         const response = await axios.post(
          UpdateSalaryMasterDetailsURL,
          JSON.stringify({
            SalaryId: SalaryId,
            EmployeeCardNo: EmployeeCardNo,
            Basic: Basic,
            BasicType: BasicType,
            Rate: Rate,
            RateType: RateType,
            SPLAllownce: SPLAllownce,
            PFRate: PFRate,
            PFIsPercentage: PFIsPercentage,
            EffectiveMonth: Month,
            EffectiveYear: Year,
            EntryBy: localStorage.getItem("userId"),
            UpdateBy: localStorage.getItem("userId"),
            UnitId: localStorage.getItem("unitId"),
            IsActive: IsActive,
            CompanyId: localStorage.getItem("companyId"),
          }),
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            withCredentials: true,
          }
        );
        console.log(response.data);
        setErrMsg(response.data);
        setIsActive(false);
        Swal.fire({
          position: "top-center",
          icon: "success",
          title: response.data,
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/EmployeeSalary");
      }
    } catch (error) {
      console.log("error", error);
    }
    e.preventDefault();
  };

  return (
    <>
      <Layout>
        <Header style={headerStyle}>
          <HeaderTop></HeaderTop>
        </Header>
        <Layout hasSider>
          <Sider style={siderStyle}>
            <SideMenu></SideMenu>
          </Sider>
          <Content style={contentStyle}>
            <div
              className="col-lg-12"
              style={{ padding: "20px 30px 20px 50px" }}
            >
              <div className="row">
                <form onSubmit={handleSubmit}>
                  <div className="col-12">
                    <h2>Salary Master</h2>
                  </div>
                  <div className="col-2">
                    <label htmlFor="EmployeeCardNo">EmployeeCardNo</label>{" "}
                    &nbsp;
                  </div>
                  <div className="col-2">
                    <input
                      type="Text"
                      className="form-controll"
                      id="EmployeeCardNo"
                      onChange={(e) => setEmployeeCardNo(e.target.value)}
                      ref={userRef}
                      autoComplete="off"
                      value={EmployeeCardNo}
                      placeholder="EmployeeCardNo"
                      required
                    ></input>
                  </div>
                  <div className="col-2">
                    <label htmlFor="Basic">Basic</label> &nbsp;
                  </div>
                  <div className="col-2">
                    <input
                      type="Text"
                      className="form-controll"
                      id="Basic"
                      onChange={(e) => setBasic(e.target.value)}
                      ref={userRef}
                      autoComplete="off"
                      value={Basic}
                      placeholder="Basic"
                      required
                    ></input>
                  </div>
                  <div className="col-2">
                    <label htmlFor="Rate">Rate</label> &nbsp;
                  </div>
                  <div className="col-2">
                    <input
                      type="Text"
                      className="form-controll"
                      id="Rate"
                      onChange={(e) => setRate(e.target.value)}
                      ref={userRef}
                      autoComplete="off"
                      value={Rate}
                      placeholder="Rate"
                      required
                    ></input>
                  </div>
                  <div className="col-2">
                    <label htmlFor="RateType">RateType</label> &nbsp;
                  </div>
                  <div className="col-2">
                    <select
                      value={RateType}
                      onChange={(e) => setRateType(e.target.value)}
                    >
                      <option value="Daily">Daily</option>
                      <option value="Monthly">Monthly</option>
                    </select>
                  </div>
                  <div className="col-2">
                    <label htmlFor="SPLAllownce">SPLAllownce</label> &nbsp;
                  </div>
                  <div className="col-2">
                    <input
                      type="Text"
                      className="form-controll"
                      id="SPLAllownce"
                      onChange={(e) => setSPLAllownce(e.target.value)}
                      ref={userRef}
                      autoComplete="off"
                      value={SPLAllownce}
                      placeholder="SPLAllownce"
                      required
                    ></input>
                  </div>
                  <div className="col-2">
                    <label htmlFor="PFRate">PFRate</label> &nbsp;
                  </div>
                  <div className="col-2">
                    <input
                      type="Text"
                      className="form-controll"
                      id="PFRate"
                      onChange={(e) => setPFRate(e.target.value)}
                      ref={userRef}
                      autoComplete="off"
                      value={PFRate}
                      placeholder="PFRate"
                      required
                    ></input>
                  </div>
                  <div className="col-2">
                    <label htmlFor="PFIsPercentage">PF IsPercentage</label>{" "}
                    &nbsp;
                  </div>
                  <div className="col-2">
                    <input
                      type="CheckBox"
                      id="PFIsPercentage"
                      checked={PFIsPercentage}
                      onChange={(e) => setPFIsPercentage(e.target.checked)}
                    ></input>
                  </div>
                  <div className="col-2">
                    <label htmlFor="EffectiveMonth">Effective Month</label>{" "}
                    &nbsp;
                  </div>
                  {/* <div className="col-2">
                    <DatePicker 
                       selected={EffectiveMonth}
                       name="EffectiveMonth"
                       onChange={(date) => setEffectiveMonth(date)}
                       dateFormat="dd-MMM-yyyy"
                     />
                   
                 </div> */}

                  <div className="col-lg-4">
                    {/* <label className="form-controll" >Month</label> &nbsp; */}
                    <select
                      value={Month}
                      onChange={(e) => setMonth(e.target.value)}
                      className="ant-select"
                    >
                      <option value="0">Select Month</option>
                      <option value="1">January</option>
                      <option value="2">February</option>
                      <option value="3">March</option>
                      <option value="4">April</option>
                      <option value="5">May</option>
                      <option value="6">June</option>
                      <option value="7">July</option>
                      <option value="8">August</option>
                      <option value="9">September</option>
                      <option value="10">October</option>
                      <option value="11">November </option>
                      <option value="12">December</option>
                    </select>
                  </div>
                  <div className="col-lg-4">
                    {/* <label className="form-controll" >Year</label> &nbsp; */}
                    <select
                      className="dropdown-toggle ant-select"
                      value={Year}
                      onChange={(e) => setYear(e.target.value)}
                    >
                      {options.map(({ value, label }, index) => (
                        <option value={value}>{label}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-2">
                    <label htmlFor="IsActive">Is Active</label> &nbsp;
                  </div>
                  <div className="col-2">
                    <input
                      type="CheckBox"
                      id="IsActive"
                      checked={IsActive}
                      onChange={(e) => setIsActive(e.target.checked)}
                    ></input>
                  </div>
                  <div className="col-12">
                    {/* 
<input type="RetypePassword" className="form-controll" id="RetypePassword" onChange={(e) => setPassword(e.target.value)}
              autoComplete="off" value={Password} placeholder="Retype Password" required></input> */}
                    <button className="btn btn-primary"> {btnLabel}</button>
                  </div>

                  <div>
                    <label>{MSG}</label>
                  </div>
                </form>
              </div>
            </div>
          </Content>
        </Layout>
        <Footer style={footerStyle}>
          <FooterBottom />
        </Footer>
      </Layout>
    </>
  );
};
