import React from "react";

import SideBar from "../../Layout/Sidebar/SideBar";
import HeaderTop from "../../Layout/HeaderTop";
import { useRef, useState, useEffect, useContext } from "react";
//import AuthContext from '../context/AuthProvider';
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import axios from "../../api/axios";
import Swal from "sweetalert2";
import UnitMasterDDL from "../DropdownList/UnitMasterDDL";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Layout, Space } from "antd";
import FooterBottom from "../../Layout/FooterBottom";
import SideMenu from "../../Layout/SideMenu";

export const ShiftMaster = (props) => {
  const { Header, Footer, Sider, Content } = Layout;
  const headerStyle: React.CSSProperties = {
    textAlign: "center",
    height: 64,
    paddingInline: 50,
    lineHeight: "64px",
    backgroundColor: "#ffff",
  };

  const contentStyle: React.CSSProperties = {
    float: "left",
  };

  const siderStyle: React.CSSProperties = {
    backgroundColor: "#ffff",
  };

  const footerStyle: React.CSSProperties = {
    backgroundColor: "#7dbcea",
  };
  const INSERTSHIFT_URL = "/InsertShiftDetails";
  const UPDATESHIFT_URL = "/UpdateShiftDetails";
  const SHIFTNDETAIL_URL = "/GetShiftFromShiftId";
  const userRef = useRef();
  const errRef = useRef();
  const navigate = useNavigate();
  const UnitMasterDDLRef = useRef();

  const [ShiftId, setShiftId] = useState(0);
  const [UnitId, setUnitId] = useState(0);

  const [UnitName, setUnitName] = useState("");
  const [ShiftName, setShiftName] = useState("");
  const [ShiftInTime, setShiftInTime] = useState("");
  const [ShiftOutTime, setShiftOutTime] = useState("");
  const [HalfDayMin, setHalfDayMin] = useState(240);
  const [AbsentMin, setAbsentMin] = useState(120);

  const [IsActive, setIsActive] = useState(false);
  const [CompanyId, setCompanyId] = useState("");
  const [MSG, setErrMsg] = useState("");
  const [sucecess, setSucecess] = useState(false);
  const [btnLabel, setbtnLabel] = useState(false);

  const { state } = useLocation(0);

  const handleChange = (event) => {
    if (event.target.checked) {
      console.log("✅ Checkbox is checked");
      setIsActive(true);
    } else {
      console.log("⛔️ Checkbox is NOT checked");
      setIsActive(false);
    }

    console.log(IsActive);
  };

  const setIsActiveValue = (value) => {
    setIsActive((pre) => (pre = value));
  };

  useEffect(() => {
    //setShiftId(state.id);
    if (state.id == 0) {
      //alert('New');
      setShiftName("");
      setShiftInTime("");
      setShiftOutTime("");
      setIsActive(false);
      setbtnLabel("Save");
    } else {
      // alert(state.id);
      const FetchShiftDetails = async () => {
        //alert(state.id);
        const response = await axios.get(
          SHIFTNDETAIL_URL + "?ShiftId=" + state.id
        );

        setShiftName(response.data.shiftName);
        setShiftInTime(response.data.shiftInTime);
        setShiftOutTime(response.data.shiftOutTime);
        setShiftId(response.data.shiftId);
        setUnitName(response.data.unitName);
        setUnitId(response.data.unitId);
        console.log(UnitId);
        console.log(response.data.status);
        if (response.data.status == "true") {
          console.log("set True");
          setIsActiveValue(true);
          console.log(IsActive);
        } else {
          console.log("set False");

          // setIsActive(false);
          setIsActiveValue(false);
          console.log(IsActive);
        }
      };
      FetchShiftDetails();
      setbtnLabel("Update");
    }

    userRef.current.focus();
  }, []);

  // useEffect(() => {

  //   setErrMsg('');
  //   //UserList.apply();

  // }, [ , userName, Password ])

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      //          alert(userId);

      //    console.log(userId);
      if (ShiftId == 0) {
        console.warn(localStorage.getItem("companyId"));
        setUnitId(UnitMasterDDLRef.current.getUnitId());
        console.log(UnitMasterDDLRef.current.getUnitId());
        console.log(UnitId);
        const response = await axios.post(
          INSERTSHIFT_URL,
          JSON.stringify({
            ShiftName: ShiftName,
            ShiftInTime: ShiftInTime,
            ShiftOutTime: ShiftOutTime,
            HalfDayMin: HalfDayMin,
            AbsentMin: AbsentMin,
            UnitId: UnitMasterDDLRef.current.getUnitId(),
            IsActive: IsActive,
            CompanyId: localStorage.getItem("companyId"),
          }),
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            withCredentials: true,
          }
        );
        console.log(response.data);
        setErrMsg(response.data);
        setShiftName("");
        setUnitId(0);
        setIsActive(false);

        //setCompanyId('');
        // UserList
        Swal.fire({
          position: "top-center",
          icon: "success",
          title: response.data,
          showConfirmButton: false,
          timer: 1500,
        });

        // alert('Saved successfully.')
        navigate("/Shift");
      } else {
        setUnitId(UnitMasterDDLRef.current.getUnitId());
        console.log(UnitMasterDDLRef.current.getUnitId());
        console.log(UnitId);
        const response = await axios.post(
          UPDATESHIFT_URL,
          JSON.stringify({
            ShiftId: ShiftId,
            ShiftName: ShiftName,
            ShiftInTime: ShiftInTime,
            ShiftOutTime: ShiftOutTime,
            HalfDayMin: HalfDayMin,
            AbsentMin: AbsentMin,
            UnitId: UnitId,
            IsActive: IsActive,
            CompanyId: localStorage.getItem("companyId"),
          }),
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            withCredentials: true,
          }
        );
        console.log(response.data);
        setErrMsg(response.data);
        setShiftName("");
        setShiftId(0);
        setIsActive(false);
        Swal.fire({
          position: "top-center",
          icon: "success",
          title: response.data,
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/Shift");
      }
    } catch (error) {
      console.log("error", error);
    }
    e.preventDefault();
  };

  return (
    <>
      <div className="layoutContainer screenCenter">
        <div className="loginContainer">
                <form onSubmit={handleSubmit}>
                  <h2>Shift Master</h2>
                  <input
                    type="Text"
                    className="form-controll"
                    id="ShiftName"
                    onChange={(e) => setShiftName(e.target.value)}
                    ref={userRef}
                    autoComplete="off"
                    value={ShiftName}
                    placeholder="Shift Name"
                    required
                  ></input>
                  <input
                    type="time"
                    className="without_ampm"
                    id="ShiftInTime"
                    onChange={(e) => setShiftInTime(e.target.value)}
                    ref={userRef}
                    autoComplete="off"
                    value={ShiftInTime}
                    placeholder="HH:MM"
                    required
                  ></input>
                  <input
                    type="time"
                    className="wform-controll"
                    id="ShiftOutTime"
                    onChange={(e) => setShiftOutTime(e.target.value)}
                    ref={userRef}
                    autoComplete="off"
                    value={ShiftOutTime}
                    placeholder="HH:MM"
                    required
                  ></input>
                  <input
                    type="number"
                    className="form-controll"
                    id="HalfDayMin"
                    onChange={(e) => setHalfDayMin(e.target.value)}
                    ref={userRef}
                    autoComplete="off"
                    value={HalfDayMin}
                    placeholder="Half Day Minutes"
                    required
                  ></input>
                  <input
                    type="number"
                    className="form-controll"
                    id="AbsentMin"
                    onChange={(e) => setAbsentMin(e.target.value)}
                    ref={userRef}
                    autoComplete="off"
                    value={AbsentMin}
                    placeholder="Absent Minutes"
                    required
                  ></input>
                  <UnitMasterDDL
                    ref={UnitMasterDDLRef}
                    unitId={UnitId}
                  ></UnitMasterDDL>
                  <label className="form-controll" htmlFor="IsActive">
                    Is Active
                  </label>{" "}
                  &nbsp;
                  <input
                    type="CheckBox"
                    id="IsActive"
                    checked={IsActive}
                    onChange={(e) => setIsActive(e.target.checked)}
                  ></input>
                  {/* 
<input type="RetypePassword" className="form-controll" id="RetypePassword" onChange={(e) => setPassword(e.target.value)}
              autoComplete="off" value={Password} placeholder="Retype Password" required></input> */}
                  <button className="btn btn-primary"> {btnLabel}</button>
                  <div>
                    <label>{MSG}</label>
                  </div>
                </form>
              </div>
            </div>
    </>
  );
};
