// Layout/MainLayout.js
import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import HeaderTop from "../Layout/HeaderTop";
import SideMenu from "../Layout/SideMenu";
import FooterBottom from "../Layout/FooterBottom";

import { Button, Layout, Menu, theme } from 'antd';
const { Header, Sider, Content, Footer } = Layout;

const MainLayout = () => {
    const [collapsed, setCollapsed] = useState(false);
    const [mobileView, setMobileView] = useState(false);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();


  
  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setMobileView(true);
      setCollapsed(true);
    } else {
      setMobileView(false);
      setCollapsed(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleBackdropClick = () => {
    setCollapsed(true);
  };

  const headerStyle: React.CSSProperties = {
    height: "5 %",
    backgroundColor: "#ffff",
    padding: 0,
    // background: colorBgContainer,
    position: 'sticky',
    top: 0,
    zIndex: 99,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
};


const siderStyle: React.CSSProperties = {
    backgroundColor: "#ffff",
    height: 'calc(100vh - 64px)',
    position: mobileView ? 'absolute' : 'relative',
  };

  const footerStyle: React.CSSProperties = {
    backgroundColor: "#eee",
    height: "5 %",
  };
  return (
    <Layout style={{ minHeight: '100vh' }}>
    <Header style={headerStyle}>
        <HeaderTop onClick={() => setCollapsed(!collapsed)}></HeaderTop>
    </Header>
    <Layout hasSider >
    {mobileView && !collapsed && (
          <div className="backdrop" onClick={handleBackdropClick}></div>
        )}
        {(!mobileView || !collapsed) &&(
      <Sider style={siderStyle} trigger={null} collapsible collapsed={collapsed}>
      <SideMenu></SideMenu>
      </Sider>)}
     
        <Content className="mobileFooterAdjust"
         style={{
                paddingBottom: 50,
            //     margin: '24px 16px',
            //     padding: 24,
            //     minHeight: 280,
            //     background: colorBgContainer,
            //     borderRadius: borderRadiusLG,
          }}
        >
            <Outlet />
        </Content>
        <Footer style={footerStyle}>
          <FooterBottom />
        </Footer>
      </Layout>
    </Layout>
  );
};
export default MainLayout;