import React, { useEffect, useState, useMemo, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import HeaderTop from "../../Layout/HeaderTop";
import SideBar from "../../Layout/Sidebar/SideBar";
import { Link } from "react-router-dom";
import { DownloadTableExcel } from "react-export-table-to-excel";
import Swal from "sweetalert2";
import { Navigate, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Layout, Space } from "antd";
import FooterBottom from "../../Layout/FooterBottom";
import SideMenu from "../../Layout/SideMenu";

import {
  Container,
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
} from "reactstrap";
import TableContainer from "../Util/TableContainer";
//import 'bootstrap/dist/css/bootstrap.min.css';
import { SelectColumnFilter } from "../Util/filters";
import axios from "../../api/axios";

const Unit = () => {
  const { Header, Footer, Sider, Content } = Layout;
  const headerStyle: React.CSSProperties = {
    textAlign: "center",
    height: 64,
    paddingInline: 50,
    lineHeight: "64px",
    backgroundColor: "#ffff",
  };

  const contentStyle: React.CSSProperties = {
    float: "left",
  };

  const siderStyle: React.CSSProperties = {
    backgroundColor: "#ffff",
  };

  const footerStyle: React.CSSProperties = {
    backgroundColor: "#fff",
  };

  const [data, setData] = useState([]);
  const UserListURL = "/GetUnitListFromCompanyId";
  const tableRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const doFetch = async () => {
      // const response = await fetch('https://randomuser.me/api/?results=100');
      const response = await axios.get(
        UserListURL + "?CompanyId=" + localStorage.getItem("companyId")
      );

      //   const body = await response.json();
      //   const contacts = body.data;
      response.data.shift();
      console.log(response.data);
      setData(response.data);
    };
    doFetch();
  }, []);

  const EditData = (id) => {
    //let { id} = data;
    // localStorage.setItem('EditUserId', id);
    //   alert(id);
    navigate("/UnitMaster", { state: { id } });
  };

  const columns = useMemo(
    () => [
      // {
      //   Header: () => null,
      //   id: 'expander', // 'id' is required
      //   Cell: ({ row }) => (
      //     <span {...row.getToggleRowExpandedProps()}>
      //       {row.isExpanded ? '👇' : '👉'}
      //     </span>
      //   ),
      // },
      {
        Header: "#",
        accessor: "rowNum",
        //disableSortBy: true,
        //filterable: false,
        disableFilters: true,
        // Filter: SelectColumnFilter,
        //filter: 'equals',
      },
      {
        Header: "Unit Name",
        accessor: "unitName",
        //disableSortBy: false,
        // Filter: SelectColumnFilter,
        // filter: 'equals',
      },
      {
        Header: "Company Name",
        accessor: "companyName",
      },
      {
        Header: "Address",
        accessor: "address",
      },
      // {
      //   Header: 'Email',
      //   accessor: 'email',
      // },
      {
        Header: "Status",
        Filter: SelectColumnFilter,
        accessor: "status",
      },
      {
        Header: "Action", //() => null,
        id: "unitId", // 'id' is required
        accessor: "unitId",
        disableFilters: true,
        Cell: ({ row }) => (
          <span {...row}>
            <button
              style={{ width: "40%", height: "40px", color: "green" }}
              onClick={(e) => {
                EditData(row.cells[5].value);
              }}
              className="btn"
            >
              <i className="fa fa-pencil-square-o"></i>
            </button>
            {/* &nbsp;
           <button style={{width:'40%', height:'40px' ,color:'red' }} onClick={(e) => {InActiveData(row.cells[4].value)}} className="btn" ><i className='fa fa-close'></i></button> */}
          </span>
        ),
      },
      // {
      //   Header: 'Hemisphere',
      //   accessor: (values) => {
      //     const { latitude, longitude } = values.location.coordinates;
      //     const first = Number(latitude) > 0 ? 'N' : 'S';
      //     const second = Number(longitude) > 0 ? 'E' : 'W';
      //     return first + '/' + second;
      //   },
      //   disableSortBy: true,
      //   Filter: SelectColumnFilter,
      //   filter: 'equals',
      //   Cell: ({ cell }) => {
      //     const { value } = cell;

      //     const pickEmoji = (value) => {
      //       let first = value[0]; // N or S
      //       let second = value[2]; // E or W
      //       const options = ['⇖', '⇗', '⇙', '⇘'];
      //       let num = first === 'N' ? 0 : 2;
      //       num = second === 'E' ? num + 1 : num;
      //       return options[num];
      //     };

      //     return (
      //       <div style={{ textAlign: 'center', fontSize: 18 }}>
      //         {pickEmoji(value)}
      //       </div>
      //     );
      //   },
      // },
    ],
    []
  );

  return (
    <>
      <Layout>
        <Header style={headerStyle}>
          <HeaderTop></HeaderTop>
        </Header>
        <Layout hasSider>
          <Sider style={siderStyle}>
            <SideMenu></SideMenu>
          </Sider>
          <Content style={contentStyle}>
            <div className="col-lg-11" style={{ padding: "20px 50px" }}>
              <div className="row">
                <div className="col-lg-10" style={{ textAlign: "Left" }}>
                  {" "}
                  <h4>
                    <b>Unit List</b>
                  </h4>
                </div>
                <div className="col-lg-2">
                  {/* <button onClick={(e) => {EditData(0)}}  className="btn btn-success">Add New (+)</button> */}
                  <button
                    onClick={(e) => {
                      EditData(0);
                    }}
                    className="btn btn-primary"
                  >
                    Add New (+)
                  </button>
                  {/*           
&nbsp;   <DownloadTableExcel
                    filename="Unit table"
                    sheet="users"
                    currentTableRef={tableRef.current}
                >                   
                
<button style={{width:'50%', height:'50px' ,color:'green' }} className="btn" ><i className='fa fa-file-excel-o'></i></button>
          
                </DownloadTableExcel>
                 */}
                </div>
              </div>
              <div style={{ paddingTop: "20px" }}>
                <TableContainer
                  table
                  table-striped
                  bordered
                  table-sm
                  columns={columns}
                  data={data}
                  tableRef={tableRef}
                />
              </div>
            </div>
          </Content>
        </Layout>
        <Footer style={footerStyle}>
          <FooterBottom />
        </Footer>
      </Layout>
    </>
  );
};

export default Unit;
