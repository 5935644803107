import React from "react";

import SideBar from "../../Layout/Sidebar/SideBar";
import HeaderTop from "../../Layout/HeaderTop";
import { useRef, useState, useEffect, useContext } from "react";
//import AuthContext from '../context/AuthProvider';
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import axios from "../../api/axios";
import Swal from "sweetalert2";
import UnitMasterDDL from "../DropdownList/UnitMasterDDL";
import { DatePicker } from "antd";
import "react-datepicker/dist/react-datepicker.css";
import { Layout, Space } from "antd";
import FooterBottom from "../../Layout/FooterBottom";
import SideMenu from "../../Layout/SideMenu";
import dayjs from "dayjs";

export const LeaveMaster = (props) => {
  const { Header, Footer, Sider, Content } = Layout;
  const headerStyle: React.CSSProperties = {
    textAlign: "center",
    height: 64,
    paddingInline: 50,
    lineHeight: "64px",
    backgroundColor: "#ffff",
  };

  const contentStyle: React.CSSProperties = {
    float: "left",
  };

  const siderStyle: React.CSSProperties = {
    backgroundColor: "#ffff",
  };

  const footerStyle: React.CSSProperties = {
    backgroundColor: "#7dbcea",
  };
  const InsertLeaveMasterDetailsURL = "/InsertLeaveMasterDetails";
  const UpdateLeaveMasterDetailsUrl = "/UpdateLeaveMasterDetails";
  const GetLeaveFromLeaveMasterIdUrl = "/GetLeaveFromLeaveMasterId";
  const userRef = useRef();
  const errRef = useRef();
  const navigate = useNavigate();
  const UnitMasterDDLRef = useRef();
  const [LeaveMasterId, setLeaveMasterId] = useState(0);
  const [UnitId, setUnitId] = useState(0);
  const [UnitName, setUnitName] = useState("");
  const [EmployeeCardNo, setEmployeeCardNo] = useState("");
  //const [EmployeeId, setEmployeeId] = useState("");
  const [LeaveType, setLeaveType] = useState("");
  let newDateTime = new Date().toLocaleTimeString();
  const [FromDate, setFromDate] = useState(new Date());
  const [ToDate, setToDate] = useState(new Date());
  const [LeaveResion, setLeaveResion] = useState("");
  const [CompanyId, setCompanyId] = useState("");
  const [MSG, setErrMsg] = useState("");
  const [sucecess, setSucecess] = useState(false);
  const [btnLabel, setbtnLabel] = useState(false);

  const { state } = useLocation(0);

  //const handleChange = (event) => {
  //  if (event.target.checked) {
    //  console.log("✅ Checkbox is checked");
   //   setIsActive(true);
  //  } else {
     /// console.log("⛔️ Checkbox is NOT checked");
     // setIsActive(false);
   // }

   // console.log(IsActive);
  //};//

  //const setLoanFlagValue = (value) => {
    //setLoanFlag((pre) => (pre = value));
 /// };

  useEffect(() => {
    setLeaveMasterId(state.id);
    if (state.id == 0) {
      //alert('New');
      setEmployeeCardNo("");
      setLeaveType("");
      //setLoanInsttalmentAmount(0);
      //setNoOfInstallment(0);
      //setLoanFlag(false);
      setbtnLabel("Save");
    } else {
     // alert(state.id);
      const FetchLeaveMasterDetails = async () => {
        const response = await axios.get(
          GetLeaveFromLeaveMasterIdUrl + "?LeaveMasterId=" + state.id
        );
      //  setEmployeeId(response.data.EmployeeId);
        setEmployeeCardNo(response.data.employeeCardNo);
        setLeaveType(response.data.leaveType);
        setFromDate(response.data.fromDate);
        setToDate(response.data.toDate);
        setLeaveResion(response.data.leaveResion);
        setLeaveMasterId(response.data.leaveMasterId);

      //  if (response.data.LoanFlag == "true") {
         // console.log("set True");
         // setLoanFlagValue(true);
          //console.log(IsActive);
      //  } else {
        //  console.log("set False");

          // setIsActive(false);
          //setLoanFlagValue(false);
         // console.log(IsActive);
        //}//
      };
      FetchLeaveMasterDetails();
      setbtnLabel("Update");
    }

    userRef.current.focus();
  }, []);

  // useEffect(() => {

  //   setErrMsg('');
  //   //UserList.apply();

  // }, [ , userName, Password ])

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      //          alert(userId);

      //    console.log(userId);
      if (LeaveMasterId == 0) {
        console.warn(localStorage.getItem("companyId"));
        setUnitId(localStorage.getItem("unitId"));
        console.log(localStorage.getItem("unitId"));

        const response = await axios.post(
          InsertLeaveMasterDetailsURL,
          JSON.stringify({
            EmployeeCardNo: EmployeeCardNo,
            //EmployeeId: EmployeeId,
            LeaveType:LeaveType,
            FromDate:FromDate,
            ToDate:ToDate,
            LeaveResion: LeaveResion,
            UnitId:  localStorage.getItem("unitId"),
            CompanyId: localStorage.getItem("companyId"),
            EntryBy: localStorage.getItem("userId"),
            UpdateBy: localStorage.getItem("userId"),
          }),
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            withCredentials: true,
          }
        );
        console.log(response.data);
        setErrMsg(response.data);
        setLeaveType("");
        setLeaveMasterId(0);
        setLeaveResion("");
        setEmployeeCardNo("");
        //setIsActive(false);

        //setCompanyId('');
        // UserList
        Swal.fire({
          position: "top-center",
          icon: "success",
          title: response.data,
          showConfirmButton: false,
          timer: 1500,
        });

        // alert('Saved successfully.')
        navigate("/Leave");
      } else {
        setUnitId(localStorage.getItem("unitId"));
        console.log(localStorage.getItem("unitId"));
      //  console.log(UnitId);
        const response = await axios.post(
          UpdateLeaveMasterDetailsUrl,
          JSON.stringify({
            LeaveMasterId: LeaveMasterId,
            EmployeeCardNo: EmployeeCardNo,
            //EmployeeId: EmployeeId,
            LeaveType:LeaveType,
            FromDate:FromDate,
            ToDate:ToDate,
            LeaveResion: LeaveResion,
            UnitId: localStorage.getItem("unitId"),
            CompanyId: localStorage.getItem("companyId"),
            EntryBy: localStorage.getItem("userId"),
            UpdateBy: localStorage.getItem("userId"),
          }),
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            withCredentials: true,
          }
        );
        console.log(response.data);
        setErrMsg(response.data);
        setLeaveType("");
        setLeaveMasterId(0);
        setLeaveResion("");
        setEmployeeCardNo("");
       // setIsActive(false);
        Swal.fire({
          position: "top-center",
          icon: "success",
          title: response.data,
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/Leave");
      }
    } catch (error) {
      console.log("error", error);
    }
    e.preventDefault();
  };

  return (
    <>
      <Layout>
        <Header style={headerStyle}>
          <HeaderTop></HeaderTop>
        </Header>
        <Layout hasSider>
          <Sider style={siderStyle}>
            <SideMenu></SideMenu>
          </Sider>
          <Content style={contentStyle}>
            <div className="container">
              <div className="loginContainer">
                <form onSubmit={handleSubmit}>
                  <h2>Leave Master</h2>
                  <input
                    type="Text"
                    className="form-controll"
                    id="EmployeeCardNo"
                    onChange={(e) => setEmployeeCardNo(e.target.value)}
                    ref={userRef}
                    autoComplete="off"
                    value={EmployeeCardNo}
                    placeholder="Employee Card No"
                    required
                  ></input>
                    <input
                    type="Text"
                    className="form-controll"
                    id="LeaveType"
                    onChange={(e) => setLeaveType(e.target.value)}
                    ref={userRef}
                    autoComplete="off"
                    value={LeaveType}
                    placeholder="Leave Type"
                    required
                  ></input>
                           <div className="col-sm-6">
                      <label className="form-controll" htmlFor="FromDate">
                        From Date
                      </label>{" "}
                      <br></br>
                     
                      <DatePicker
                        className="form-controll"
                        format="DD-MMM-YYYY "
                        onChange={(date) => setFromDate(date)}
                        value={dayjs(FromDate)}
                      />
                    </div>

                    <div className="col-sm-6">
                      <label className="form-controll" htmlFor="ToDate">
                        To Date
                      </label>{" "}
                      <br></br>
                     
                      <DatePicker
                        className="form-controll"
                        format="DD-MMM-YYYY "
                        onChange={(date) => setToDate(date)}
                        value={dayjs(ToDate)}
                      />
                    </div>

               
                    <input
                    type="Text"
                    className="form-controll"
                    id="LeaveResion"
                    onChange={(e) => setLeaveResion(e.target.value)}
                    ref={userRef}
                    autoComplete="off"
                    value={LeaveResion}
                    placeholder="Leave Resion"
                    required
                  ></input>
                  {/* 
<input type="RetypePassword" className="form-controll" id="RetypePassword" onChange={(e) => setPassword(e.target.value)}
              autoComplete="off" value={Password} placeholder="Retype Password" required></input> */}
                  <button className="btn btn-primary"> {btnLabel}</button>
                  <div>
                    <label>{MSG}</label>
                  </div>
                </form>
              </div>
            </div>
          </Content>
        </Layout>
        <Footer style={footerStyle}>
          <FooterBottom />
        </Footer>
      </Layout>
    </>
  );
};

