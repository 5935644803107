import React from "react";

import SideBar from "../../Layout/Sidebar/SideBar";
import HeaderTop from "../../Layout/HeaderTop";
import { useRef, useState, useEffect, useContext } from "react";
//import AuthContext from '../context/AuthProvider';
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import axios from "../../api/axios";
import Swal from "sweetalert2";
import UnitMasterDDL from "../DropdownList/UnitMasterDDL";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Layout, Space } from "antd";
import FooterBottom from "../../Layout/FooterBottom";
import SideMenu from "../../Layout/SideMenu";

export const UserMaster = (props) => {
  const { Header, Footer, Sider, Content } = Layout;
  const headerStyle: React.CSSProperties = {
    textAlign: "center",
    height: 64,
    paddingInline: 50,
    lineHeight: "64px",
    backgroundColor: "#ffff",
  };

  const contentStyle: React.CSSProperties = {
    float: "left",
  };

  const siderStyle: React.CSSProperties = {
    backgroundColor: "#ffff",
  };

  const footerStyle: React.CSSProperties = {
    backgroundColor: "#7dbcea",
  };
  const INSERTUSER_URL = "/InsertUserDetails";
  const UPDATEUSER_URL = "/UpdateUserDetails";
  const USERDETAIL_URL = "/GetUserFromUserId";
  const userRef = useRef();
  const errRef = useRef();
  const navigate = useNavigate();
  const [UnitId, setUnitId] = useState(0);
  const UnitMasterDDLRef = useRef();

  const [userId, setUserId] = useState(0);

  const [userName, setuserName] = useState("");
  const [Password, setPassword] = useState("");
  const [IsActive, setIsActive] = useState(false);
  const [CompanyId, setCompanyId] = useState("");
  const [MSG, setErrMsg] = useState("");
  const [sucecess, setSucecess] = useState(false);
  const [UserType, setUserType] = useState(0);

  const { state } = useLocation(0);

  useEffect(() => {
    setUserId(state.id);
    if (state.id == 0) {
      setuserName("");
      setPassword("");
      setIsActive(false);
    } else {
      // alert(state.id);
      const FetchUserDetails = async () => {
        const response = await axios.get(
          USERDETAIL_URL + "?UserId=" + state.id
        );

        setuserName(response.data.userName);
        setPassword(response.data.password);
        setUnitId(response.data.unitId);
        setUserType(response.data.userType);

        setUserId(response.data.userId);
        console.log(response.data.status);
        if (response.data.status == "true") {
          console.log("set True");
          setIsActive(true);
        } else {
          console.log("set False");

          setIsActive(false);
        }
      };
      FetchUserDetails();
    }

    userRef.current.focus();
  }, []);

  // useEffect(() => {

  //   setErrMsg('');
  //   //UserList.apply();

  // }, [ , userName, Password ])

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      alert(userId);

      console.log(userId);
      if (userId == 0) {
        console.warn(localStorage.getItem("companyId"));
        const response = await axios.post(
          INSERTUSER_URL,
          JSON.stringify({
            UserName: userName,
            Password: Password,
            UnitId: UnitMasterDDLRef.current.getUnitId(),
            UserType: UserType,
            IsActive: IsActive,
            CompanyId: localStorage.getItem("companyId"),
          }),
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            withCredentials: true,
          }
        );
        console.log(response.data);
        setErrMsg(response.data);
        setuserName("");
        setPassword("");
        setIsActive(false);

        //setCompanyId('');
        // UserList
        Swal.fire({
          position: "top-center",
          icon: "success",
          title: response.data,
          showConfirmButton: false,
          timer: 2500,
        });

        // alert('Saved successfully.')
        navigate("/Users");
      } else {
        const response = await axios.post(
          UPDATEUSER_URL,
          JSON.stringify({
            UserName: userName,
            Password: Password,
            UnitId: UnitId,
            UserType: UserType,
            UserId: userId,
            IsActive: IsActive,
            CompanyId: localStorage.getItem("companyId"),
          }),
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            withCredentials: true,
          }
        );
        console.log(response.data);
        setErrMsg(response.data);
        setuserName("");
        setPassword("");
        setIsActive(false);
        Swal.fire({
          position: "top-center",
          icon: "success",
          title: response.data,
          showConfirmButton: false,
          timer: 2500,
        });
        navigate("/Users");
      }
    } catch (error) {
      console.log("error", error);
      // if (!error?.response) {
      //   setErrMsg('No Server Response');
      // }
      // else if (error.response?.status == 400) {
      //   setErrMsg('Missin Username or password');

      // } else if (error.response?.status == 401) {
      //   setErrMsg('Un Autharised');

      // }
      // else {
      //   setErrMsg('Login Fail');
      // }
      // errRef.current.focus();
    }
    e.preventDefault();
  };

  return (
    <>
      <div className="layoutContainer screenCenter">
        <div className="loginContainer">
          <form onSubmit={handleSubmit}>
            <h2>User Master</h2>
            <input
              type="Text"
              className="form-controll"
              id="username"
              onChange={(e) => setuserName(e.target.value)}
              ref={userRef}
              autoComplete="off"
              value={userName}
              placeholder="User Name"
              required
            ></input>
            <input
              type="Password"
              className="form-controll"
              id="password"
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="off"
              value={Password}
              placeholder="Password"
              required
            ></input>
            <UnitMasterDDL
              ref={UnitMasterDDLRef}
              unitId={UnitId}
            ></UnitMasterDDL>
            <select
              onChange={(e) => setUserType(e.target.value)}
              className="dropdown-toggle ant-select"
            >
              <option key={0} value={0} selected={UserType}>
                Select One
              </option>
              <option key={1} value={1} selected={UserType}>
                Administrator
              </option>
              <option key={2} value={2} selected={UserType}>
                Unit HR
              </option>
            </select>
            <label className="form-controll" htmlFor="IsActive">
              Is Active
            </label>{" "}
            &nbsp;
            <input
              type="CheckBox"
              className="form-controll"
              id="IsActive"
              checked={IsActive}
              onChange={(e) => setIsActive(e.target.checked)}
            ></input>
            {/* 
<input type="RetypePassword" className="form-controll" id="RetypePassword" onChange={(e) => setPassword(e.target.value)}
              autoComplete="off" value={Password} placeholder="Retype Password" required></input> */}
            <button className="btn btn-primary"> Save</button>
            <div>
              <label>{MSG}</label>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
