import React, { forwardRef, useImperativeHandle } from 'react'
import { useRef, useState, useEffect, useContext } from 'react'
import axios from '../../api/axios'
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
const UnitMasterDDL = forwardRef ((props, _ref) => {
const [items, setItems] = React.useState([]);
const [UnitId, setUnitId] = useState(0);
const UserListURL = '/GetUnitListFromCompanyId';

type UnitObj ={
id : number,
label: string

}
const  UnitOptions = items.map((UnitObj, index) =>({
id:  items[index].unitId,
label: items[index].unitName,

}))
{
}
    
useImperativeHandle(_ref, ()=> ({
  getUnitId:()=>{
          return UnitId; 
  },
}));

const handleChange = async (e) => {
  setUnitId( e.target.value);
//console.warn(e.target.value);
//console.warn(UnitId);
};

 useEffect(() => {
        const doFetch = async () => {
         // const response = await fetch('https://randomuser.me/api/?results=100');
          const response = await axios.get(UserListURL+"?CompanyId="+localStorage.getItem('companyId') );   
      console.log(response.data);
      console.log(UnitOptions);
     // response.data.upShift({unitId:0,rowNum:0, unitName:'Select One Unit'});
         // var body =[{unitId:0,rowNum:0, unitName:'Select One Unit'}];
          //body.push(response.data);
          //console.log(body);
           setItems(response.data.map(({ unitId, unitName }) => ({ label: unitName , value: unitId })));
        };
        doFetch();
      }, []);
  return (
<>
{/* <Autocomplete
  disablePortal
  id="combo-box-demo"
  options={UnitOptions}
  sx={{ width: 300 }}
  renderInput={(params) => <TextField {...params} label="Select One Unit" />}
/> */}

    <select        onChange={handleChange}
    className="dropdown-toggle" >
    {items.map(item => (
      <option
        key={item.value}
        value={item.value}
        selected={item.value === props.unitId}
      >
        {item.label}
      </option>
    ))}
  </select>
  </>
  )
});

export default UnitMasterDDL