import React, { useEffect, useState, useMemo, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import HeaderTop from "../../../Layout/HeaderTop";
import SideBar from "../../../Layout/Sidebar/SideBar";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { Navigate, useNavigate } from "react-router-dom";
import "./att.css";
import axios from "../../../api/axios";
import { DownloadTableExcel } from "react-export-table-to-excel";
import moment from "moment";
import { Button, Modal } from "react-bootstrap";

import type { RangePickerProps } from "antd/es/date-picker";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import DatePicker from "antd/es/date-picker";
import "react-datepicker/dist/react-datepicker.css";
import { Layout, Space } from "antd";
import FooterBottom from "../../../Layout/FooterBottom";
import SideMenu from "../../../Layout/SideMenu";

const MissedPunch = () => {
  const { Header, Footer, Sider, Content } = Layout;
  const headerStyle: React.CSSProperties = {
    textAlign: "center",
    height: 64,
    paddingInline: 50,
    lineHeight: "64px",
    backgroundColor: "#ffff",
  };

  const contentStyle: React.CSSProperties = {
    float: "left",
  };

  const siderStyle: React.CSSProperties = {
    backgroundColor: "#ffff",
  };

  const footerStyle: React.CSSProperties = {
    backgroundColor: "#fff",
  };
  const [data, setData] = useState([]);
  const [Headerdata, setHeaderdata] = useState([]);

  const tableRef = useRef(null);
  const navigate = useNavigate();
  const [UnitItems, setUnitItems] = React.useState([]);
  const [ShiftItems, setShiftItems] = React.useState([]);
  const [UnitId, setUnitId] = useState(0);
  const [AttendanceId, setAttendanceId] = useState(0);
  const [ShiftId, setShiftId] = useState(0);
  const [InTime, setInTime] = useState(new Date());
  const [AttendanceDate, setAttendanceDate] = useState(new Date());
  const [OutTime, setOutTime] = useState(new Date());

  const newDate = useRef(new Date());

  const [ShiftInTime, setShiftInTime] = useState();
  const [ShiftOutTime, setShiftOutTime] = useState();
  const [Remarks, setRemarks] = useState("");
  const [missedPunchRadiovalue, setmissedPunchRadiovalue] =
    useState("MissedPunch");

  const [PunchRadiovalue, setPunchRadiovalue] = useState("MonthWise");
  const [IsInTimeChange, setIsInTimeChange] = useState(false);

  const [IsOutTimeChange, setIsOutTimeChange] = useState(false);

  const [IsShiftChange, setIsShiftChange] = useState(false);

  const [Month, setMonth] = useState(0);
  const [Year, setYear] = useState(0);
  const [currentYear] = useState(() => new Date().getFullYear());
  const [EmployeeCardNo, setEmployeeCardNo] = useState("");
  const [EmployeeCardNoUpdate, setEmployeeCardNoUpdate] = useState("");

  const [EmployeeNameUpdate, setEmployeeNameUpdate] = useState("");
  const [divUpdateDisplay, setdivUpdateDisplay] = useState("none");

  const GetMissedPunchMonthWiseURL = "/GetMissedPunchMonthWise";
  const GetMissedPunchMonthWiseEmployeeCardNoWiseURL =
    "/GetMissedPunchMonthWiseEmployeeCardNoWise";
  const GetPunchMonthWiseAndEmployeeCodeWiseURL =
    "/GetPunchMonthWiseAndEmployeeCodeWise";
  const UnitListURL = "/GetUnitListFromCompanyId";
  const ShiftListURL = "/GetShiftListFromCompanyId";
  const AttendanceURL = "/GetAttendanceAttendanceIdWise";
  const UpdateAttendanceURL = "/UpdateAttendanceDetails";
  const AbsentAttendanceURL = "/AbsentAttendanceDetails";
  const HalfDayAttendanceDetailsURL = "/HalfDayAttendanceDetails";
  const ShiftFromShiftIdURL = "/GetShiftFromShiftId";

  const [ModelState, setModelState] = useState(false);
  const options = [];
  obj = {};
  var obj = {};

  obj["value"] = 0;
  obj["label"] = "Select Year";
  options.push(obj);
  for (var i = -2; i < 1; i++) {
    var obj = {};

    obj["value"] = currentYear + i;
    obj["label"] = currentYear + i;
    options.push(obj);
  }
  useEffect(() => {
    const doFetch = async () => {
      // const response = await fetch('https://randomuser.me/api/?results=100');
      const responseunit = await axios.get(
        UnitListURL + "?CompanyId=" + localStorage.getItem("companyId")
      );
      setUnitItems(
        responseunit.data.map(({ unitId, unitName }) => ({
          label: unitName,
          value: unitId,
        }))
      );
      setUnitId(localStorage.getItem("unitId"));
      const responseShift = await axios.get(
        ShiftListURL + "?CompanyId=" + localStorage.getItem("companyId")
      );
      setShiftItems(
        responseShift.data.map(({ shiftId, shiftName }) => ({
          label: shiftName,
          value: shiftId,
        }))
      );
      //console.log(Intime);
    };
    doFetch();
  }, []);

  const setIsInTimeChangeValue = (value) => {
    setIsInTimeChange((pre) => (pre = value));
  };
  const setIsOutTimeChangeValue = (value) => {
    setIsOutTimeChange((pre) => (pre = value));
  };
  const setIsShiftChangeValue = (value) => {
    setIsShiftChange((pre) => (pre = value));
  };
  const UpdateAttendanceData = async (e) => {
    // alert(Remarks);
    if ((IsShiftChange || IsInTimeChange || IsOutTimeChange) && Remarks != "") {
      const response = await axios.post(
        UpdateAttendanceURL,
        JSON.stringify({
          AttendanceId: AttendanceId,
          IsShiftChange: IsShiftChange,
          IsInTimeChange: IsInTimeChange,
          IsOutTimeChange: IsOutTimeChange,
          EmployeeCardNo: EmployeeCardNoUpdate,
          ShiftInTime: ShiftInTime,
          ShiftOutTime: ShiftOutTime,
          InTime: InTime,
          OutTime: OutTime,
          ShiftId: ShiftId,
          ManualPunchBy: localStorage.getItem("userId"),
          UnitId: localStorage.getItem("unitId"),
          CompanyId: localStorage.getItem("companyId"),
          Remarks: Remarks,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          withCredentials: true,
        }
      );
      console.log(response.data);
      setdivUpdateDisplay("none");
      ViewData();
      Swal.fire({
        position: "top-center",
        icon: "success",
        title: response.data,
        showConfirmButton: false,
        timer: 2500,
      });
    } else {
      Swal.fire({
        position: "top-center",
        icon: "warning",
        title:
          "Please Select One IN/OUT/Shift CheckBox to Update Attendance With Remarks",
        showConfirmButton: false,
        timer: 5500,
      });
    }
  };
  const AbsentAttendanceData = async (e) => {
    // alert(Remarks);
    if (Remarks != "") {
      const response = await axios.post(
        AbsentAttendanceURL,
        JSON.stringify({
          AttendanceId: AttendanceId,
          IsShiftChange: IsShiftChange,
          IsInTimeChange: IsInTimeChange,
          IsOutTimeChange: IsOutTimeChange,
          EmployeeCardNo: EmployeeCardNoUpdate,
          ShiftInTime: ShiftInTime,
          ShiftOutTime: ShiftOutTime,
          InTime: InTime,
          OutTime: OutTime,
          ShiftId: ShiftId,
          ManualPunchBy: localStorage.getItem("userId"),
          UnitId: localStorage.getItem("unitId"),
          CompanyId: localStorage.getItem("companyId"),
          Remarks: Remarks,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          withCredentials: true,
        }
      );
      console.log(response.data);
      setdivUpdateDisplay("none");
      ViewData();
      Swal.fire({
        position: "top-center",
        icon: "success",
        title: response.data,
        showConfirmButton: false,
        timer: 2500,
      });
    } else {
      Swal.fire({
        position: "top-center",
        icon: "warning",
        title: "Please Write Remarks to Absent Punch",
        showConfirmButton: false,
        timer: 5500,
      });
    }
  };

  const HDAttendanceData = async (e) => {
    // alert(Remarks);
    if (Remarks != "") {
      const response = await axios.post(
        HalfDayAttendanceDetailsURL,
        JSON.stringify({
          AttendanceId: AttendanceId,
          IsShiftChange: IsShiftChange,
          IsInTimeChange: IsInTimeChange,
          IsOutTimeChange: IsOutTimeChange,
          EmployeeCardNo: EmployeeCardNoUpdate,
          ShiftInTime: ShiftInTime,
          ShiftOutTime: ShiftOutTime,
          InTime: InTime,
          OutTime: OutTime,
          ShiftId: ShiftId,
          ManualPunchBy: localStorage.getItem("userId"),
          UnitId: localStorage.getItem("unitId"),
          CompanyId: localStorage.getItem("companyId"),
          Remarks: Remarks,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          withCredentials: true,
        }
      );
      console.log(response.data);
      setdivUpdateDisplay("none");
      ViewData();
      Swal.fire({
        position: "top-center",
        icon: "success",
        title: response.data,
        showConfirmButton: false,
        timer: 2500,
      });
    } else {
      Swal.fire({
        position: "top-center",
        icon: "warning",
        title: "Please Write Remarks to Absent Punch",
        showConfirmButton: false,
        timer: 5500,
      });
    }
  };
  const ShiftChange = async (e) => {
    console.log(e.target.value);
    setShiftId(e.target.value);

    const responseAttendance = await axios.get(
      ShiftFromShiftIdURL + "?ShiftId=" + e.target.value
    );
    console.log(responseAttendance.data);
    setShiftId(responseAttendance.data.shiftId);
    setShiftInTime(AttendanceDate + " " + responseAttendance.data.shiftInTime);
    var myDate = new Date(AttendanceDate);

    if (responseAttendance.data.shiftType == "Night") {
      myDate.setDate(myDate.getDate() + 1);
      setShiftOutTime(
        moment(myDate).format("DD-MMM-YYYY") +
          " " +
          responseAttendance.data.shiftOutTime
      );
    } else {
      setShiftOutTime(
        AttendanceDate + " " + responseAttendance.data.shiftOutTime
      );
    }
  };
  const UnitChange = async (e) => {
    setUnitId(e.target.value);
  };
  const CancelUpdate = async (value) => {
    setdivUpdateDisplay("none");
  };
  const ViewAttendanceDetails = async (value) => {
    const responseAttendance = await axios.get(
      AttendanceURL + "?AttendanceId=" + value
    );

    // if (
    //   missedPunchRadiovalue == "MissedPunch" &&
    //   PunchRadiovalue == "MonthWise"
    // ) {
    //   responseAttendance = await axios.get(
    //     AttendanceURL + "?AttendanceId=" + value
    //   );
    // } else if (
    //   missedPunchRadiovalue == "MissedPunch" &&
    //   PunchRadiovalue == "EmployeeMonthWise"
    // ) {
    //   responseAttendance = await axios.get(
    //     AttendanceURL + "?AttendanceId=" + value
    //   );
    // } else if (
    //   missedPunchRadiovalue == "AllPunch" &&
    //   PunchRadiovalue == "EmployeeMonthWise"
    // ) {
    //   alert("Test");
    // }
    console.log(responseAttendance.data);
    setAttendanceId(responseAttendance.data.attendanceId);
    setEmployeeCardNoUpdate(responseAttendance.data.employeeCardNo);
    setShiftId(responseAttendance.data.shiftId);
    setEmployeeNameUpdate(responseAttendance.data.employeeName);
    setShiftInTime(responseAttendance.data.shiftInTime);
    setShiftOutTime(responseAttendance.data.shiftOutTime);
    setAttendanceDate(responseAttendance.data.attendanceDate);
    if (responseAttendance.data.inTime == "") {
      setInTime(
        moment(responseAttendance.data.shiftInTime).format(
          "YYYY-MM-DD HH:mm:ss"
        )
      );
    } else {
      setInTime(
        moment(responseAttendance.data.inTime).format("YYYY-MM-DD HH:mm:ss")
      );
    }

    //alert(responseAttendance.data.outTime);
    if (responseAttendance.data.outTime == "") {
      //alert("Out TIme Null");
      setOutTime(
        moment(responseAttendance.data.shiftOutTime).format(
          "YYYY-MM-DD HH:mm:ss"
        )
      );
    } else {
      setOutTime(
        moment(responseAttendance.data.outTime).format("YYYY-MM-DD HH:mm:ss")
      );
    }
    setdivUpdateDisplay("block");
  };
  const handleModal = () => {
    setModelState(true);
  };

  const ViewData = async () => {
    var response = null;

    console.log(missedPunchRadiovalue);
    console.log(PunchRadiovalue);
    console.log(EmployeeCardNo);
    if (
      missedPunchRadiovalue == "MissedPunch" &&
      PunchRadiovalue == "MonthWise"
    ) {
      response = await axios.get(
        GetMissedPunchMonthWiseURL +
          "?UnitId=" +
          localStorage.getItem("unitId") +
          "&CompanyId=" +
          localStorage.getItem("companyId") +
          "&Month=" +
          Month +
          "&Year=" +
          Year
      );
    } else if (
      missedPunchRadiovalue == "MissedPunch" &&
      PunchRadiovalue == "EmployeeMonthWise"
    ) {
      response = await axios.get(
        GetMissedPunchMonthWiseEmployeeCardNoWiseURL +
          "?UnitId=" +
          localStorage.getItem("unitId") +
          "&CompanyId=" +
          localStorage.getItem("companyId") +
          "&Month=" +
          Month +
          "&Year=" +
          Year +
          "&EmployeeCardNo=" +
          EmployeeCardNo
      );
    } else if (
      missedPunchRadiovalue == "AllPunch" &&
      PunchRadiovalue == "EmployeeMonthWise"
    ) {
      response = await axios.get(
        GetPunchMonthWiseAndEmployeeCodeWiseURL +
          "?UnitId=" +
          localStorage.getItem("unitId") +
          "&CompanyId=" +
          localStorage.getItem("companyId") +
          "&Month=" +
          Month +
          "&Year=" +
          Year +
          "&EmployeeCardNo=" +
          EmployeeCardNo
      );
    }
    console.log(response.data);
    setData(response.data);

    setHeaderdata(response.data.slice(0, 1));
  };

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days before today and today
    return null; //current && current < dayjs().endOf('day');
  };

  const disabledDateTime = () => ({
    // disabledHours: () => range(0, 24).splice(4, 20),
    // disabledMinutes: () => range(30, 60),
    // disabledSeconds: () => [55, 56],
  });

  const disabledRangeTime: RangePickerProps["disabledTime"] = (_, type) => {
    if (type === "start") {
      return {
        disabledHours: () => range(0, 60).splice(4, 20),
        disabledMinutes: () => range(30, 60),
        disabledSeconds: () => [55, 56],
      };
    }
    return {
      disabledHours: () => range(0, 60),
      disabledMinutes: () => range(0, 31),
      disabledSeconds: () => [55, 56],
    };
  };

  const { RangePicker } = DatePicker;

  const range = (start: number, end: number) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  return (
    <>
      <div className="layoutContainer">
      <div className="row g-3">
                <div className="col-lg-8" style={{ textAlign: "Left" }}>
                  <h4>
                    <b>Punch Management</b>
                  </h4>
                </div>
                <div className="col-lg-3" style={{ textAlign: "Left" }}>
                  {/* <label className="form-controll" >Unit</label> &nbsp; */}
                  <select onChange={UnitChange} className="dropdown-toggle">
                    {UnitItems.map((item) => (
                      <option
                        key={item.value}
                        value={item.value}
                        selected={item.value == UnitId}
                      >
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-lg-12" style={{ textAlign: "center" }}>
                  &nbsp;<br></br>
                </div>
                <div
                  onChange={(event) =>
                    setmissedPunchRadiovalue(event.target.value)
                  }
                  className="col-lg-12"
                >
                  {/* <p>Please select Filter:</p> */} {" "}
                  <input
                    type="radio"
                    id="MonthAll"
                    name="PunchRadio"
                    value="MissedPunch"
                    defaultChecked={missedPunchRadiovalue === "MissedPunch"}
                  />
                    <label for="MonthAll">Missed Punch</label> {" "}
                  <input
                    type="radio"
                    id="AllPunch"
                    name="PunchRadio"
                    value="AllPunch"
                    defaultChecked={missedPunchRadiovalue === "AllPunch"}
                  />
                    <label for="AllPunch">All Punch </label> {" "}
                </div>

                <div
                  onChange={(event) => setPunchRadiovalue(event.target.value)}
                  className="col-lg-12"
                >
                  {/* <p>Please select Filter:</p> */} {" "}
                  <input
                    selected={true}
                    type="radio"
                    id="MonthWise"
                    name="missedPunchRadio"
                    value="MonthWise"
                    defaultChecked={PunchRadiovalue === "MonthWise"}
                  />
                    <label for="MonthWise">Month Wise</label> {" "}
                  <input
                    type="radio"
                    id="EmployeeMonthWise"
                    name="missedPunchRadio"
                    value="EmployeeMonthWise"
                    defaultChecked={PunchRadiovalue === "EmployeeMonthWise"}
                  />
                    <label for="EmployeeMonthWise">Employee & Month </label> {" "}
                  {/* <input
                type="radio"
                id="DateWise"
                name="missedPunchRadio"
                value="DateWise"
              />
                <label for="DateWise">Date Wise</label> */}
                </div>
                <div className="clearfix"></div>
                <div className="col-lg-3">
                  {/* <label className="form-controll" >Month</label> &nbsp; */}
                  <select
                    value={Month}
                    onChange={(e) => setMonth(e.target.value)}
                    style={{ margin: "10px 0px" }}
                  >
                    <option value="0">Select Month</option>
                    <option value="1">January</option>
                    <option value="2">February</option>
                    <option value="3">March</option>
                    <option value="4">April</option>
                    <option value="5">May</option>
                    <option value="6">June</option>
                    <option value="7">July</option>
                    <option value="8">August</option>
                    <option value="9">September</option>
                    <option value="10">October</option>
                    <option value="11">November </option>
                    <option value="12">December</option>
                  </select>
                </div>
                <div className="col-lg-3">
                  {/* <label className="form-controll" >Year</label> &nbsp; */}
                  <select
                    className="dropdown-toggle"
                    value={Year}
                    onChange={(e) => setYear(e.target.value)}
                    style={{ margin: "10px 0px" }}
                  >
                    {options.map(({ value, label }, index) => (
                      <option value={value}>{label}</option>
                    ))}
                  </select>
                </div>
                <div className="col-lg-3">
                  <input
                    type="Text"
                    className="form-controll"
                    id="EmployeeCardNo"
                    onChange={(e) => setEmployeeCardNo(e.target.value)}
                    autoComplete="off"
                    value={EmployeeCardNo}
                    placeholder="EmployeeCardNo"
                    required
                  ></input>
                </div>
                <div className="col-lg-2">
                  <button
                    style={{ width: "200px" }}
                    onClick={ViewData}
                    className="btn btn-primary"
                    style={{ margin: "10px 0px" }}
                  >
                    {" "}
                    View
                  </button>
                  &nbsp;
                  {/* <DownloadTableExcel
                    filename="Missed Punch Log"
                    sheet="Missed Punch"
                    currentTableRef={tableRef.current}
                  >
                    <button
                      style={{ width: "50%", height: "50px", color: "green" }}
                      className="btn"
                    >
                      <i className="fa fa-file-excel-o"></i>
                    </button>
                  </DownloadTableExcel> */}
                </div>

                <div
                  className="col-lg-12"
                  style={{
                    borderRadius: "5px",
                    border: "1px solid",
                    paddingLeft: "20px",
                    paddingTop: "20px",
                    paddingRight: "20px",
                    paddingBottom: "20px",
                    display: divUpdateDisplay,
                    backgroundColor: "white",
                    marginBottom: "20px",
                  }}
                >
                  <div className="row">
                    <div className="col-lg-4">
                      <label htmlFor="Name">Name:</label>{" "}
                      <input
                        type="Text"
                        className="form-controll"
                        autoComplete="off"
                        value={EmployeeNameUpdate}
                        placeholder="Name"
                        required
                      ></input>
                    </div>
                    <div className="col-lg-4">
                      <label htmlFor="EmployeeCardNo">EmployeeCardNo:</label>{" "}
                      <input
                        type="Text"
                        className="form-controll"
                        autoComplete="off"
                        value={EmployeeCardNoUpdate}
                        placeholder="EmployeeCardNo"
                        required
                      ></input>
                    </div>
                    <div className="col-lg-4"></div>
                    <div className="col-lg-3">
                      <label htmlFor="Shift">Shift:</label>{" "}
                      <select
                        onChange={ShiftChange}
                        className="dropdown-toggle"
                        style={{ margin: "8px 0px" }}
                      >
                        {ShiftItems.map((item) => (
                          <option
                            key={item.value}
                            value={item.value}
                            selected={item.value == ShiftId}
                          >
                            {item.label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-lg-1">
                      <input
                        type="CheckBox"
                        id="IsActive"
                        checked={IsShiftChange}
                        onChange={(e) => setIsShiftChange(e.target.checked)}
                        style={{ margin: "45px 0px" }}
                      ></input>
                    </div>
                    <div className="col-lg-4">
                      <label htmlFor="Shift InTime">Shift In Time:</label>{" "}
                      <input
                        type="Text"
                        className="form-controll"
                        autoComplete="off"
                        value={ShiftInTime}
                        placeholder="Shift InTime"
                        required
                      ></input>
                    </div>
                    <div className="col-lg-4">
                      <label htmlFor="Shift Out Time">Shift Out Time:</label>{" "}
                      <input
                        type="Text"
                        className="form-controll"
                        autoComplete="off"
                        value={ShiftOutTime}
                        placeholder="Shift Out Time"
                        required
                      ></input>
                    </div>
                    <div className="col-lg-3">
                      <label htmlFor="In Time">In Time:</label>{" "}
                      <Space direction="vertical" size={12}>
                        <DatePicker
                          onChange={(date) => setInTime(date)}
                          format="DD-MMM-YYYY HH:mm:ss"
                          showTime={{
                            defaultValue: dayjs("00:00:00", "HH:mm:ss"),
                          }}
                          value={dayjs(InTime)}
                        />
                      </Space>
                      {/* <input type="time" className="without_ampm" id="Intime" onChange={(e) => setIntime(e.target.value)}
                            autoComplete="off" value={Intime} placeholder="HH:MM" required></input> */}
                    </div>
                    <div className="col-lg-1">
                      <input
                        type="CheckBox"
                        id="IsActive"
                        checked={IsInTimeChange}
                        onChange={(e) => setIsInTimeChange(e.target.checked)}
                        style={{ margin: "45px 0px" }}
                      ></input>
                    </div>
                    <div className="col-lg-3">
                      <label htmlFor="Out Time">Out Time:</label>{" "}
                      <DatePicker
                        format="DD-MMM-YYYY HH:mm:ss"
                        onChange={(date) => setOutTime(date)}
                        showTime={{
                          defaultValue: dayjs("00:00:00", "HH:mm:ss"),
                        }}
                        value={dayjs(OutTime)}
                      />
                    </div>
                    <div className="col-lg-1">
                      <input
                        type="CheckBox"
                        id="IsActive"
                        checked={IsOutTimeChange}
                        onChange={(e) => setIsOutTimeChange(e.target.checked)}
                        style={{ margin: "45px 0px" }}
                      ></input>
                    </div>
                    <div className="col-lg-4">
                      <label htmlFor="Remarks">Remarks:</label>{" "}
                      <textarea
                        name="Remarks"
                        rows="3"
                        cols="30"
                        type="Text"
                        className="form-controll ant-textarea"
                        id="Remarks"
                        onChange={(e) => setRemarks(e.target.value)}
                        required
                      ></textarea>
                    </div>
                    <div className="col-lg-12" style={{ textAlign: "center" }}>
                      <button
                        className="btn btn-primary"
                        style={{ width: "100px" }}
                        onClick={() => UpdateAttendanceData()}
                      >
                        Update
                      </button>
                      &nbsp;&nbsp;
                      <button
                        className="btn btn-success"
                        style={{ width: "100px" }}
                        onClick={() => CancelUpdate()}
                      >
                        Cancel
                      </button>
                      &nbsp;&nbsp;
                      <button
                        className="btn btn-danger"
                        style={{ width: "100px" }}
                        onClick={() => AbsentAttendanceData()}
                      >
                        Absent
                      </button>
                      &nbsp;&nbsp;
                      <button
                        className="btn btn-danger"
                        style={{ width: "100px" }}
                        onClick={() => HDAttendanceData()}
                      >
                        HD
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2">
                {" "}
                <DownloadTableExcel
                  filename="Punch table"
                  sheet="Punch"
                  currentTableRef={tableRef.current}
                >
                  <button className="btn btn-success margin-bottom">
                    Export To Excell
                  </button>
                </DownloadTableExcel>
              </div>
              <div className="scollDiv mt-4">
                <table
                  ref={tableRef}
                  className="attendTable text-center"
                  style={{ width: "100%" }}
                >
                  <thead></thead>
                  <tbody>
                    <tr>
                      <td>Employee CardNo</td>
                      <td>Employee Name</td>
                      <td>Department</td>
                      <td> Date</td>
                      <td>ShiftName</td>
                      <td>Shift InTime</td>
                      <td>Shift OutTime</td>
                      <td>InTime </td>
                      <td>OutTime</td>
                      <td></td>
                    </tr>

                    {data.map((att, index) => (
                      <>
                        <tr data-index={index}>
                          <td>{att.employeeCardNo}</td>
                          <td>{att.employeeName}</td>
                          <td>{att.departmentName}</td>
                          <td>{att.attendanceDate}</td>
                          <td>{att.shiftName}</td>
                          <td>{att.shiftInTime}</td>
                          <td>{att.shiftOutTime}</td>
                          <td>{att.inTime} </td>
                          <td>{att.outTime} </td>
                          <td>
                            {" "}
                            <button
                              className="btn btn-primary"
                              onClick={() =>
                                ViewAttendanceDetails(att.attendanceId)
                              }
                            >
                              Update
                            </button>
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
    </>
  );
};

export default MissedPunch;
