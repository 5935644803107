import { height } from "@mui/system";
import React from "react";
import { useRef, useState, useEffect, useContext } from "react";

import SCRIPTINDIABLUELOGO from "../images/logo-black Blue.png";
import { Navigate, useNavigate } from "react-router-dom";
import {
  AppstoreOutlined,
  ContainerOutlined,
  MenuFoldOutlined,
  DesktopOutlined,
  PieChartOutlined,
  MailOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { Button, Menu } from "antd";


const HeaderTop = ({onClick}) => {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const LogOutClick = (event) => {
    localStorage.clear();
    console.log("LogOut");
    navigate("/Login");
  };

  const handleToggle = () => {
    setCollapsed(!collapsed)
        onClick()
        // Here you would typically handle form submission logic
        // setFormData(initialFormData);
   
};

  return (
    <div className="header">
       <div className="d-flex">
        <div className="logo">
          <a href="#">
            <img src={SCRIPTINDIABLUELOGO} height="50" alt="SCRIPT INDIA" />
          </a>
        </div>
        <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => handleToggle()}
            style={{
              fontSize: '16px',
              width: 64,
              height: 64,
            }}
          />
          </div>
      <div className="btn-logout">
        <button className="btn btn-success" onClick={LogOutClick}>
          Logout
        </button>
      </div>
    </div>
  );
};

export default HeaderTop;
