import React, { useEffect, useState, useMemo, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import { Link } from "react-router-dom";
import { DownloadTableExcel } from "react-export-table-to-excel";
import Swal from "sweetalert2";
import { Navigate, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


import {
  Container,
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
} from "reactstrap";
import TableContainer from "../Util/TableContainer";
//import 'bootstrap/dist/css/bootstrap.min.css';
import { SelectColumnFilter } from "../Util/filters";
import axios from "../../api/axios";

const Employee = () => {

  const [data, setData] = useState([]);
  const [SearchText, setSearchText] = useState("");
  const [Headerdata, setHeaderdata] = useState([]);
  const EmployeeListURL = "/GetEmployeeListFromCompanyId";
  const GetEmployeeFromEmployeeCardNoEmployeeNameMobileNoURL = "/GetEmployeeFromEmployeeCardNoEmployeeNameMobileNo";
  const tableRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const doFetch = async () => {
      // const response = await fetch('https://randomuser.me/api/?results=100');
      const response = await axios.get(
        EmployeeListURL + "?CompanyId=" + localStorage.getItem("companyId")
      );
      console.log(response.data);
      setData(response.data);
    };
    doFetch();
  }, []);



  const ViewData = async () => {
    //alert('ok');
    if (SearchText == "") {
      alert('ok2');
      const response = await axios.get(
        EmployeeListURL +
        "&CompanyId=" +
        localStorage.getItem("companyId")
      );
      console.log(response.data);
      setData(response.data);
      //setHeaderdata(response.data.slice(0, 1));
    }
    else {
      alert('ok1');
      const response = await axios.get(
        GetEmployeeFromEmployeeCardNoEmployeeNameMobileNoURL +
        "?CompanyId=" +
        localStorage.getItem("companyId") +
        "&EmployeeCardNo=" +
        SearchText +
        "&EmployeeName=" +
        SearchText +
        "&MobileNo=" +
        SearchText
      );
      console.log(response.data);
      setData(response.data);
      //setHeaderdata(response.data.slice(0, 1));
    }
  };



  const EditData = (id) => {
    //let { id} = data;
    // localStorage.setItem('EditUserId', id);
    //alert(id);
    navigate("/EmployeeMaster", { state: { id } });
  };

  return (
    <>
      <div className="layoutContainer">
        <div className="row g-3">
          <div className="col-lg-8" style={{ textAlign: "Left" }}>
            {" "}
            <h4>
              <b>Employee List</b>
            </h4>
          </div>

          <div>
            {/* className="col-lg-2" */}
            <input
              type="Text"
              className="form-controll"
              id="EmployeeCardNo"
              onChange={(e) => setSearchText(e.target.value)}
              autoComplete="off"
              value={SearchText}
              placeholder="Search By Employee Card No , Employee Name , Mobile No"
            //required
            ></input>
          </div>


          <div className="col-lg-2">
            <button
              onClick={(e) => {
                EditData(0);
              }}
              className="btn btn-primary"
            >
              Add New (+)
            </button>
            {/* <button
                    style={{ width: "70%", height: "50", color: "blue" }}
                    onClick={(e) => {
                      EditData(0);
                    }}
                    className="btn"
                  >
                    <i className="fa fa-plus-square"></i>
                  </button> */}
          </div>
          <div className="col-lg-2">
            <DownloadTableExcel
              filename="Employee Table"
              sheet="Employee"
              currentTableRef={tableRef.current}
            >
              <button className="btn btn-success">
                Export to Excell
              </button>
            </DownloadTableExcel>
          </div>

          <div className="col-lg-2">
            <button onClick={ViewData} className="btn btn-primary">
              {" "}
              View
            </button>
          </div>

        </div>
        <div className="scollDiv mt-4">
          <table
            ref={tableRef}
            className="attendTable text-center"
            style={{ width: "100%" }}
          >
            <thead></thead>
            <tbody>
              <tr>
                <td>Sr No.</td>
                <td>Employee CardNo</td>
                <td>Employee Name</td>
                <td>Current Address</td>
                <td>Mobile No</td>
                <td>Email</td>
                <td>Date Of Birth</td>
                <td>Gender</td>
                <td>Date Of Joining</td>
                <td>Company Name</td>
                <td>Unit Name</td>
                <td>Department Name</td>
                <td>Designation Name</td>
                <td>Shift Name</td>
                <td>Adhar CardNo</td>
                <td>PAN No</td>
                <td>Bank Name</td>
                <td>Bank Branch</td>
                <td>Bank Account No</td>
                <td>IFSC</td>
                <td>Week Off Day</td>
                <td>Status</td>
                <td>Action</td>
              </tr>

              {data.map((att, index) => (
                <>
                  <tr data-index={index}>
                    <td>{att.rowNum}</td>
                    <td>{att.employeeCardNo}</td>
                    <td>{att.employeeName}</td>
                    <td>{att.currentAddress}</td>
                    <td>{att.mobileNo}</td>
                    <td>{att.email}</td>
                    <td>{att.dateOfBirth}</td>
                    <td>{att.gender}</td>
                    <td>{att.dateOfJoining}</td>
                    <td>{att.companyName}</td>
                    <td>{att.unitName}</td>
                    <td>{att.departmentName}</td>
                    <td>{att.designationName}</td>
                    <td>{att.shiftName}</td>
                    <td>{att.adharCardNo}</td>
                    <td>{att.panNo}</td>
                    <td>{att.bankName}</td>
                    <td>{att.bankBranch}</td>
                    <td>{att.bankAccountNo}</td>
                    <td>{att.iFSC}</td>
                    <td>{att.weekOffDay}</td>
                    <td>{att.status}</td>
                    <td>{<button
                      style={{ width: "40%", height: "40px", color: "green" }}
                      onClick={(e) =>
                        EditData(att.employeeId)
                      }
                      className="btn"
                    >
                      <i className="fa fa-pencil-square-o"></i>
                    </button>}
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Employee;
