import React, { useEffect, useState, useMemo, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import HeaderTop from "../../Layout/HeaderTop";
import SideBar from "../../Layout/Sidebar/SideBar";
import { Link } from "react-router-dom";
import { DownloadTableExcel } from "react-export-table-to-excel";
import Swal from "sweetalert2";
import { Navigate, useNavigate } from "react-router-dom";
import SideMenu from "../../Layout/SideMenu";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Layout, Space } from "antd";
import FooterBottom from "../../Layout/FooterBottom";

import {
  Container,
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
} from "reactstrap";
import TableContainer from "../Util/TableContainer";
//import 'bootstrap/dist/css/bootstrap.min.css';
import { SelectColumnFilter } from "../Util/filters";
import axios from "../../api/axios";

const Visitor = () => {

  const [data, setData] = useState([]);
  const [Headerdata, setHeaderdata] = useState([]);
  const AllVisitorListURL = "/GetAllVisitorDetailsFromCompanyIdAndUnitId";
  const VisitorWiseURL = "/GetVisitorWiseDetailsFromCompanyIdAndUnitId";
  const tableRef = useRef(null);
  const navigate = useNavigate();
  const [UnitItems, setUnitItems] = React.useState([]);
  const [UnitId, setUnitId] = useState(0);
  const [Month, setMonth] = useState(0);
  const [Year, setYear] = useState(0);
  const [Day, setDay] = useState(0);
  const [currentYear] = useState(() => new Date().getFullYear());
  const [currentDay] = useState(() => new Date().getDay());
  const [currentMonth] = useState(() => new Date().getMonth());
  const [SearchText, setSearchText] = useState("");
  const [EmployeeCardNo, setEmployeeCardNo] = useState("");
  const UnitListURL = "/GetUnitListFromCompanyId";
  const options = [];
  obj = {};
  var obj = {};

  obj["value"] = 0;
  obj["label"] = "Select Year";
  options.push(obj);
  for (var i = -2; i < 1; i++) {
    var obj = {};

    obj["value"] = currentYear + i;
    obj["label"] = currentYear + i;
    options.push(obj);
  }


  useEffect(() => {
    setUnitId(localStorage.getItem("unitId"));
    const doFetch = async () => {
      // const response = await fetch('https://randomuser.me/api/?results=100');
      const responseunit = await axios.get(
        UnitListURL + "?CompanyId=" + localStorage.getItem("companyId")
      );
      setUnitItems(
        responseunit.data.map(({ unitId, unitName }) => ({
          label: unitName,
          value: unitId,
        }))
      );
      //alert(new Date().getDay());
      setMonth(new Date().getMonth() + 1);
      setYear(currentYear);
      setDay(new Date().getDate());
    };
    doFetch();
  }, []);

  const UnitChange = async (e) => {
    setUnitId(e.target.value);

  };

  const [iframSrc, setIframeUrl] = useState();
  const ViewLocationDetails = (id, ltd, lnd) => {
    // navigate("/ViewLocation")//, { state: { id } });

    setIframeUrl(
      // `${googleUrl}?q==${state.ltd},${state.lnd}&hl=es;z=14&amp;output=embed`
      // `https://maps.google.com/maps?q=10.305385,77.923029&hl=es;z=14&amp;output=embed`
      `https://maps.google.com/maps?q=${ltd},${lnd}&hl=es;z=14&output=embed`
    );
    //alert(id);
    // navigate("/ViewLocation", { state: { id ,ltd,lnd} });
  };

  const ViewData = async () => {
    if (SearchText == "") {
      const response = await axios.get(
        AllVisitorListURL +
        "?UnitId=" +
        localStorage.getItem("unitId") +
        "&CompanyId=" +
        localStorage.getItem("companyId") +
        "&Month=" +
        Month +
        "&Year=" +
        Year
      );
      console.log(response.data);
      setData(response.data);
      setHeaderdata(response.data.slice(0, 1));
    }
    else {
      const response = await axios.get(
        VisitorWiseURL +
        "?UnitId=" +
        localStorage.getItem("unitId") +
        "&CompanyId=" +
        localStorage.getItem("companyId") +
        "&VisitorCardNo=" +
        SearchText +
        "&Month=" +
        Month +
        "&Year=" +
        Year
      );
      console.log(response.data);
      setData(response.data);
      setHeaderdata(response.data.slice(0, 1));
    }
  };


  return (
    <>
      <div className="layoutContainer">
        <div className="row">
          <div className="col-lg-10" style={{ textAlign: "Left" }}>
            <h4>
              <b>Client Visit</b>
            </h4>
          </div>

          <div className="col-lg-4">
            {/* <label className="form-controll" >Unit</label> &nbsp; */}
            <select
              onChange={UnitChange}
              className="dropdown-toggle form-controll ant-select"
            >
              {UnitItems.map((item) => (
                <option
                  key={item.value}
                  value={item.value}
                  selected={item.value == UnitId}
                >
                  {item.label}
                </option>
              ))}
            </select>
          </div>

          <div className="col-lg-4">
            {/* <label className="form-controll" >Month</label> &nbsp; */}
            <select
              value={Month}
              onChange={(e) => setMonth(e.target.value)}
              className="form-controll ant-select"
            >
              <option value="0">Select Month</option>
              <option value="1">January</option>
              <option value="2">February</option>
              <option value="3">March</option>
              <option value="4">April</option>
              <option value="5">May</option>
              <option value="6">June</option>
              <option value="7">July</option>
              <option value="8">August</option>
              <option value="9">September</option>
              <option value="10">October</option>
              <option value="11">November </option>
              <option value="12">December</option>
            </select>
          </div>
          <div className="col-lg-4">
            {/* <label className="form-controll" >Year</label> &nbsp; */}
            <select
              className="dropdown-toggle form-controll ant-select"
              value={Year}
              onChange={(e) => setYear(e.target.value)}
              style={{ margin: "8px 0;" }}
            >
              {options.map(({ value, label }, index) => (
                <option value={value}>{label}</option>
              ))}
            </select>
          </div>

          <div className="col-lg-4">
            <input
              type="Text"
              className="form-controll"
              id="VisitorCardNo"
              onChange={(e) => setSearchText(e.target.value)}
              autoComplete="off"
              value={SearchText}
              placeholder="Visitor Card No"
              required
            ></input>
          </div>
          <div className="col-lg-2" style={{ margin: "15px 0px" }}>
            <button onClick={ViewData} className="btn btn-primary">
              {" "}
              View
            </button>
          </div>
          <div className="col-lg-2" style={{ margin: "15px 0px" }}>
            <DownloadTableExcel
              filename="Visitor Detail"
              sheet="Visitor Detail"
              currentTableRef={tableRef.current}
            >
              <button className="btn btn-success">
                Export To Excell
              </button>
            </DownloadTableExcel>
          </div>
        </div>
        <div className="scollDiv mt-4">
          {/* <table >
                        <thead>
                            <tr>
                                {heading.map((head, headID) =>
                                    <th key={headID} >{head}</th>)}
                            </tr>
                        </thead>
                        <tbody>
                            {body.map((rowContent, rowID) =>
                                <tdrowContent={rowContent} key={rowID} />)}
                        </tbody>
                    </table>
                    
                    */}


          <table
            ref={tableRef}
            className="attendTable"
            style={{ width: "100%" }}
          >
            <thead></thead>
            <tbody>
              <tr>
                <td>Visitor Card No</td>
                <td>Visitor Name</td>
                <td>Company Name</td>
                <td>Company Address</td>
                <td>Contact Person Name</td>
                <td>Contact Person No</td>
                <td>EmailId</td>
                <td>Latitude</td>
                <td>Longitude</td>
                <td>Visit Date</td>
                <td>Status</td>
                <td>Remarks</td>
                <td></td>
              </tr>

              {data.map((att, index) => (
                <>
                  <tr data-index={index}>
                    <td>{att.visitorCardNo}</td>
                    <td>{att.visitorName}</td>
                    <td>{att.companyName}</td>
                    <td>{att.companyAddress}</td>
                    <td>{att.contactPersonName}</td>
                    <td>{att.contactPersonNo}</td>
                    <td>{att.emailId}</td>
                    <td>{att.latitude}</td>
                    <td>{att.longitude}</td>
                    <td>{att.entryDate}</td>
                    <td>{att.status}</td>
                    <td>{att.remarks} </td>

                    <td>
                      {/* {" "} */}
                      {<button
                        className="btn btn-outline-primary btn-sm"
                        onClick={(e) =>
                          ViewLocationDetails(att.clientVisitId, att.latitude, att.longitude) //att.employeeCardNo
                        }
                      >
                        View Location
                      </button>}

                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>

        </div>
        <iframe style={{ width: "100%", height: "50%" }} src={iframSrc}></iframe>
      </div>
    </>
  );
};

export default Visitor;
