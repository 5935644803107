import React from "react";

import SideBar from "../../Layout/Sidebar/SideBar";
import HeaderTop from "../../Layout/HeaderTop";
import { useRef, useState, useEffect, useContext } from "react";
//import AuthContext from '../context/AuthProvider';
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import axios from "../../api/axios";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Layout, Space } from "antd";
import FooterBottom from "../../Layout/FooterBottom";
import SideMenu from "../../Layout/SideMenu";

export const UnitMaster = (props) => {
  const { Header, Footer, Sider, Content } = Layout;
  const headerStyle: React.CSSProperties = {
    textAlign: "center",
    height: 64,
    paddingInline: 50,
    lineHeight: "64px",
    backgroundColor: "#ffff",
  };

  const contentStyle: React.CSSProperties = {
    float: "left",
  };

  const siderStyle: React.CSSProperties = {
    backgroundColor: "#ffff",
  };

  const footerStyle: React.CSSProperties = {
    backgroundColor: "#7dbcea",
  };

  const INSERTUNIT_URL = "/InsertUnitDetails";
  const UPDATEUNIT_URL = "/UpdateUnitDetails";
  const UNITDETAIL_URL = "/GetUnitFromUnitId";
  const userRef = useRef();
  const errRef = useRef();
  const navigate = useNavigate();

  const [UnitId, setUnitId] = useState(0);

  const [UnitName, setUnitName] = useState("");
  const [Address, setAddress] = useState("");
  const [IsActive, setIsActive] = useState(false);
  const [CompanyId, setCompanyId] = useState("");
  const [MSG, setErrMsg] = useState("");
  const [sucecess, setSucecess] = useState(false);

  const { state } = useLocation(0);

  const handleChange = (event) => {
    if (event.target.checked) {
      console.log("✅ Checkbox is checked");
    } else {
      console.log("⛔️ Checkbox is NOT checked");
    }
    setIsActive((current) => !current);
  };

  const setIsActiveValue = (value) => {
    setIsActive((pre) => (pre = value));
  };

  useEffect(() => {
    setUnitId(state.id);
    if (state.id == 0) {
      alert("New");
      setUnitName("");
      setAddress("");
      setIsActive(false);
    } else {
      // alert(state.id);
      const FetchUnitDetails = async () => {
        const response = await axios.get(
          UNITDETAIL_URL + "?UnitId=" + state.id
        );

        setUnitName(response.data.unitName);
        setAddress(response.data.address);
        setUnitId(response.data.unitId);
        console.log(response.data.status);
        if (response.data.status == "true") {
          console.log("set True");
          setIsActive(true);
        } else {
          console.log("set False");

          setIsActive(false);
        }
      };
      FetchUnitDetails();
    }

    userRef.current.focus();
  }, []);

  // useEffect(() => {

  //   setErrMsg('');
  //   //UserList.apply();

  // }, [ , userName, Password ])

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      //          alert(userId);

      //    console.log(userId);
      if (UnitId == 0) {
        console.warn(localStorage.getItem("companyId"));
        const response = await axios.post(
          INSERTUNIT_URL,
          JSON.stringify({
            UnitName: UnitName,
            Address: Address,
            IsActive: IsActive,
            CompanyId: localStorage.getItem("companyId"),
          }),
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            withCredentials: true,
          }
        );
        console.log(response.data);
        setErrMsg(response.data);
        setUnitName("");
        setAddress("");
        setIsActive(false);

        //setCompanyId('');
        // UserList
        Swal.fire({
          position: "top-center",
          icon: "success",
          title: response.data,
          showConfirmButton: false,
          timer: 2500,
        });

        // alert('Saved successfully.')
        navigate("/Unit");
      } else {
        const response = await axios.post(
          UPDATEUNIT_URL,
          JSON.stringify({
            UnitName: UnitName,
            Address: Address,
            UnitId: UnitId,
            IsActive: IsActive,
            CompanyId: localStorage.getItem("companyId"),
          }),
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            withCredentials: true,
          }
        );
        console.log(response.data);
        setErrMsg(response.data);
        setUnitName("");
        setAddress("");
        setIsActive(false);
        Swal.fire({
          position: "top-center",
          icon: "success",
          title: response.data,
          showConfirmButton: false,
          timer: 2500,
        });
        navigate("/Unit");
      }
    } catch (error) {
      console.log("error", error);
      // if (!error?.response) {
      //   setErrMsg('No Server Response');
      // }
      // else if (error.response?.status == 400) {
      //   setErrMsg('Missin Username or password');

      // } else if (error.response?.status == 401) {
      //   setErrMsg('Un Autharised');

      // }
      // else {
      //   setErrMsg('Login Fail');
      // }
      // errRef.current.focus();
    }
    e.preventDefault();
  };

  return (
    <>
      <Layout>
        <Header style={headerStyle}>
          <HeaderTop></HeaderTop>
        </Header>
        <Layout hasSider>
          <Sider style={siderStyle}>
            <SideMenu></SideMenu>
          </Sider>
          <Content style={contentStyle}>
            <div className="container">
              <div className="loginContainer">
                <form onSubmit={handleSubmit}>
                  <h2>Unit Master</h2>
                  <input
                    type="Text"
                    className="form-controll"
                    id="UnitName"
                    onChange={(e) => setUnitName(e.target.value)}
                    ref={userRef}
                    autoComplete="off"
                    value={UnitName}
                    placeholder="User Name"
                    required
                  ></input>
                  <textarea
                    className="form-controll"
                    id="Address"
                    onChange={(e) => setAddress(e.target.value)}
                    rows="4"
                    cols="50"
                    autoComplete="off"
                    value={Address}
                    placeholder="Address"
                    required
                  ></textarea>
                  <label className="form-controll" htmlFor="IsActive">
                    Is Active
                  </label>{" "}
                  &nbsp;
                  <input
                    type="CheckBox"
                    id="IsActive"
                    checked={IsActive}
                    onChange={(e) => setIsActive(e.target.checked)}
                  ></input>
                  {/* 
<input type="RetypePassword" className="form-controll" id="RetypePassword" onChange={(e) => setPassword(e.target.value)}
              autoComplete="off" value={Password} placeholder="Retype Password" required></input> */}
                  <button className="btn btn-primary"> Save</button>
                  <div>
                    <label>{MSG}</label>
                  </div>
                </form>
              </div>
            </div>
          </Content>
        </Layout>
        <Footer style={footerStyle}>
          <FooterBottom />
        </Footer>
      </Layout>
    </>
  );
};
