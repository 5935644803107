import React from "react";

import SideBar from "../../Layout/Sidebar/SideBar";
import HeaderTop from "../../Layout/HeaderTop";
import { useRef, useState, useEffect, useContext } from "react";
//import AuthContext from '../context/AuthProvider';
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import axios from "../../api/axios";
import Swal from "sweetalert2";
import UnitMasterDDL from "../DropdownList/UnitMasterDDL";
import { components } from "react-select";
import Multiselect from "multiselect-react-dropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Layout, Space } from "antd";
import FooterBottom from "../../Layout/FooterBottom";
import SideMenu from "../../Layout/SideMenu";

export const ShiftGroupMaster = (props) => {
  const { Header, Footer, Sider, Content } = Layout;
  const headerStyle: React.CSSProperties = {
    textAlign: "center",
    height: 64,
    paddingInline: 50,
    lineHeight: "64px",
    backgroundColor: "#ffff",
  };

  const contentStyle: React.CSSProperties = {
    float: "left",
  };

  const siderStyle: React.CSSProperties = {
    backgroundColor: "#ffff",
  };

  const footerStyle: React.CSSProperties = {
    backgroundColor: "#7dbcea",
  };
  const INSERTShiftGroup_URL = "/InsertShiftGroupDetails";
  const UPDATEShiftGroup_URL = "/UpdateShiftGroupDetails";
  const ShiftGroupDETAIL_URL = "/GetShiftGroupFromShiftGroupId";
  const ShiftListURL = "/GetShiftListFromCompanyId";

  const userRef = useRef();
  const errRef = useRef();
  const navigate = useNavigate();
  const UnitMasterDDLRef = useRef();

  const [ShiftGroupId, setShiftGroupId] = useState(0);
  const [UnitId, setUnitId] = useState(0);

  const [UnitName, setUnitName] = useState("");
  const [ShiftGroupName, setShiftGroupName] = useState("");
  const [OTAfterMin, setOTAfterMin] = useState(0);
  const [AssignShift, setAssignShift] = useState("");
  const [AssignShiftId, setAssignShiftId] = useState("");
  const [IsActive, setIsActive] = useState(false);
  const [IsCOFF, setIsCOFF] = useState(false);
  const [IsOTAllow, setIsOTAllow] = useState(false);
  const [CompanyId, setCompanyId] = useState("");
  const [MSG, setErrMsg] = useState("");
  const [sucecess, setSucecess] = useState(false);
  const [btnLabel, setbtnLabel] = useState(false);
  const [items, setItems] = React.useState([]);
  const [selectedValue, setselectedValue] = React.useState([]);

  const { state } = useLocation(0);

  const handleChange = (event) => {
    if (event.target.checked) {
      console.log("✅ Checkbox is checked");
      setIsActive(true);
    } else {
      console.log("⛔️ Checkbox is NOT checked");
      setIsActive(false);
    }

    console.log(IsActive);
  };

  const setIsActiveValue = (value) => {
    setIsActive((pre) => (pre = value));
  };

  const setIsOTValue = (value) => {
    setIsOTAllow((pre) => (pre = value));
  };

  const setsetIsCOFFValue = (value) => {
    setIsCOFF((pre) => (pre = value));
  };

  useEffect(() => {
    const doFetch = async () => {
      // const response = await fetch('https://randomuser.me/api/?results=100');
      const response = await axios.get(
        ShiftListURL + "?CompanyId=" + localStorage.getItem("companyId")
      );
      console.log(response.data);

      setItems(
        response.data.map(({ shiftId, shiftName }) => ({
          name: shiftName,
          id: shiftId,
        }))
      );
    };
    doFetch();

    setShiftGroupId(state.id);
    if (state.id == 0) {
      //alert('New');
      setShiftGroupName("");
      setIsActive(false);
      setbtnLabel("Save");
    } else {
      // alert(state.id);
      const FetchShiftGroupDetails = async () => {
        const response = await axios.get(
          ShiftGroupDETAIL_URL + "?ShiftGroupId=" + state.id
        );

        setShiftGroupName(response.data.shiftGroupName);
        setOTAfterMin(response.data.otAfterMin);
        setAssignShift(response.data.asssignShift);
        setAssignShiftId(response.data.assignShiftId);
        setShiftGroupId(response.data.shiftGroupId);
        setUnitName(response.data.unitName);
        setUnitId(response.data.unitId);
        setselectedValue(
          response.data.shiftGroupsMapList.map(({ id, name }) => ({
            name: name,
            id: id,
          }))
        );
        //  console.log(UnitId);
        // console.log(response.data.status);
        if (response.data.status == "Active") {
          console.log("set True");
          setIsActiveValue(true);
          console.log(IsActive);
        } else {
          console.log("set False");
          // setIsActive(false);
          setIsActiveValue(false);
          console.log(IsActive);
        }
        if (response.data.isCOFF == 1) {
          setsetIsCOFFValue(true);
        } else {
          setsetIsCOFFValue(false);
        }
        if (response.data.isOTAllow == 1) {
          setIsOTValue(true);
        } else {
          setIsOTValue(false);
        }
      };
      FetchShiftGroupDetails();
      setbtnLabel("Update");
    }

    userRef.current.focus();
  }, []);

  const onSelect = (selectedList, selectedItem) => {
    console.log(selectedItem);
    console.log(selectedList);
    setselectedValue(selectedList);
  };

  const onRemove = (selectedList, removedItem) => {
    console.log(removedItem);
    console.log(selectedList);
    setselectedValue(selectedList);
  };
  // useEffect(() => {

  //   setErrMsg('');
  //   //UserList.apply();

  // }, [ , userName, Password ])

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (ShiftGroupId == 0) {
        console.warn(localStorage.getItem("companyId"));
        setUnitId(UnitMasterDDLRef.current.getUnitId());
        console.log(UnitMasterDDLRef.current.getUnitId());
        console.log(UnitId);
        const response = await axios.post(
          INSERTShiftGroup_URL,
          JSON.stringify({
            ShiftGroupName: ShiftGroupName,
            AssignShiftId: AssignShiftId,
            OTAfterMin: OTAfterMin,
            IsCOFF: IsCOFF,
            IsOTAllow: IsOTAllow,
            ShiftGroupsMapList: selectedValue,
            UnitId: UnitId,
            IsActive: IsActive,
            CompanyId: localStorage.getItem("companyId"),
          }),
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            withCredentials: true,
          }
        );
        console.log(response.data);
        setErrMsg(response.data);
        setShiftGroupName("");
        setUnitId(0);
        setIsActive(false);

        setIsOTAllow(false);
        setIsCOFF(false);
        setOTAfterMin(0);

        Swal.fire({
          position: "top-center",
          icon: "success",
          title: response.data,
          showConfirmButton: false,
          timer: 1500,
        });

        // alert('Saved successfully.')
        navigate("/ShiftGroup");
      } else {
        setUnitId(UnitMasterDDLRef.current.getUnitId());
        console.log(UnitMasterDDLRef.current.getUnitId());
        console.log(UnitId);
        const response = await axios.post(
          UPDATEShiftGroup_URL,
          JSON.stringify({
            ShiftGroupId: ShiftGroupId,
            ShiftGroupName: ShiftGroupName,
            AssignShiftId: AssignShiftId,
            OTAfterMin: OTAfterMin,
            IsCOFF: IsCOFF,
            IsOTAllow: IsOTAllow,
            ShiftGroupsMapList: selectedValue,
            UnitId: UnitMasterDDLRef.current.getUnitId(),
            IsActive: IsActive,
            CompanyId: localStorage.getItem("companyId"),
          }),
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            withCredentials: true,
          }
        );
        console.log(response.data);
        setErrMsg(response.data);
        setShiftGroupName("");
        setShiftGroupId(0);
        setIsActive(false);
        setIsOTAllow(false);
        setIsCOFF(false);
        setOTAfterMin(0);
        Swal.fire({
          position: "top-center",
          icon: "success",
          title: response.data,
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/ShiftGroup");
      }
    } catch (error) {
      console.log("error", error);
    }
    e.preventDefault();
  };

  return (
    <>
      <Layout>
        <Header style={headerStyle}>
          <HeaderTop></HeaderTop>
        </Header>
        <Layout hasSider>
          <Sider style={siderStyle}>
            <SideMenu></SideMenu>
          </Sider>
          <Content style={contentStyle}>
            <div className="container">
              <div className="loginContainer">
                <form onSubmit={handleSubmit}>
                  <h2>ShiftGroup Master</h2>
                  <input
                    type="Text"
                    className="form-controll"
                    id="ShiftGroupName"
                    onChange={(e) => setShiftGroupName(e.target.value)}
                    ref={userRef}
                    autoComplete="off"
                    value={ShiftGroupName}
                    placeholder="ShiftGroup Name"
                    required
                  ></input>
                  <input
                    type="Text"
                    className="form-controll"
                    id="OTAfterMin"
                    onChange={(e) => setOTAfterMin(e.target.value)}
                    ref={userRef}
                    autoComplete="off"
                    value={OTAfterMin}
                    placeholder="OTAfterMin"
                    required
                  ></input>
                  <UnitMasterDDL
                    ref={UnitMasterDDLRef}
                    unitId={UnitId}
                  ></UnitMasterDDL>
                  <Multiselect
                    options={items} // Options to display in the dropdown
                    selectedValues={selectedValue} // Preselected value to persist in dropdown
                    onSelect={onSelect} // Function will trigger on select event
                    onRemove={onRemove} // Function will trigger on remove event
                    displayValue="name" // Property name to display in the dropdown options
                  />
                  <label className="form-controll" htmlFor="IsCOFF">
                    Is COFF
                  </label>{" "}
                  &nbsp;
                  <input
                    type="CheckBox"
                    id="IsCOFF"
                    checked={IsCOFF}
                    onChange={(e) => setIsCOFF(e.target.checked)}
                  ></input>
                  <label className="form-controll" htmlFor="IsCOFF">
                    Is OT Allow
                  </label>{" "}
                  &nbsp;
                  <input
                    type="CheckBox"
                    id="IsOTAllow"
                    checked={IsOTAllow}
                    onChange={(e) => setIsOTAllow(e.target.checked)}
                  ></input>
                  <label className="form-controll" htmlFor="IsActive">
                    Is Active
                  </label>{" "}
                  &nbsp;
                  <input
                    type="CheckBox"
                    id="IsActive"
                    checked={IsActive}
                    onChange={(e) => setIsActive(e.target.checked)}
                  ></input>
                  {/* 
<input type="RetypePassword" className="form-controll" id="RetypePassword" onChange={(e) => setPassword(e.target.value)}
              autoComplete="off" value={Password} placeholder="Retype Password" required></input> */}
                  <button className="btn btn-primary"> {btnLabel}</button>
                  <div>
                    <label>{MSG}</label>
                  </div>
                </form>
              </div>
            </div>
          </Content>
        </Layout>
        <Footer style={footerStyle}>
          <FooterBottom />
        </Footer>
      </Layout>
    </>
  );
};
