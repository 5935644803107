import React, { useEffect, useState, useMemo, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import HeaderTop from "../../../Layout/HeaderTop";
import SideBar from "../../../Layout/Sidebar/SideBar";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { Navigate, useNavigate } from "react-router-dom";
import "./att.css";
import axios from "../../../api/axios";
import { DownloadTableExcel } from "react-export-table-to-excel";
import SideMenu from "../../../Layout/SideMenu";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Layout, Space } from "antd";
import FooterBottom from "../../../Layout/FooterBottom";
import { useReactToPrint } from "react-to-print";

const InOutReportC = () => {
  const conponentPDF = useRef();
  const { Header, Footer, Sider, Content } = Layout;
  const headerStyle: React.CSSProperties = {
    textAlign: "center",
    height: 64,
    paddingInline: 50,
    lineHeight: "64px",
    backgroundColor: "#ffff",
  };

  const contentStyle: React.CSSProperties = {
    float: "left",
  };

  const siderStyle: React.CSSProperties = {
    backgroundColor: "#ffff",
  };

  const footerStyle: React.CSSProperties = {
    backgroundColor: "#ffff",
  };

  const [data, setData] = useState([]);
  const InOutReportListURL = "/GetComplianceAttendanceFromCompanyIdAndUnitId";
  const InOutReportListURLEmployeeCardNo =
    "/GetComplianceAttendanceFromCompanyIdAndUnitIdAndEmployeeCardNo";
  const tableRef = useRef(null);
  const navigate = useNavigate();
  const [UnitItems, setUnitItems] = React.useState([]);
  const [UnitId, setUnitId] = useState(0);
  const [Month, setMonth] = useState(0);
  const [Year, setYear] = useState(0);
  const [currentYear] = useState(() => new Date().getFullYear());
  const [SearchText, setSearchText] = useState("");
  const UnitListURL = "/GetUnitListFromCompanyId";
  const options = [];
  obj = {};
  var obj = {};

  obj["value"] = 0;
  obj["label"] = "Select Year";
  options.push(obj);
  for (var i = -2; i < 1; i++) {
    var obj = {};

    obj["value"] = currentYear + i;
    obj["label"] = currentYear + i;
    options.push(obj);
  }
  useEffect(() => {
    setUnitId(localStorage.getItem("unitId"));
    const doFetch = async () => {
      // const response = await fetch('https://randomuser.me/api/?results=100');
      const responseunit = await axios.get(
        UnitListURL + "?CompanyId=" + localStorage.getItem("companyId")
      );
      setUnitItems(
        responseunit.data.map(({ unitId, unitName }) => ({
          label: unitName,
          value: unitId,
        }))
      );
    };
    doFetch();
  }, []);

  const generatePDF = useReactToPrint({
    content: () => conponentPDF.current,
    documentTitle: "InOut Report",
    onAfterPrint: () => alert("Data saved in PDF"),
  });

  const UnitChange = async (e) => {
    setUnitId(e.target.value);
    // const responsedepartment = await axios.get(DepartmentListURL+"?CompanyId="+localStorage.getItem('companyId')+"&UnitId="+e.target.value );
    // setDepartmentItems(responsedepartment.data.map(({ departmentId, departmentName }) => ({ label: departmentName , value: departmentId })));
    // const responsedesignation = await axios.get(DesignationListURL+"?CompanyId="+localStorage.getItem('companyId')+"&UnitId="+e.target.value );
    // setDesignationItems(responsedesignation.data.map(({ designationId, designationName }) => ({ label: designationName , value: designationId })));
    // const responseshiftgroup = await axios.get(ShiftGroupListURL+"?CompanyId="+localStorage.getItem('companyId')+"&UnitId="+e.target.value );
    // setShiftGroupItems(responseshiftgroup.data.map(({ shiftGroupId, shiftGroupName }) => ({ label: shiftGroupName , value: shiftGroupId })));
  };

  const ViewData = async () => {
    if (SearchText == "") {
      const response = await axios.get(
        InOutReportListURL +
          "?UnitId=" +
          localStorage.getItem("unitId") +
          "&CompanyId=" +
          localStorage.getItem("companyId") +
          "&Month=" +
          Month +
          "&Year=" +
          Year
      );
      console.log(response.data);
      setData(response.data);
    } else {
      const response = await axios.get(
        InOutReportListURLEmployeeCardNo +
          "?UnitId=" +
          localStorage.getItem("unitId") +
          "&CompanyId=" +
          localStorage.getItem("companyId") +
          "&Month=" +
          Month +
          "&Year=" +
          Year +
          "&EmployeeCardNo=" +
          SearchText
      );
      console.log(response.data);
      setData(response.data);
    }
  };

  return (
    <>
         <div className="layoutContainer">
         <div className="row g-3">
                <div className="col-lg-10" style={{ textAlign: "Left" }}>
                  <h4>
                    <b>In Out Report</b>
                  </h4>
                </div>

                <div className="col-lg-4" style={{ textAlign: "Left" }}>
                  {/* <label className="form-controll" >Unit</label> &nbsp; */}
                  <select
                    onChange={UnitChange}
                    className="dropdown-toggle ant-select"
                  >
                    {UnitItems.map((item) => (
                      <option
                        key={item.value}
                        value={item.value}
                        selected={item.value == UnitId}
                      >
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-lg-4" style={{ textAlign: "Left" }}>
                  {/* <label className="form-controll" >Month</label> &nbsp; */}
                  <select
                    value={Month}
                    onChange={(e) => setMonth(e.target.value)}
                    className="ant-select"
                  >
                    <option value="0">Select Month</option>
                    <option value="1">January</option>
                    <option value="2">February</option>
                    <option value="3">March</option>
                    <option value="4">April</option>
                    <option value="5">May</option>
                    <option value="6">June</option>
                    <option value="7">July</option>
                    <option value="8">August</option>
                    <option value="9">September</option>
                    <option value="10">October</option>
                    <option value="11">November </option>
                    <option value="12">December</option>
                  </select>
                </div>
                <div className="col-lg-4" style={{ textAlign: "Left" }}>
                  {/* <label className="form-controll" >Year</label> &nbsp; */}
                  <select
                    className="dropdown-toggle ant-select"
                    value={Year}
                    onChange={(e) => setYear(e.target.value)}
                  >
                    {options.map(({ value, label }, index) => (
                      <option value={value}>{label}</option>
                    ))}
                  </select>
                </div>
                <div className="col-lg-4" style={{ textAlign: "Left" }}>
                  <input
                    type="Text"
                    className="form-controll"
                    id="EmployeeCardNo"
                    onChange={(e) => setSearchText(e.target.value)}
                    autoComplete="off"
                    value={SearchText}
                    placeholder="Employee CardNo"
                  ></input>
                </div>
                <div className="col-lg-2">
                  <button onClick={ViewData} className="btn btn-primary">
                    {" "}
                    View
                  </button>
                </div>
                <div className="col-lg-3">
                  <DownloadTableExcel
                    filename="InOut Report"
                    sheet="InOut"
                    currentTableRef={tableRef.current}
                  >
                    <button className="btn btn-success">
                      Export To Excell
                    </button>
                  </DownloadTableExcel>
                  <button className="btn btn-success" onClick={generatePDF}>
                    PDF
                  </button>
                </div>
              </div>
              <div ref={conponentPDF} className="scollDiv mt-4">
                <table
                  className="attendTable text-center"
                  ref={tableRef}
                  style={{ width: "100%" }}
                >
                  <thead></thead>
                  <tbody>
                    <tr>
                      <td>EMPLOYEE CODE</td>
                      <td colSpan={9}>NAME</td>
                      <td>P</td>
                      <td>AB</td>

                      <td> WO </td>
                      <td>PH</td>
                      <td>CL</td>
                      <td>PL</td>
                      <td>SL</td>
                      <td>OT</td>
                    </tr>

                    {data.map((att, index) => (
                      <>
                        <tr data-index={index}>
                          <td>{att.EmployeeCardNo}</td>
                          <td colSpan={9}>{att.EmployeeName}</td>
                          <td>{att.TotalPresent}</td>
                          <td>{att.TotalAbsent}</td>
                          <td>{att.WOP}</td>
                          <td>{att.PH} </td>
                          <td>{att.CL}</td>
                          <td>{att.PL}</td>
                          <td>{att.SL}</td>
                          <td>{att.OT}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>Day</td>

                          {att.FullMonthInOut.map((D, index) => (
                            <td>{D.Day}</td>
                          ))}
                        </tr>
                        <tr>
                          <td></td>
                          <td>Status</td>

                          {att.FullMonthInOut.map((D, index) => (
                            <td>{D.Status}</td>
                          ))}
                        </tr>
                        <tr>
                          <td></td>
                          <td>In Time</td>

                          {att.FullMonthInOut.map((D, index) => (
                            <td>{D.InTime}</td>
                          ))}
                        </tr>
                        <tr>
                          <td></td>
                          <td>Out Time</td>

                          {att.FullMonthInOut.map((D, index) => (
                            <td>{D.OutTime}</td>
                          ))}
                        </tr>
                        <tr>
                          <td></td>
                          <td>WorkHour</td>

                          {att.FullMonthInOut.map((D, index) => (
                            <td>{D.WorkHour}</td>
                          ))}
                        </tr>
                        <tr>
                          <td></td>
                          <td>OTHour</td>

                          {att.FullMonthInOut.map((D, index) => (
                            <td>{D.OTHour}</td>
                          ))}
                        </tr>
                        <tr>
                          <td></td>
                          <td>Late</td>

                          {att.FullMonthInOut.map((D, index) => (
                            <td>{D.Late}</td>
                          ))}
                        </tr>
                        <tr>
                          <td></td>
                          <td>Early</td>

                          {att.FullMonthInOut.map((D, index) => (
                            <td>{D.Early}</td>
                          ))}
                        </tr>
                        <tr>
                          <td colSpan={35}>&nbsp;</td>
                        </tr>{" "}
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
    </>
  );
};

export default InOutReportC;
