import React, { useEffect, useState, useMemo, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import HeaderTop from "../../Layout/HeaderTop";
import SideBar from "../../Layout/Sidebar/SideBar";
import { Link } from "react-router-dom";
import { DownloadTableExcel } from "react-export-table-to-excel";
import Swal from "sweetalert2";
import { Navigate, useNavigate } from "react-router-dom";
import SideMenu from "../../Layout/SideMenu";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Layout, Space } from "antd";
import FooterBottom from "../../Layout/FooterBottom";

import {
  Container,
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
} from "reactstrap";
import TableContainer from "../Util/TableContainer";
//import 'bootstrap/dist/css/bootstrap.min.css';
import { SelectColumnFilter } from "../Util/filters";
import axios from "../../api/axios";

const Device = () => {
  const [data, setData] = useState([]);
  const DeviceListURL = "/GetDeviceListFromCompanyId";
  const tableRef = useRef(null);
  const navigate = useNavigate();
  const { Header, Footer, Sider, Content } = Layout;
  const headerStyle: React.CSSProperties = {
    textAlign: "center",
    height: 64,
    paddingInline: 50,
    lineHeight: "64px",
    backgroundColor: "#ffff",
  };

  const contentStyle: React.CSSProperties = {
    float: "left",
    // textAlign: "center",
    // minHeight: 120,
    // lineHeight: "120px",
    // color: "#fff",
    // backgroundColor: "#108ee9",
  };

  const siderStyle: React.CSSProperties = {
    backgroundColor: "#ffff",
  };

  const footerStyle: React.CSSProperties = {
    backgroundColor: "#fff",
  };

  useEffect(() => {
    const doFetch = async () => {
      // const response = await fetch('https://randomuser.me/api/?results=100');
      const response = await axios.get(
        DeviceListURL + "?CompanyId=" + localStorage.getItem("companyId")
      );
      console.log(response.data);
      setData(response.data);
    };
    doFetch();
  }, []);

  const EditData = (id) => {
    //let { id} = data;
    // localStorage.setItem('EditUserId', id);
    //alert(id);
    navigate("/DeviceMaster", { state: { id } });
  };

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "rowNum",
        disableFilters: true,
      },
      {
        Header: "Device IP",
        accessor: "deviceIpAddress",
        Filter: SelectColumnFilter,
        disableFilters: true,
      },
      {
        Header: "PortNo",
        accessor: "portNo",
        disableFilters: true,
      },
      {
        Header: "DeviceType",
        accessor: "deviceType",
        Filter: SelectColumnFilter,
        disableFilters: true,
      },
      {
        Header: "Unit",
        accessor: "unitName",
        Filter: SelectColumnFilter,
      },
      // {
      //   Header: 'Email',
      //   accessor: 'email',
      // },
      {
        Header: "Status",
        Filter: SelectColumnFilter,
        accessor: "status",
      },
      {
        Header: "Action", //() => null,
        id: "DeviceId", // 'id' is required
        accessor: "deviceId",
        disableFilters: true,
        Cell: ({ row }) => (
          <span {...row}>
            <button
              style={{ width: "40%", height: "40px", color: "green" }}
              onClick={(e) => {
                EditData(row.cells[6].value);
              }}
              className="btn"
            >
              <i className="fa fa-pencil-square-o"></i>
            </button>
            {/* &nbsp;
           <button style={{width:'40%', height:'40px' ,color:'red' }} onClick={(e) => {InActiveData(row.cells[4].value)}} className="btn" ><i className='fa fa-close'></i></button> */}
          </span>
        ),
      },
    ],
    []
  );

  return (
    <>
      <Layout>
        <Header style={headerStyle}>
          <HeaderTop></HeaderTop>
        </Header>
        <Layout hasSider>
          <Sider style={siderStyle}>
            <SideMenu></SideMenu>
          </Sider>
          <Content style={contentStyle}>
            <div className="col-lg-11" style={{ padding: "20px 50px" }}>
              <div className="row">
                <div className="col-lg-10" style={{ textAlign: "Left" }}>
                  {" "}
                  <h4>
                    <b>Device List</b>
                  </h4>
                </div>
                <div className="col-lg-2">
                  {/* <button onClick={(e) => {EditData(0)}}  className="btn btn-success">Add New (+)</button> */}
                  <button
                    onClick={(e) => {
                      EditData(0);
                    }}
                    className="btn btn-primary"
                  >
                    Add New (+)
                  </button>
                </div>
              </div>
              <div style={{ paddingTop: "20px" }}>
                <TableContainer
                  table
                  table-striped
                  bordered
                  table-sm
                  columns={columns}
                  data={data}
                  tableRef={tableRef}
                />
              </div>
            </div>
          </Content>
        </Layout>
        <Footer style={footerStyle}>
          <FooterBottom />
        </Footer>
      </Layout>
    </>
  );
};

export default Device;
